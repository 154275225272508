import React, { useEffect, useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import "./News.css";
import newscover from "../../assets/newscover.png";
import newscar from "../../assets/newspic.png";
import newscard1 from "../../assets/newscard1.png";
import newscard2 from "../../assets/newscard2.png";
import newscard3 from "../../assets/newscard3.png";
import loanpic from "../../assets/laonpic.png";
import NewsCardHolder from "./NewsCardHolder";
import Footer from "../../components/LandingPage/Footer/Footer";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/Auth/AuthSlice";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import loadingbars from "../../assets/loading_bars.svg";
const News = () => {
  const auth = useSelector(selectAuth);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function loadinghandler() {
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    setloading(true);
    loadinghandler();
  }, []);
  return (
    <div className="news">
      {auth ? <NavbarProfile /> : <Navbar />}
      {loading ? (
        <div className="loading-div">
          <img src={loadingbars} alt="Loading..." />
        </div>
      ) : (
        <div className="newscover">
          <div className="bluecover">
            <img src={newscover} alt="news cover" />
            <div className="centered">
              <h1
                style={{ backgroundColor: "transparent", textAlign: "center" }}
              >
                Karblock News
              </h1>
            </div>
          </div>
          <div className="mainnews">
            <div className="newstext">
              <h2>Taxis in Islamabad go better</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat
                sed massa mattis eget integer faucibus quis turpis. Et lorem
                sapien sit arcu.
              </p>
              <div className="dateandreadholder">
                <p className="dateholder">May 20th 2020</p>
                <p className="readmore">Read more</p>
              </div>
            </div>
            <div className="newsimageholder">
              <img src={newscar} alt="" />
            </div>
          </div>
          <NewsCardHolder />
          <div className="getloanmain">
            <div className="getloan">
              <div className="getloantext">
                <h2>How to get loan for your next Car</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div className="newscarddate">
                  <p className="dateholder">May 20th 2020</p>
                  <p className="readmore">Read more</p>
                </div>
              </div>
              <div className="loanimageholder">
                <img src={loanpic} alt="" />
              </div>
            </div>
          </div>
          <NewsCardHolder />
          <NewsCardHolder />
          <NewsCardHolder />
          <NewsCardHolder />
        </div>
      )}

      {/* <Footer /> */}
    </div>
  );
};

export default News;
