import React from "react";
import "./KarblockRenter.css";
import karblockrenter from "../../../assets/karblockrenter.png";
import google_play from "../../../assets/images/google_play.svg";
import android_play from "../../../assets/images/android_play.svg";
const KarblockRenter = ({ targetElement }) => {
  return (
    <div style={{ display: "flex", width: "100vw" }} ref={targetElement}>
      <div className="karblockrenter">
        <div className="renter">
          <img src={karblockrenter} alt="karblockrenter" />
        </div>
        <div className="rentercard">
          <h3>Karblock Renter</h3>
          <p>
            We want to make your life easier by taking care of everything
            related to car rental for you, right from the word GO. From
            arranging the car pickup to making sure the docking back of the
            vehicle on time is on us. We have got options available for every
            pocket and taste.
          </p>
          <div
            style={{ paddingTop: "30px", cursor: "pointer" }}
            className="renter-download-images"
          >
            <a
              href="https://apps.apple.com/us/app/karblock/id1602901466"
              target="_blank"
            >
              {" "}
              <img
                src={android_play}
                alt="arrow"
                width="auto"
                className="android_play"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.karblock&hl=en_US&gl=US"
              target="_blank"
            >
              <img
                src={google_play}
                alt="arrow"
                width="auto"
                className="google_play"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KarblockRenter;
