import React, { useEffect, useState } from "react";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import Rating from "@material-ui/lab/Rating";
import jwt_decode from "jwt-decode";
import loadingbars from "../../assets/loading_bars.svg";
import { useHistory, useParams, Redirect } from "react-router-dom";
import PopupModal from "../../components/PopupModal/PopupModal";
import moment from "moment";
import TodayIcon from "@material-ui/icons/Today";
import RoomIcon from "@material-ui/icons/Room";
import axios from "../../api";
import Carousel from "react-material-ui-carousel";
import { getAllBookingReasons } from "../../features/reason/reason.action";
import GoogleMap from "./MapComponents/GoogleMap";
import "./ConfirmBooking.css";
// import { cities } from "../../utills/data";
import {
  Modal,
  Backdrop,
  Fade,
  NativeSelect,
  FormControl,
  InputBase,
  withStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },

  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "1px solid black",
    fontSize: "1.2vw",
    padding: "10px 26px 10px 12px",
    width: "27vw",

    fontFamily: ["Inter"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "black",

      backgroundColor: "transparent",
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))(InputBase);

const ConfirmBooking = (props) => {
  const dispatch = useDispatch();
  const { state } = props.location;
  const history = useHistory();
  const { id } = useParams();
  const [_pickuppoint, set_pickuppoint] = useState([]);
  const [specificCar, setspecificCar] = useState(null);
  const [loading, setloading] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [openmap, setopenmap] = useState(false);
  const [status, setstatus] = useState("");
  const { reasons } = useSelector((state) => state.reason);
  const [booking_reason, setbooking_reason] = useState("");
  const [traveling_city, settraveling_city] = useState("");
  let token = localStorage.getItem("token");
  let decoded = jwt_decode(token);
  let userId = decoded.doc._id;
  useEffect(() => {
    dispatch(getAllBookingReasons());
    async function getSpecificCar() {
      axios.get(`/car/getCarbyId?_id=${id}`).then((res) => {
        setspecificCar(res.data.data[0]);

        setloading(false);
      });
    }
    setloading(true);
    getSpecificCar();
  }, []);
  let cities = [];

  const citiesProps = {
    options: cities,
    getOptionLabel: (option) => `${option.name}`,
  };

  const setPickupPoint = (e) => {
    set_pickuppoint({
      ..._pickuppoint,
      address: e.target.value,
      name: e.target.value,
    });
  };
  const handleCloseMapModal = () => {
    setopenmap(false);
  };

  const openMapModal = () => {
    navigator.permissions
      .query({
        name: "geolocation",
      })
      .then(function (result) {
        if (result.state == "granted") {
          setopenmap(true);
        } else if (result.state == "prompt") {
          setopenmap(true);
        } else if (result.state == "denied") {
          window.alert("Please turn on location!");
        }
      });
  };
  async function addBackendBooking() {
    if (!state.pickup_date || !state.dropoff_date) {
      window.alert("Please select pickup date");
      return;
    }
    if (!state.days) {
      window.alert("Please select days");
      return;
    } else if (!state.pickup_point) {
      window.alert("Please select pickup point");
      return;
    } else if (!booking_reason) {
      window.alert("Please select booking reason");
      return;
    } else if (!traveling_city) {
      window.alert("Please select traveling city");
      return;
    }
    const bookingToAdd = {
      days: state.days,
      rent: state.days * specificCar.rent,
      car_id: state.car_id,
      pickup_date: moment(state.pickup_date)
        .seconds(0)
        .milliseconds(0)
        .toISOString(),
      dropoff_date: moment(state.dropoff_date)
        .seconds(0)
        .milliseconds(0)
        .toISOString(),
      renter_id: userId,
      pickup_point: _pickuppoint,
      booking_reason: booking_reason,
      traveling_city: traveling_city,
    };

    axios
      .post(`/booking/addNewBooking`, bookingToAdd)
      .then(() => {
        seterrormessage(`Booking Confirmed!`);
        setstatus("Success");
        setshowPopup(true);
        history.push("/bookings");
      })
      .catch((err) => {
        seterrormessage(err.response.data.message);
        setstatus("Error");
        setshowPopup(true);
      });
  }
  const handleChangeReason = (e) => {
    if (!e.target.value) return;
    setbooking_reason(e.target.value);
  };
  const handleChangeTravelingCity = (e) => {
    if (!e.target.value) return;
    settraveling_city(e.target.value);
  };
  return (
    <div className="confirmbooking">
      <NavbarProfile />
      {showPopup ? (
        <PopupModal
          message={errormessage}
          status={status}
          setshowPopup={setshowPopup}
        />
      ) : null}
      {loading ? (
        <div className="loading-div">
          <img src={loadingbars} alt="Loading..." />
        </div>
      ) : null}
      {specificCar !== null ? (
        <div className="wrapperfeature">
          <div className="featurediv">
            <div className="carousel-carimages">
              <Carousel autoPlay={false}>
                {specificCar?.car_images?.map((item, i) => (
                  <div className="cnicImage" key={i}>
                    <img src={item} />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="feacturetext featuredivcol2" id="featuredivcol2">
              <p className="confirm">Confirm Booking</p>
              <h2>
                {specificCar?.brand?.brand_name} {specificCar?.car_name["name"]}{" "}
                {specificCar?.model}
              </h2>
              <Rating
                value={specificCar.rating && specificCar.rating}
                readOnly
                precision={0.1}
                size="small"
              />
              <p>{`${
                specificCar.noofRentals ? specificCar.noofRentals : 0
              } Rentals`}</p>
              <p style={{ fontWeight: "600" }}>Features</p>

              <div className="featureholder">
                {specificCar?.features?.map((feature) => {
                  return <p>{feature?.feature_name}</p>;
                })}
              </div>
            </div>
          </div>
          <div className="featurewrapperrow2">
            <p>Booking Details</p>
            <div
              style={{ width: "80vw", borderBottom: "1px solid black" }}
            ></div>
            <div className="pickndropinfo">
              <div className="timeholder">
                <p>
                  <TodayIcon style={{ color: "#F05123" }} />
                  <div>
                    <span>Pickup Date</span>

                    <span>
                      {moment(state?.pickup_date).format("DD - MMM - YYYY")}
                    </span>
                  </div>
                </p>
                <p>
                  <TodayIcon style={{ color: "#F05123" }} />
                  <div>
                    <span>Pickup Time</span>

                    <span>
                      {moment(state?.pickup_date).format("HH:mm:ss a")}
                    </span>
                  </div>
                </p>
                <p>
                  <TodayIcon style={{ color: "#F05123" }} />
                  <div>
                    {" "}
                    <span>Dropoff Date</span>
                    <span>
                      {moment(state?.dropoff_date).format("DD - MMM - YYYY")}
                    </span>
                  </div>
                </p>
                <p>
                  <TodayIcon style={{ color: "#F05123" }} />
                  <div>
                    {" "}
                    <span>Dropoff Time</span>
                    <span>
                      {moment(state?.dropoff_date).format("HH:mm:ss a")}
                    </span>
                  </div>
                </p>
                <p>
                  <RoomIcon style={{ color: "#F05123" }} />
                  <div>
                    <span>Days</span>
                    <span>{state?.days}</span>
                  </div>
                </p>
              </div>
              <div className="timeholder">
                <p>
                  <RoomIcon style={{ color: "#F05123" }} />
                  <div>
                    <span>Pickup Point</span>
                    <input
                      style={{
                        width: 410,
                        borderRadius: 6,
                        height: 40,
                        borderColor: "gray",
                      }}
                      type="text"
                      onClick={openMapModal}
                      name="Address"
                      placeholder="Open Map"
                      value={_pickuppoint?.address}
                      onChange={(e) => setPickupPoint(e.target.value)}
                      required
                    />
                  </div>
                </p>
              </div>
              <div className="timeholder">
                <p>
                  <RoomIcon style={{ color: "#F05123" }} />
                  <div>
                    <span>Booking Reason</span>
                    <FormControl className={""}>
                      <NativeSelect
                        id="demo-customized-select-native"
                        style={{ width: 410 }}
                        onChange={handleChangeReason}
                        input={<BootstrapInput />}
                      >
                        {" "}
                        <option>{"select"}</option>{" "}
                        {reasons?.map((reason) => {
                          return (
                            <option value={reason.reason} key={reason._id}>
                              {reason.reason}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </div>
                </p>
              </div>
              <div className="timeholder">
                <p>
                  <RoomIcon style={{ color: "#F05123" }} />
                  <div>
                    <span>Travelling City</span>
                    <FormControl className={""}>
                      <NativeSelect
                        style={{ width: 410 }}
                        id="demo-customized-select-native"
                        onChange={handleChangeTravelingCity}
                        input={<BootstrapInput />}
                      >
                        {" "}
                        <option>{"select"}</option>{" "}
                        {cities?.map((city) => {
                          return (
                            <option value={city.name} key={city.name}>
                              {city.name}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="carmakeHolder"></div>
          <div className="carmakeHolder"></div>
          <div className="grayareholder">
            <div className="graypricearea">
              <div className="price">
                <h3>Rs {specificCar.rent}</h3>
                <p className="hourlyrate" id="hourlyrate">
                  Per Day
                </p>
              </div>
              <button
                onClick={() => {
                  addBackendBooking();
                }}
              >
                Confirm Booking
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={"__modal"}
        open={openmap}
        onClose={handleCloseMapModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openmap}>
          <div className={"modalPaper"}>
            <GoogleMap
              set_pickuppoint={set_pickuppoint}
              handleCloseMapModal={handleCloseMapModal}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ConfirmBooking;
