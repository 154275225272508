import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import "./Contact.css";
import locationIcon from "../../assets/images/map.svg";
import phoneIcon from "../../assets/images/mob.svg";
import messageIcon from "../../assets/images/msg.svg";
import mask_contactus from "../../assets/images/mask_contactus.svg";
import contact_mail from "../../assets/images/contact_mail.svg";
import Footer from "../../components/LandingPage/Footer/Footer";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/Auth/AuthSlice";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import loadingbars from "../../assets/loading_bars.svg";
import axios from "../../api";
import Map from "./Map";
import Loader from "../../components/Loader/loader";
import GoogleMapReact from "google-map-react";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Conatct = () => {
  const auth = useSelector(selectAuth);
  const name = useRef("");
  const email = useRef("");
  const message = useRef("");

  const [data, setData] = useState([]);
  const getContactUsData = async () => {
    const res = await axios.get(`/contactus`);
    setData(res.data.data[0]);
  };
  useEffect(() => {
    getContactUsData();
  }, []);

  const shipContact = () => {
    const contactUsToAdd = {
      name: name.current.value,
      email: email.current.value,
      message: message.current.value,
    };
    axios
      .post("/contactus", contactUsToAdd)
      .then((res) => {
        window.alert(res.data.data.status);
      })
      .catch((err) => {
        window.alert(err.response.data.message);
      });
    const inputFeilds = document.getElementsByTagName("input");
    document.getElementsByTagName("textarea")[0].value = "";
    for (var j = 0; j < inputFeilds.length; j++) inputFeilds[j].value = "";
  };
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function loadinghandler() {
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    setloading(true);
    loadinghandler();
  }, []);
  return (
    <div className="contact-main">
      {auth ? <NavbarProfile /> : <Navbar />}
      {loading ? (
        <div style={{height:"100vh"}}>
        <Loader />
        </div>
      ) : (
        <>
          <div className="contactheader">
            <img src={mask_contactus} />
            <h2>CONTACT US</h2>
          </div>
          <div className="contactformcontainer">
            <div className="getintouch">
              <img src={contact_mail} className="getintouch-img" />
              <h3>Get in touch</h3>
              <p>You can also reach out to us via below provided details.</p>
              <div className="companyinformationholder">
                <p>
                  <img src={messageIcon} alt="emailIcon" />
                  {data?.email}
                </p>
                <p>
                  <img src={phoneIcon} alt="emailIcon" />+{data?.phone}
                </p>
                <p>
                  <img src={locationIcon} alt="emailIcon" />
                  {data?.address}
                </p>
              </div>
              <Map />
            </div>
            <div className="leaveamessage">
              <div className="leaveamessage-sub">
                <h3>Send us a message</h3>
                <div className="leavemessageform">
                  <label htmlFor="name">Full Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    id=""
                    ref={name}
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    id=""
                    ref={email}
                  />
                  <label htmlFor="message">Message</label>
                  <textarea
                    placeholder="Message"
                    name="message"
                    id=""
                    cols="30"
                    rows="10"
                    ref={message}
                  ></textarea>
                </div>
                <button
                  onClick={() => {
                    shipContact();
                  }}
                >
                  Message
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default Conatct;
