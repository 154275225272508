import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { getBrands } from "../../features/Dropdown/dropdown.action"
import { searchCarbybrand } from "../../features/cars/cars.action"
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid } from '@material-ui/core';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom"
import "./CarsByMake.css"
const CarByMake = ({ user }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { brands } = useSelector((state => state.dropdown))
    const [activeIndex, setActiveIndex] = useState(0);
    const [brandList, setBrandList] = useState(brands);
  
    const sortBrandName = async () => {
        let b = brands.slice();
        if (b.length > 0) {
            var s1 = 'Suzuki';
            var s2 = 'Toyota';
            var s3 = 'Honda';
            var s4 = 'KIA';
            var s5 = 'Hyundai';
            var s6 = 'Diahatsu';
            var s7 = 'Nissan';
            var s8 = 'MG';
            b.forEach(function (item, i) {
                if (item.brand_name === s8) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            b.forEach(function (item, i) {
                if (item.brand_name === s7) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            b.forEach(function (item, i) {
                if (item.brand_name === s6) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            b.forEach(function (item, i) {
                if (item.brand_name === s5) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            b.forEach(function (item, i) {
                if (item.brand_name === s4) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            b.forEach(function (item, i) {
                if (item.brand_name === s3) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            b.forEach(function (item, i) {
                if (item.brand_name === s2) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            b.forEach(function (item, i) {
                if (item.brand_name === s1) {
                    b.splice(i, 1);
                    b.unshift(item);
                    return;
                }
            });
            setBrandList(b);
            return;
        }
    };
    useEffect(() => {
        dispatch(getBrands())
        sortBrandName()
    }, [])

    const [responsive, setResponsive] = useState({
        0: { items: 2 },
        530: { items: 3 },
        700: { items: 4 },
        1024: { items: 5 },
    });
    useEffect(() => {
        setResponsive({
            0: { items: 2 },
            530: { items: 3 },
            700: { items: 4 },
            1024: { items: 5 },
        });
    }, []);
    const search = (id) => {
        dispatch(searchCarbybrand(id, user?.city?._id))
        history.push("/searchcar")
    }
    const viewAll = () => {
        history.push("/brands")
    }
    const getCarouselItem = () => {
        return brandList?.map((item, i) =>
        (<Paper className="paper-item" key={i} onClick={() => search(item._id)} >
            <div className="paper-img-container" onClick={() => search(item._id)}>
                <img src={item.image?.replace(

'https://image-assets-bucket.s3.ap-south-1.amazonaws.com/',

'https://d1w0w4ie5qtn2n.cloudfront.net/',

)} onClick={() => search(item._id)} />
            </div>
            <p>{item.brand_name}</p>
        </Paper>))

    }

    const slidePrev = () => setActiveIndex(activeIndex - 1);
    const slideNext = () => setActiveIndex(activeIndex + 1);
    const syncActiveIndex = ({ item }) => setActiveIndex(item);
    return (
        <div className="">
            <div className="carcarousel-row">
                <p className="make-heading">Browse by make</p>
                <button onClick={viewAll}>View All</button>
            </div>
            <Grid
                container
                spacing={2}
                className="grid-carousel carmake-carousel"
                justifyContent="center"
                alignItems="center"
            >
                <>
                <AliceCarousel
                mouseTracking
                disableDotsControls
                animationType="fadeout" 
                animationDuration={800}
disableButtonsControls
                items={getCarouselItem()}
                activeIndex={activeIndex}
                responsive={responsive}
                onSlideChanged={syncActiveIndex}
                />
            <div className="b-refs-buttons">
            <ArrowBackIosIcon onClick={slidePrev} className="orange-arrow" />
            <ArrowForwardIosIcon onClick={slideNext} className="orange-arrow" />
            </div></>
            </Grid>
        </div>
    )
}

export default CarByMake 


// import React, { useState } from 'react';
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';

// const responsive = {
//     0: { items: 1 },
//     568: { items: 2 },
//     1024: { items: 3 },
// };

// const createItems = (length, [handleClick]) => {
//     let deltaX = 0;
//     let difference = 0;
//     const swipeDelta = 20;

//     return Array.from({ length }).map((item, i) => (
//         <div
//             data-value={i + 1}
//             className="item"
//             onMouseDown={(e) => (deltaX = e.pageX)}
//             onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
//             onClick={() => (difference < swipeDelta) && handleClick(i)}
//         >
//             <span className="item-inner" />
//         </div>
//     ));
// };

// const Carousel = () => {
//     const [activeIndex, setActiveIndex] = useState(0);

//     const slidePrev = () => setActiveIndex(activeIndex - 1);
//     const slideNext = () => setActiveIndex(activeIndex + 1);
//     const syncActiveIndex = ({ item }) => setActiveIndex(item);
//     const items = [
//         <div className="item" data-value="1">1</div>,
//         <div className="item" data-value="2">2</div>,
//         <div className="item" data-value="3">3</div>,
//         <div className="item" data-value="4">4</div>,
//         <div className="item" data-value="5">5</div>,
//     ];
    
//     return [
//         <AliceCarousel
//             mouseTracking
//             disableDotsControls
//             disableButtonsControls
//             items={items}
//             activeIndex={activeIndex}
//             responsive={responsive}
//             onSlideChanged={syncActiveIndex}
//         />,
//         <div className="b-refs-buttons">
//             <button onClick={slidePrev}>Prev</button>
//             <button onClick={slideNext}>Next</button>
//         </div>,
//     ];
// };
// export default Carousel