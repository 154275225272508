import React from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import cover from "../../assets/images/about_us.svg";
import fc1 from "../../assets/images/fc1.svg";
import fc2 from "../../assets/images/fc2.svg";
import fc3 from "../../assets/images/fc3.svg";
import desertcar from "../../assets/images/desert_car.svg";
import Footer from "../../components/LandingPage/Footer/Footer";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { selectAuth } from "../../features/Auth/AuthSlice";
import { useSelector } from "react-redux";
import "./About.css";

const About = () => {
  const auth = useSelector(selectAuth);
  return (
    <div className="mainabout">
      {auth ? <NavbarProfile /> : <Navbar />}
      <>
        <div className="wrapperwithoverlap">
          <div className="coversec">
            <img src={cover} alt="" />
          </div>
          <div className="covercard">
            <h3 id="covercardheading">WHAT IS KARBLOCK?</h3>
            <p>
              Karblock is the best chauffeur-driven car rental service in the
              city. We give you the best cars for your budget and take care of
              everything
            </p>
          </div>
        </div>
        <Grid
          container
          spacing={1}
          className={"grid"}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item className="gridcol1">
            <h2>
              TRANSFORM YOUR
              <br />
              TRAVEL EXPERIENCE
            </h2>
            <span>
              TRANSFORM YOUR
              <br />
              TRAVEL EXPERIENCE
            </span>
            <div className="dessertcarcontainer">
              <img src={desertcar} alt="desert car" />
            </div>
          </Grid>
          <Grid item className="gridcol2">
            <div className="knowmorecol2">
              <div className="darkblue">
                <div className="fc1image">
                  <img src={fc1} alt="fc1" />
                </div>
                <div className="fc1row1">
                  <span className="headfc">Your dream car, on-demand</span>
                  <p>
                    You decide which car you want to take out; we will arrange
                    everything else for you! You can even customize your ride by
                    adding extras like GPS or pet insurance
                  </p>
                </div>
              </div>

              <div className="lightblue">
                <div className="fc2image">
                  <img src={fc2} alt="fc2" />
                </div>
                <div className="fc2row2">
                  <span className="headfc">Get the best in less</span>
                  <p>
                    Whatever your budget is, Karblock has got you covered. Save
                    up to 40% on our chauffeur-driven cars without sacrificing
                    service quality! Our affordable prices are unbeatable!
                  </p>
                </div>
              </div>

              <div className="morelightblue">
                <div className="fc3image">
                  <img src={fc3} alt="fc3" />
                </div>
                <div className="fc3row3">
                  <span className="headfc">Savings coupled with Comfort</span>
                  <p>
                    We know how important it is to make sure you're comfortable
                    during your trip, so Karblock will pick up and drop off the
                    car right at your doorstep.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>

      <Footer />
    </div>
  );
};

export default About;
