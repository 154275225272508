import React from "react";
import "./BrowseCars.css";
import bulkbooking from "../../../assets/browsecars.png";
import { useHistory } from "react-router-dom";

const KarblockRenter = () => {
    const history = useHistory();
    const redirect = ()=>{
        history.push("/bookacar")
    }
    return (
        <div style={{ display: "flex", width: "100vw",padding:"0px 0px",justifyContent:"center",alignContent:"center"}}>
            <div className="browseCars">
            <div className="renter">
                    <img src={bulkbooking} alt="bulkbooking" />
                </div>
                <div className="rentercard">
                    <h3><span className="black-heading">Find the perfect car</span><span className="orange-heading">  for scenic corners & curves</span></h3>
                    <p>Get your fill of high-performance thrills, with the richest selection of luxury and exotic vehicles anywhere.</p>
                    <button className="blue-button" onClick={redirect}>Browse Cars</button>
                </div>
                
            </div>
        </div>
    );
};

export default KarblockRenter;
