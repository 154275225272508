import React, { useEffect, useState } from "react";
import crypto from "crypto";
import { useLocation } from "react-router-dom";

const MyComponent = () => {
    let { search } = useLocation();
    const [_data, set_data] = useState([])
    useEffect(() => {
        var data = search.substring(1).split('&');
        data?.forEach((res) => {
            const [name, value] = res.split('=');
            _data[name] = value;
            set_data({ ..._data })
        })
    }, [])
    const return_url = `http://localhost:3001/payment/app/confirmation`;

    const createHash = (value) => {
        const client_id = "29cg3XswQC96OSJ", client_secret = "1H79dRF19B2PxaE", order_id = value;
        const string = client_id + order_id + return_url + client_secret
        var hash = crypto.createHash('sha256').update(string).digest('hex');
        var _hash = crypto.createHash('md5').update(hash).digest('hex');
        return _hash
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const customOrderId = _data.order_id;
        const encrypted_data = await createHash(customOrderId);
        setTimeout(() =>
            window.location.replace(`https://staging-ipg.blinq.pk/Payment/PaymentProcess.aspx?client_id=29cg3XswQC96OSJ&payment_via=${_data?.payment_via}&order_id=${customOrderId}&customer_name=${_data?.customer_name}&customer_email=${_data?.customer_email}&customer_mobile=${_data?.customer_mobile}&order_amount=${_data?.order_amount}&order_expiry_date_time=${_data?.order_expiry_date_time}&product_description=${_data?.product_description}&encrypted_form_data=${encrypted_data}&return_url=${return_url}`)
            , 4000)
    }
    return <div style={{ display: 'flex', flexDirection: 'column', width: "80%", margin: 'auto' }}>
        <p>Customer Name : {_data?.customer_name}</p>
        <p>Customer Mobile:{_data?.customer_mobile}</p>
        <p>Customer Email : {_data?.customer_email}</p>
        <p>Order Amount : {_data?.order_amount}</p>
        <p>Payment Method : {_data?.payment_via}</p>
        <button onClick={handleSubmit} type="submit">Proceed</button>
    </div>
}
export default MyComponent