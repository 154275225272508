import React, { useState, useEffect } from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import daysProps from "../../components/SearchBar/days";
import { addBooking } from "../../features/bookings/bookings.action";
import Swal from "sweetalert2";
import DateFnsUtils from "@date-io/date-fns";
import { getAllBookingReasons } from "../../features/reason/reason.action";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { cityProps } from "../../utills/data";
import MapModal from "./MapModal"
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import axios from "../../api";

const RequestBox = ({ car }) => {
    const [day, set_day] = useState({ value: 1, hours: 24 });
    const [time, setTime] = useState(new Date());
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD')); //this is "FROM" date
    const [date_to, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [reason, set_reason] = useState("")
    const [timeArray, setTimeArray] = useState([]);
    const { reasons } = useSelector((state) => state.reason);
    const [_pickuppoint, set_pickuppoint] = useState([]);
    const [openmap, setopenmap] = useState(false);
    const [loading, setloading] = useState(false);
    const [traveling_city, settraveling_city] = useState("");
    // MAP LOADING 
    const [loadingmap, setloadingmap] = React.useState(false)

    const dispatch = useDispatch()
    const history = useHistory()

    const handleDateChange = (date) => {
        setDate(moment(date).format('YYYY-MM-DD'));
        var d = new Date(date);
        if (moment(date).isAfter(moment(), "day")) {
            d.setHours(0, 0, 0, 0);
            var timeStops = getTimeStops(
                moment(roundMinutes(new Date(d))),
                moment(moment(d).endOf('day'))
            );
            setTimeArray(timeStops);
            setTime(timeStops[0]);
        }
        else {
            var new_date = new Date();
            var timeStops = getTimeStops(
                moment(roundMinutes(new_date)),
                moment(moment(d).endOf('day'))
            );
            setTimeArray(timeStops);
            setTime(timeStops[0]);
        }
        var new_date = moment(date).add(24, "hours");
        setToDate(moment(new_date).format());
    };
    const handleChangeDays = (event, value) => {
        if (!value) return;
        set_day(value);
        let _date = date;
        var new_date = moment(_date).add(value.hours, "hours");
        setToDate(moment(new_date).format());
    }
    const roundMinutes = (date) => {
        const minutes = 30;
        const ms = 1000 * 60 * minutes;

        return new Date(Math.ceil(date.getTime() / ms) * ms);
    };
    const getTimeStops = (start, end) => {
        var startTime = moment(start, "hh:mm")
        var endTime = moment(end, "hh:mm");
        if (endTime.isBefore(startTime)) { endTime.add(1, "day"); }
        var timeStops = [];
        while (startTime <= endTime) {
            timeStops.push(new moment(startTime).format("hh:mm a"));
            startTime.add(30, "minutes");
        }
        return timeStops;
    };

    useEffect(() => {
        let _days = sessionStorage.getItem('days')
        let _pickupdate = sessionStorage.getItem('pickupdate')
        let _time = sessionStorage.getItem('time')
        const getDaysValue = daysProps?.options?.filter((res) => { return res.value == _days })
        let d = new Date();
        var timeStops = getTimeStops(
            moment(roundMinutes(d)),
            moment(moment(d).endOf('day'))
        );
        setTimeArray(timeStops);
        setTime(_time);
        set_day(getDaysValue[0]);
        var new_date = moment(_pickupdate).format("YYYY-MM-DD");
        setDate(new_date)
        setToDate(moment(_pickupdate).add(getDaysValue[0]?.hours, "hours").format());
        dispatch(getAllBookingReasons())
    }, []);
    const timeSlotsProps = {
        options: timeArray,
        getOptionLabel: (option) => option,
    };
    const handleChangeTime = (event, value) => {
        if (!value) return;
        setTime(value);
    };

    const handleChangeReason = (event, value) => {
        if (!value) return;
        set_reason(value.reason);
    };
    const reasonProps = {
        getOptionLabel: (option) => option.reason,
        options: reasons,
    }
    // Pickup Loction
    const setPickupPoint = (e) => {
        set_pickuppoint({
            ..._pickuppoint,
            address: e.target.value,
            name: e.target.value,
        });
    };
    const handleCloseMapModal = () => {
        setopenmap(false);
    };
    const openMapModal = () => {
        setloadingmap(true)
        setTimeout(() => {
            setloadingmap(false)
        }, 2000)
        navigator.permissions
            .query({
                name: "geolocation",
            })
            .then(function (result) {
                if (result.state == "granted") {
                    setopenmap(true);
                } else if (result.state == "prompt") {
                    setopenmap(true);
                } else if (result.state == "denied") {
                    window.alert("Please turn on location!");
                }
            });
    };
    const handleChangeTravelingCity = (event, value) => {
        if (!value) return;
        settraveling_city(value.name);
    };
    // Add BOoking
    let token = localStorage.getItem("token");
    let decoded = jwt_decode(token);
    let userId = decoded.doc._id;
    const userData = decoded.doc

    async function addBackendBooking() {
        let datetime_p = moment(date + ' ' + time, "YYYY-MM-DD hh:mm a").format("YYYY-MM-DD hh:mm a");
        let datetime_d = moment(date_to + ' ' + time, "YYYY-MM-DD hh:mm a").format("YYYY-MM-DD hh:mm a");

        if (!date || !date_to) {
            window.alert("Please select pickup date");
            return;
        }
        else if (!day) {
            window.alert("Please select days");
            return;
        } else if (_pickuppoint.length == 0) {
            window.alert("Please select pickup point");
            return;
        } else if (!reason) {
            window.alert("Please select booking reason");
            return;
        } else if (!traveling_city) {
            window.alert("Please select traveling city");
            return;
        }
        // setloading(true)
        const bookingToAdd = {
            days: day?.value,
            rent: day?.value * car[0]?.rent,
            car_id: car && car[0]?._id,
            pickup_date: moment(datetime_p).seconds(0).milliseconds(0),
            dropoff_date: moment(datetime_d).seconds(0).milliseconds(0),
            renter_id: userId,
            pickup_point: _pickuppoint,
            booking_reason: reason,
            traveling_city: traveling_city,
        };
        await axios.get(`/user?_id=${userId}`).then((res) => {
            if (res?.data?.data[0]?.status === false) {
                Swal.fire({
                    icon: "error",
                    position: "center",
                    title: `Your account is blocked, please contact customer support.`,
                    showConfirmButton: true
                });
            } else if (res?.data?.data[0]?.status=== true) {
                dispatch(addBooking(bookingToAdd, history))
            }
        });
    }



    return (
        <>
            <Grid container className="bookingrequest__requestdetails">
                <Grid item lg={12}>
                    <h3>PKR {car && car[0]?.rent} <span> / day</span></h3>
                    {/* <Divider orientation="horizontal" flexItem /> */}
                </Grid>
                <Grid item lg={12}>
                    <span className="label-requestbox">Time Start</span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className={"dateGrid"}>
                            <KeyboardDatePicker
                                clearable
                                value={date}
                                onChange={(date) => handleDateChange(date)}
                                minDate={new Date()}
                                maxDate={new Date(car && car[0]?.availableTo_date)}
                                invalidDateMessage=""
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"

                                keyboardIcon={<ExpandMore style={{ color: "#F05123" }} />}
                                className={"bookingrequest_dateField"}
                            />
                            <Autocomplete
                                {...timeSlotsProps}
                                closeIcon={null}
                                style={{ fontSize: 10 }}
                                onChange={handleChangeTime}
                                value={time}
                                className={"bookingrequest_timeField"}
                                popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={"outlined"}
                                    />
                                )}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={12} className="bookingrequest__autocomplete">
                    <span className="label-requestbox">Days</span>
                    <Autocomplete
                        onChange={handleChangeDays}
                        {...daysProps}
                        value={day}
                        popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                        // defaultValue={{ value: 1, hours: 24 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ width: 350 }}
                                className="bookingrequest__textfield"
                                placeholder="-- select days --"
                                variant={"outlined"}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg={12} className="bookingrequest__autocomplete">
                    <span className="label-requestbox">Pickup </span>

                    <TextField
                        onClick={openMapModal}
                        value={_pickuppoint?.address}
                        onChange={(e) => setPickupPoint(e)}
                        style={{ width: 350 }}
                        className="bookingrequest__textfield "
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <LocationOnIcon className="location-icon" /></InputAdornment>
                        }}
                        placeholder="-- select pickup --"
                        variant={"outlined"}
                    />

                </Grid>
                <Grid item lg={12} className="bookingrequest__autocomplete">
                    <span className="label-requestbox">Booking Reason </span>
                    <Autocomplete
                        onChange={handleChangeReason}
                        {...reasonProps}
                        popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ width: 350 }}
                                className="bookingrequest__textfield"
                                placeholder="-- select reason --"
                                variant={"outlined"}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg={12} className="bookingrequest__autocomplete">
                    <span className="label-requestbox">Travelling City </span>
                    <Autocomplete
                        onChange={handleChangeTravelingCity}
                        {...cityProps}
                        popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ width: 350 }}
                                className="bookingrequest__textfield"
                                placeholder="-- select city --"
                                variant={"outlined"}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg={12} className="bookingrequest__autocomplete">
                    <button className="reqbooking-button" onClick={addBackendBooking}>
                        {loading ? "Requesting..." :
                            "Request Booking"}</button>
                </Grid>
            </Grid>
            <MapModal open={openmap}
                onClose={handleCloseMapModal} loadingmap={loadingmap} set_pickuppoint={set_pickuppoint} _pickuppoint={_pickuppoint} />
        </>

    );
};
export default RequestBox;
