import api from "../../api/index";
import {
  getLoadingLists,
  getReasonsListsFailure,
  getReasonsListsSuccess,
} from "./reason.reducer";

// get All Reasons
export const getAllBookingReasons = () => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.get(`/reason?type=booking`);
    dispatch(getReasonsListsSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getReasonsListsFailure(err));
    }
  }
};
