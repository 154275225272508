import React, { useEffect, useRef, useState } from "react";
import profileholder from "../../assets/profileholder.png";
import picvector from "../../assets/picvector.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Loader from "../../components/Loader/loader";
import axios from "../../api";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PublishIcon from "@material-ui/icons/Publish";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Divider } from "@material-ui/core";
import { getCities } from "../../features/Dropdown/dropdown.action";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import PopupModal from "../../components/PopupModal/PopupModal";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import { setauth } from "../../features/Auth/AuthSlice";
import { setisotp } from "../../features/OTP/OTPSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Profile.css";

const Profie = ({ phone }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "43vw",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    dispatch(getCities());
  }, []);
  const fullname = useRef("");
  const [mobilenumber, setmobilenumber] = useState(phone);
  const { cities } = useSelector((state) => state.dropdown);

  const address = useRef("");
  const cnic = useRef("");
  const email = useRef("");
  const [city, set_city] = useState("");
  const [profile_image, setprofile_image] = useState("");
  const [cnic_front_image, setcnic_front_image] = useState("");
  const [cnic_back_image, setcnic_back_image] = useState("");
  const [isimageuploadedfront, setisimageuploadedfront] = useState(false);
  const [isimageuploadedback, setisimageuploadedback] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [termsAccepted, settermsAccepted] = useState(false);
  const [status, setstatus] = useState("");
  async function createUserBackend(data) {
    setloading(true);
    axios
      .post(`user/createUser/renter`, data)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        sessionStorage.setItem("token", res.data.token);
        dispatch(setisotp(true));
        dispatch(setauth(true));
        seterrormessage(res.data.message);
        setstatus("Success");
        setshowPopup(true);
        setTimeout(() => {
          setloading(false);
          seterrormessage();
          history.push("/searchcar");
        }, 1000);
      })
      .catch((error) => {
        if (error) {
          seterrormessage(error.response.data.message);
          setcnic_back_image("");
          setcnic_front_image("");
          setprofile_image("");
          setisimageuploadedfront(false);
          setisimageuploadedback(false);
          setstatus("Error");
          setshowPopup(true);
          setTimeout(() => {
            setloading(false);
          }, 1000);
        }
      });
  }

  const citiesProps = {
    options: cities,
    getOptionLabel: (option) => option.city_name,
  };

  const handleChangeCities = (event, value) => {
    if (!value) return;
    set_city(value._id);
  };
  const userHandler = () => {
    
    if (!termsAccepted) {
      window.alert("Please accept term and conditions to continue.");
    } else if (!profile_image) {
      window.alert("Attach a Profile Picture");
    } else if (!cnic) {
      window.alert("Please enter CNIC");
    } else if (cnic.length > 13 || cnic.length < 13) {
      window.alert("Invalid CNIC");
    } else if (!cnic_front_image) {
      window.alert("Attach a CNIC Front Photo");
    } else if (!cnic_back_image) {
      window.alert("Attach a CNIC Back Photo");
    } else if (/^([0-9]{13,})$/.test(cnic.current.value) === false) {
      window.alert("Please Provide a valid CNIC");
    } else if (!city) {
      window.alert("Please select your city.");
    } else {
      const usertoadd = {
        fullname: fullname.current.value,
        cnic: cnic.current.value,
        mobilenumber: mobilenumber,
        address: address.current.value,
        email: email.current.value,
        city: city,
        cnic_front_image,
        cnic: cnic.current.value,
        cnic_back_image,
        profile_image,
        rating: 0,
      };
      createUserBackend(usertoadd);
    }
  };
  const changeHandlerFront = (event) => {
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    axios
      .post("/imageupload/imageUpload", formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        setcnic_front_image(res.data.locationArray[0].fileLocation);
        setisimageuploadedfront(true);
        const file = document.getElementsByClassName("filehandlers");
        for (var j = 0; j < file.length; j++) file[j].value = "";
      })
      .catch((err) => {
        window.alert("CNIC not Uploaded");
      });
  };
  const changeHandlerBack = (event) => {
    // gameImages.push(event.target.files);
    const formData = new FormData();

    formData.append("files", event.target.files[0]);

    axios
      .post("/imageupload/imageUpload", formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        setcnic_back_image(res.data.locationArray[0].fileLocation);
        setisimageuploadedback(true);
        const file = document.getElementsByClassName("filehandlers");
        for (var j = 0; j < file.length; j++) file[j].value = "";
      })
      .catch((err) => {
        window.alert("CNIC not Uploaded");
      });
  };
  const changeHandlerProfile = (event) => {
    // gameImages.push(event.target.files);
    const formData = new FormData();

    formData.append("files", event.target.files[0]);

    axios
      .post("/imageupload/imageUpload", formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        setprofile_image(res.data.locationArray[0].fileLocation);
        const file = document.getElementsByClassName("filehandlers");
        for (var j = 0; j < file.length; j++) file[j].value = "";
      })
      .catch((err) => {
        window.alert("Profile Picture not uploaded");
      });
  };
  const handleTerms = () => {
    settermsAccepted(!termsAccepted);
  };
  if (!mobilenumber) {
    history.push("/signup");
  }
  return (
    <div className="profile">
      <Navbar />
      {showPopup ? (
        <PopupModal
          message={errormessage}
          status={status}
          setshowPopup={setshowPopup}
        />
      ) : null}
      {loading ? <Loader /> : null}
      {!loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="profileform">
            <h3>Complete Your Profile</h3>
            <p>
              Please complete your profile for signing up to Karblock to rent
              your first vehicle.
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                userHandler();
              }}
            >
              <Grid container>
                <Grid item sm={12} md={6} lg={6} className="formgroupprofile">
                  <div className="profilepic">
                    <img
                      src={
                        profile_image
                          ? profile_image.replace(
                              "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",
                              "https://d1w0w4ie5qtn2n.cloudfront.net/"
                            )
                          : profileholder
                      }
                      alt="Profile"
                    />
                    <div>
                      <input
                        id="file-uploadprofile"
                        className="filehandlers"
                        type="file"
                        style={{ display: "none" }}
                        onChange={changeHandlerProfile}
                      />

                      <label
                        htmlFor="file-uploadprofile"
                        style={{ background: "transparent", cursor: "pointer" }}
                      >
                        <PublishIcon className="upload-icon" /> Upload Photo
                      </label>
                    </div>
                  </div>
                  <label htmlFor="name">Full Name</label>
                  <input type="text" name="name" ref={fullname} required />
                  <label htmlFor="city">City</label>
                  <Grid item xs={6} sm={4} md={2} className={classes.paper}>
                    <Autocomplete
                      onChange={handleChangeCities}
                      {...citiesProps}
                      popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "35vw" }}
                          className="bookingrequest__textfield"
                          placeholder="-- select city --"
                          variant={"outlined"}
                        />
                      )}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      className="hr-searchbar"
                    />
                  </Grid>
                  <label htmlFor="Address">Address (Optional)</label>
                  <input type="text" name="Address" ref={address} />
                  <label htmlFor="email">Email (Optional)</label>
                  <input type="email" name="email" ref={email} />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={6}
                  className="formgroupprofile secondcolumn-formgroupprofile"
                >
                  <label htmlFor="Cnic">CNIC</label>
                  <input
                    type="numeric"
                    name="Cnic"
                    ref={cnic}
                    required
                    maxLength="13"
                    minLength={"13"}
                  />
                  <label htmlFor="cnicpic">CNIC Pictures</label>
                  <div className="cnicpichholder">
                    <div className="cncicfront">
                      <div className="holdercnic">
                        <label htmlFor="file-uploadfront">
                          <img
                            src={picvector}
                            alt="Picture Vector"
                            style={{ cursor: "pointer" }}
                          />
                        </label>
                        <input
                          id="file-uploadfront"
                          className="filehandlers"
                          type="file"
                          style={{ display: "none", cursor: "pointer" }}
                          onChange={changeHandlerFront}
                        />
                        {isimageuploadedfront ? (
                          <p>
                            CNIC Front Image uploaded successfully.
                            <CheckCircleIcon style={{ color: "green" }} />
                          </p>
                        ) : (
                          <p>Upload CNIC Front Image</p>
                        )}
                      </div>
                    </div>
                    {/*  */}
                    <div className="cnicback">
                      <div className="holdercnic">
                        <label htmlFor="file-uploadback">
                          <img
                            src={picvector}
                            alt="Picture Vector"
                            style={{ cursor: "pointer" }}
                          />
                        </label>
                        <input
                          id="file-uploadback"
                          className="filehandlers"
                          style={{ display: "none", cursor: "pointer" }}
                          type="file"
                          onChange={changeHandlerBack}
                        />
                        {isimageuploadedback ? (
                          <p>
                            CNIC Back Image uploaded successfully.
                            <CheckCircleIcon style={{ color: "green" }} />
                          </p>
                        ) : (
                          <p>Upload CNIC Back Image</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="termsandcondition">
                    <a href="/#/terms-and-conditions" target="_blank">
                      Terms and conditions
                    </a>
                    <div>
                      <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        onClick={() => handleTerms()}
                        value={termsAccepted}
                      />
                      <label for="terms" className="terms-text">
                        {" "}
                        I agree to terms and conditions
                      </label>
                      <br />
                    </div>
                  </div>
                  <button className="continue" id="continue" type="submit">
                    Continue
                  </button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Profie;
