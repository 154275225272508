import React, { useState } from "react";
import resultcar from "../../assets/resultcar.png";
import BookingDetailsPopup from "./BookingDetailsPopup";
import CancelBookingPopup from "./CancelBookingPopup";
import "../../view/VehicleListing/VehicleListing.css";
import { Rating } from "@material-ui/lab";

const BookingCard = ({ data }) => {
  const [openBD, setOpenBD] = useState(false); //open booking details modal
  const [openCB, setOpenCB] = useState(false); //open cancel details modal

  return (
    <div className="bookingcard">
      <div className="bookingcardcar">
        <img
          src={
            data.car_id.car_images.length > 0
              ? data.car_id.car_images[0].replace(
                  "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",
                  "https://d1w0w4ie5qtn2n.cloudfront.net/"
                )
              : resultcar
          }
          alt={data.car_id.brand.brand_name}
        />
      </div>
      <div className="bookingcardtext">
        <h4>
          {data.car_id.brand.brand_name} {data.car_id.car_name["name"]} -
          {data.car_id.model}
        </h4>
        <div className="bookingcardrating">
          <Rating value={data.car_id.rating} precision={0.5} readOnly />{" "}
          {data.car_id.rating}
        </div>
        <p className="bookingcardprice">PKR {data.car_id.rent} / day </p>
        <p className="bookingcardstatus">
          Status{" "}
          <span
            className={
              data.booking_status == "pending"
                ? "text-pending"
                : data.booking_status == "cancelled"
                ? "text-cancelled"
                : "text-approved"
            }
          >
            {data.booking_status}
          </span>{" "}
        </p>
      </div>
      <div className="bookingcard-actionrow">
        <div className="bookingcard-buttonrow">
          {/* <button>Call Owner</button> */}
          <button onClick={() => setOpenBD(true)}>Booking Details</button>
        </div>
        {data.booking_status == "pending" && (
          <a onClick={() => setOpenCB(true)}>Cancel Booking</a>
        )}
      </div>
      <BookingDetailsPopup open={openBD} setOpenBD={setOpenBD} data={data} />
      <CancelBookingPopup
        open={openCB}
        setOpenCB={setOpenCB}
        data={data}
        id={data._id}
      />
    </div>
  );
};

export default BookingCard;
