import React, { useEffect } from "react";
import { Grid, TextField, makeStyles, Divider } from "@material-ui/core";
import { getFeatures } from "../../features/Dropdown/dropdown.action";
import { useSelector, useDispatch } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./FilterButtons.css"
const FilterPopUp = ({ open, setopenFilter, props }) => {
    const [err, seterr] = React.useState(false)
    const checkForModel = (value, type) => {
        if (type == "min") {
            props.setminModel(value)
        } else if (type = "max") {

            props.setmaxModel(value)
        }
        else { seterr(true) }

    }
    const useStyles = makeStyles((theme) => ({
        features: { cursor: "pointer", fontWeight: "normal" },
        divider: { marginTop: 5, marginBottom: 5 },
        filterLabel: { marginLeft: 20, color: "black" },
        heading: { fontWeight: 300, fontSize: 20 },
    }));
    const classes = useStyles();
    const dispatch = useDispatch()
    const { features } = useSelector((state) => state.dropdown);
    useEffect(() => {
        dispatch(getFeatures())
    }, [])
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    return (
        <Dialog
            open={open}
            className={classes.modal}
            onClose={() => setopenFilter(false)}
            scroll={"paper"}
            maxWidth={'md'}

            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>Filters</DialogTitle>
            <DialogContent dividers={'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}

                >
                    <Grid container>
                        <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <h5 className={`${`${classes.filterLabel} filterLabel`} filterLabel`}>Rent</h5>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4}>
                            <TextField
                                id="outlined-required"
                                label="min rent"
                                variant="outlined"
                                className="textfield-filterpopup"

                                value={props.minRent}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => props.setminRent(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <TextField
                                id="outlined-required"
                                label="max rent"
                                variant="outlined"

                                className="textfield-filterpopup"
                                value={props.maxRent}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => props.setmaxRent(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: 20 }} />
                    <Grid container>
                        <Grid
                            item xs={4} sm={4} md={4}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <h5 className={`${classes.filterLabel} filterLabel`}>Engine Capacity</h5>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <TextField
                                id="outlined-required"
                                label="min engine"
                                variant="outlined"
                                className="textfield-filterpopup"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={props.minEngineCapacity}
                                onChange={(e) => props.setminEngineCapacity(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <TextField
                                id="outlined-required"
                                label="max engine"
                                variant="outlined"
                                className="textfield-filterpopup"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={props.maxEngineCapacity}
                                onChange={(e) => props.setmaxEngineCapacity(e.target.value)}
                            />

                        </Grid>
                    </Grid>
                    <Divider style={{ margin: 20 }} />
                    <Grid container>
                        <Grid
                            item xs={4} sm={4} md={4}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <h5 className={`${classes.filterLabel} filterLabel`}>Model</h5>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <TextField
                                id="outlined-required"
                                label="min model"
                                variant="outlined"
                                className="textfield-filterpopup"
                                error={props.minModel < 2012}

                                value={props.minModel}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => checkForModel(e.target.value, 'min')}
                            />
                            {!(props.minModel > 2011 && props.minModel < 2023) && <p>model value should be after 2012</p>}
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <TextField
                                id="outlined-required"
                                label="min model"
                                variant="outlined"
                                className="textfield-filterpopup"
                                error={props.maxModel > 2022}
                                value={props.maxModel}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => checkForModel(e.target.value, 'max')}
                            />
                            {!(props.maxModel > 2011 && props.maxModel < 2023) && <p>model value should be before 2022</p>}
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: 20 }} />
                    <Grid container>
                        <Grid
                            item xs={4} sm={4} md={4}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <h5 className={`${classes.filterLabel} filterLabel`}>Transmission</h5>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <button
                                id="outlined-required"
                                className={`textfield-filterpopup button-trans ${props.transmission == "manual" ? "buttonselected-trans" : ""}`}
                                onClick={(e) => props.setTransmission("manual")}
                            >
                                Manual
                            </button>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <button
                                id="outlined-required"
                                className={`textfield-filterpopup button-trans ${props.transmission == "auto" ? "buttonselected-trans" : ""}`}

                                onClick={(e) => props.setTransmission("auto")}
                            >
                                Auto
                            </button>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: 20 }} />
                    <Grid container>
                        <Grid
                            item xs={4} sm={4} md={4}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <h5 className={`${classes.filterLabel} filterLabel`}>Features</h5>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            <div className="featureholder">
                                {features?.map((featr) => {
                                    return (
                                        <p 
                                            style={{ cursor: "pointer" }}
                                            className={
                                                props.selectedfeaturesname.includes(featr.feature_name)
                                                    ? "selectedFeature"
                                                    : ""
                                            }
                                            key={featr._id}
                                            value={featr._id}
                                            onClick={() =>
                                                props.addInFeatureQuery(featr._id, featr.feature_name)
                                            }
                                        >
                                            {featr.feature_name}
                                        </p>
                                    );
                                })}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                <button
                    className="filterButton"
                    onClick={() => {
                        props.filterQuery();
                    }}
                >
                    Apply
                </button>
            </DialogActions>
        </Dialog>

    );
};

export default FilterPopUp;
