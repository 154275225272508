import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/LandingPage/Footer/Footer";
import KarblockOwner from "../../components/LandingPage/KarblockOwner/KarblockOwner";
import KarblockRenter from "../../components/LandingPage/KarblockRenter/KarblockRenter";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import Review from "../../components/LandingPage/Review/Review";
import BulkBookingCars from "../../components/LandingPage/BulkBookingCars/BulkBookingCars";
import BrowseCars from "../../components/LandingPage/BrowseCars/BrowseCars";
import RentACar from "../../components/LandingPage/RentACar/RentACar";
import CarsByMake from "../../components/CarsByMake/CarsByMake";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { selectAuth } from "../../features/Auth/AuthSlice";
import axios from "../../api";
import "./LandingPage.css";
import jwt_decode from "jwt-decode";

const LandingPage = () => {
  const auth = useSelector(selectAuth);
  const [user, setuser] = useState();
 
  return (
    <div>
      {auth ? <NavbarProfile user={user} /> : <Navbar />}

      <RentACar />
      <div className="bg-grey">
        <CarsByMake />
      </div>
      <BrowseCars />
      <div className="bg-grey">
        <BulkBookingCars />
      </div>
      <KarblockRenter />
      <div className="bg-grey">
        <KarblockOwner />
      </div>
      {/* <Review /> */}
      <Footer />
    </div>
  );
};

export default LandingPage;
