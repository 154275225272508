import React, { useEffect } from "react";
import "./CarsGrid.css";
import { Divider, Grid, makeStyles, Paper } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        color: theme.palette.text.secondary,
        display: "flex",
        flexDirection: "row",
        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        margin: "0px 40px",
        width: "280px",
    },
}));

const CarsGrid = () => {
    const classes = useStyles();
    const { cars } = useSelector((state) => state.cars);
    const SaveLocalStorage = (id) => {
        sessionStorage.setItem("redirect_to", "requestbooking");
        sessionStorage.setItem("car_id", id);
    };
    const Item = ({ car }) => {
        return (
            <Link onClick={() => { SaveLocalStorage(car._id) }} to={{ pathname: `/requestbooking/${car._id}` }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Paper className={classes.paper}>
                        <div className="main-row">
                            <div className="car_image_cantainer">
                                <img src={car?.car_images?.[0]} />
                            </div>
                            <div className="flex-col2">
                                <div className="cardetails-container">
                                    <p>{car?.brand?.brand_name + " " + car?.car_name?.name + " - " + car?.model}</p>
                                    <div className="cardetails-div">
                                        <Rating value={car?.rating} size="small" />
                                        <span className="black-text">{car?.rating} </span>
                                        <span className="black-text">( {car?.noOfRentals} Trips )</span>
                                    </div>
                                    <Divider className="hr-cardetails" />
                                    <p className="price-details">PKR {car?.rent} / day</p>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Link>
        );
    };
    return (
        <div className={classes.root}>
            <p style={{ width: "80%", marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}><span style={{ fontWeight: "bold" }}>{cars?.length}</span> Search Results </p>
            <br />
            <Grid
                container
                spacing={8}
                justifyContent="center"
                alignItems="center"
            >
                {cars?.map((car) => {
                    return <Item car={car} key={car._id}/>
                })}
                {cars.length == 0 &&
                    <div>
                        No records found
                    </div>
                }
            </Grid>
        </div>
    );
};

export default CarsGrid;
