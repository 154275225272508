import React, { useEffect, useState } from "react";
import moment from "moment";
import { Badge, Menu, MenuItem, Typography, Divider } from "@material-ui/core";
import jwt_decode from "jwt-decode";
import axios from "../../api";
import Footer from "../../components/LandingPage/Footer/Footer";
import loadingbars from "../../assets/loading_bars.svg";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Loader from "../../components/Loader/loader";
import "./Notification.css";
const Notification = () => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      margin: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();

  const [notifications, setnotifications] = useState([]);
  const [count, setcount] = useState(0);
  const [loading, setloading] = useState(false);

  let token = localStorage.getItem("token");
  let decoded = jwt_decode(token);
  let userId = decoded.doc._id;

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);
  async function getNotifications() {
    axios.get(`/notification/${userId}`).then((res) => {
      setnotifications(res.data.data);
      setcount(res.data.count[0].count_notread);
    });
  }
  const readNotification = async (id) => {
    await axios.put(`/notification/readNotification/${id}`);
    getNotifications();
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <NavbarProfile />
      <div className="notificaiton-heading">
        <h2>Notifications</h2>
      </div>
      {loading ? (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      ) : (
        notifications?.map((notification, i) => {
          return (
            <MenuItem
              style={{
                diplay: "flex",
                flexDirection: "column",
                alignItems: "self-start",
              }}
              className={classes.paper}
              onClick={() => readNotification(notification?.messages?._id)}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="overline">
                  {notification?.messages?.title}
                </Typography>
                {notification?.messages?.is_read == false && (
                  <NotificationsActiveIcon
                    style={{ color: "#F05123", marginLeft: 10, fontSize: "20" }}
                  />
                )}
              </div>
              <Typography variant="caption">
                {notification?.messages?.message}
              </Typography>
              <Typography variant="caption">
                {moment(notification?.messages?.date).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </Typography>{" "}
              {notification?.messages?.is_read == false && (
                <Typography variant="caption" style={{ color: "blue" }}>
                  New Notification
                </Typography>
              )}{" "}
              <Divider />
            </MenuItem>
          );
        })
      )}
      <Footer />
    </>
  );
};
export default Notification;
