import React, { useEffect, useState } from "react";
import insta from "../../../assets/insta.png";
import fb from "../../../assets/fb-1.png";
import Location from "../../../assets/Location.png";
import mobile from "../../../assets/Mobile.png";
import message from "../../../assets/Message.png";
import logo from "../../../assets/images/logos/white_logo.svg";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import "./Footer.css";
import axios from "../../../api";

const Footer = () => {
  const [data, setData] = useState([]);
  const getContactUsData = async () => {
    const res = await axios.get(`/contactus`);
    setData(res.data.data[0]);
  };
  useEffect(() => {
    getContactUsData();
  }, []);

  return (
    <div className="footer">
      <div className="colcontainer" style={{ backgroundColor: "black" }}>
        <div className="col1" style={{ backgroundColor: "black" }}>
          <div style={{ backgroundColor: "black" }}>
            <img src={logo} />{" "}
          </div>
          <div style={{ backgroundColor: "black" }}>
            <p className="objective">
              Karblock is your one-stop-shop for chauffeur-driven car rental.
              With us, you can rent premium cars without any hassle. Renting
              with us will make you feel like royalty.
            </p>
          </div>
          <div style={{ backgroundColor: "black", paddingTop: "15px" }}>
            {/* <img
              style={{
                backgroundColor: "black",
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
              src={insta}
              alt="insta"
            />

           <img
              style={{
                backgroundColor: "black",
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
              src={fb}
              alt="youtube"
            />  */}
            <a href="https://www.instagram.com/app.karblock/" target="_blank" rel="noopener noreferrer" >
              <InstagramIcon
                style={{
                  backgroundColor: "black",
                  width: "30px",
                  color: "grey",
                  height: "30px",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="https://facebook.com/karblock" target="_blank" rel="noopener noreferrer" >
              {" "}
              <FacebookIcon
                style={{
                  color: "grey",
                  backgroundColor: "black",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="https://www.linkedin.com/in/kar-block-a3a4bb228/" target="_blank" rel="noopener noreferrer" >
              {" "}
              <LinkedInIcon
                style={{
                  color: "grey",
                  backgroundColor: "black",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="https://www.youtube.com/channel/UCaQDUvVb3bhX0xVTmyMCCZw" target="_blank" rel="noopener noreferrer" >
              {" "}
              <YouTubeIcon
                style={{
                  color: "grey",
                  backgroundColor: "black",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="https://www.twitter.com/appkarblock" target="_blank" rel="noopener noreferrer" >
              {" "}
              <TwitterIcon
                style={{
                  color: "grey",
                  backgroundColor: "black",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
              />
            </a>
          </div>
        </div>
        <div className="col2">
          <h6 style={{ backgroundColor: "black", color: "#fff", margin: "0" }}>
            Quick Links
          </h6>
          <ul>
            <li style={{ backgroundColor: "black" }}>
              <a style={{ color: "#fff" }} href="/#/faqs">
                FAQ's
              </a>
            </li>
            <li style={{ backgroundColor: "black" }}>
              <a style={{ color: "#fff" }} href="/#/about">
                About
              </a>
            </li>
            <li style={{ backgroundColor: "black" }}>
              <a style={{ color: "#fff" }} href="/#/contact">
                Contact
              </a>
            </li>
          </ul>
        </div>

        <div className="col4">
          <h6 style={{ backgroundColor: "black", color: "#fff", margin: "0" }}>
            Reach Us
          </h6>
          <ul>
            <li style={{ backgroundColor: "black" }}>
              <img
                src={message}
                alt="messageicon"
                style={{ marginRight: "10px", backgroundColor: "transparent" }}
              />
              <a style={{ color: "#fff" }} href="#">
                {data?.email}
              </a>
            </li>
            <li style={{ backgroundColor: "black" }}>
              <img
                src={mobile}
                alt="mobileicon"
                style={{ marginRight: "10px", backgroundColor: "transparent" }}
              />
              <a style={{ color: "#fff" }} href="#">
                +{data?.phone}
                {/* +92 345 6789012 */}
              </a>
            </li>
            <li style={{ backgroundColor: "black", maxWidth: 300 }}>
              <img
                src={Location}
                alt="addressicon"
                style={{ marginRight: "10px", backgroundColor: "transparent" }}
              />{" "}
              <a style={{ color: "#fff" }} href="#">
                {/* Nust gate 2, H-13, Islamabad, Pakistan */}
                {data?.address}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="termscondition">
        <p>© 2022 Karblock. All rights reserved</p>
        <p className="spansholder">
          <a href="/#/terms-and-conditions">Terms & Conditions</a> |
          {/* <span> */}
          <a href="/#/privacy-policy"> Privacy Policy</a>
          {/* </span> */}
        </p>
      </div>
    </div>
  );
};

export default Footer;
