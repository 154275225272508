import api from "../../api/index";
import {
    getCitiesList, getFeaturesList, getCarNamesList, getBrandList, getFailure
} from "./dropdown.reducer";

// get All car
export const getBrands = () => async (dispatch) => {
    try {
        const res = await api.get(`/brand`);
        dispatch(getBrandList(res.data.data));
    } catch (err) {
        if (err.response) {
            dispatch(getFailure(err));
        }
    }
};

export const getCarNames = (brand_id) => async (dispatch) => {
    try {
        const res = await api.get(`/car_name?brand=${brand_id}`);
        dispatch(getCarNamesList(res.data.data));
    } catch (err) {
        if (err.response) {
            dispatch(getFailure(err));
        }
    }
};
export const getFeatures = () => async (dispatch) => {
    try {
        const res = await api.get(`/feature`);
        dispatch(getFeaturesList(res.data.data));
    } catch (err) {
        if (err.response) {
            dispatch(getFailure(err));
        }
    }
};
export const getCities = () => async (dispatch) => {
    try {
        const res = await api.get(`/cities`);
        dispatch(getCitiesList(res.data.data));
    } catch (err) {
        if (err.response) {
            dispatch(getFailure(err));
        }
    }
};

