import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Divider,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FilterPopup from "./FilterPopup";
import { filterCar, sortResults, searchCar, getAllCars } from "../../features/cars/cars.action";
import {
  getBrands,
  getCarNames,
  getCities,
} from "../../features/Dropdown/dropdown.action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router"
import daysProps from "./days";
import moment from "moment";
import "./SearchBar.css";
import SortPopup from "./SortPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "row",
  },
  _paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,

  },
  customTextField: {
    fontSize: 10,
    "& input::placeholder": {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: 400,
    },
  },
}));

const SearchBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { cities, brands, carnames } = useSelector((state) => state.dropdown);
  // Search Query
  const [timeArray, setTimeArray] = useState([]);
  const [time, setTime] = useState(new Date());
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD')); //this is "FROM" date
  const [droppffdate, setDroppffdate] = useState(new Date());
  const [brand, set_brand] = useState("");
  const [city, set_city] = useState("");
  const [day, set_day] = useState(1);
  const [carname, set_carname] = useState("");
  // Filter Query
  const [sortMenu, setsortMenu] = useState(null);
  const [openFilter, setopenFilter] = useState(null);
  const [transmission, setTransmission] = useState("");
  const [minRent, setminRent] = useState("");
  const [maxRent, setmaxRent] = useState("");
  const [minEngineCapacity, setminEngineCapacity] = useState("");
  const [maxEngineCapacity, setmaxEngineCapacity] = useState("");
  const [minModel, setminModel] = useState(2012);
  const [maxModel, setmaxModel] = useState(2022);
  let [selectedfeaturesname, setselectedFeaturesName] = useState([]);
  let [selectedfeatures, setselectedFeatures] = useState([]);

  useEffect(() => {
    dispatch(getCities());
    dispatch(getBrands());
  }, []);
  //FILTER FUNCTION
  const addInFeatureQuery = (e, name) => {
    const _id = e;
    if (selectedfeatures.includes(_id)) {
      selectedfeaturesname = selectedfeaturesname.filter(function (item) {
        return item !== name;
      });

      selectedfeatures = selectedfeatures.filter(function (item) {
        return item !== _id;
      });
      setselectedFeatures([...selectedfeatures]);
      setselectedFeaturesName([...selectedfeaturesname]);
    } else {
      selectedfeatures.push(_id);
      selectedfeaturesname.push(name);
      setselectedFeatures([...selectedfeatures]);
      setselectedFeaturesName([...selectedfeaturesname]);
    }
  };

  const set_brand_func = (_id) => {
    dispatch(getCarNames(_id));
    set_brand(_id);
  };
  const brandProps = {
    options: brands,
    getOptionLabel: (option) => option.brand_name,
  };
  const handleChangeBrand = (event, value) => {
    if (!value) return;
    set_brand_func(value._id);
  };

  const carnameProps = {
    options: carnames,
    getOptionLabel: (option) => option.name,
  };

  const handleChangeCarName = (event, value) => {
    if (!value) return;
    set_carname(value._id);
  };

  const citiesProps = {
    options: cities,
    getOptionLabel: (option) => option.city_name,
  };

  const handleChangeCities = (event, value) => {
    if (!value) return;
    set_city(value._id);
  };

  const handleChangeDays = (event, value) => {
    if (!value) return;
    set_day(value.value);
    let _date = date;
    var new_date = moment(_date).add(value.hours, "hours");
    setDroppffdate(moment(new_date).format());
  }
  const roundMinutes = (date) => {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;

    return new Date(Math.ceil(date.getTime() / ms) * ms);
    // date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    // date.setMinutes(0, 0, 0);
    // return date
    // const minutes = 30;
    // const ms = 1000 * 60 * minutes;
    // console.log(new Date(Math.ceil(date.getTime() / ms) * ms))
    // return new Date(Math.ceil(date.getTime() / ms) * ms);
  };
  const getTimeStops = (start, end) => {
    var startTime = moment(start, "hh:mm")
    var endTime = moment(end, "hh:mm");
    if (endTime.isBefore(startTime)) { endTime.add(1, "day"); }
    var timeStops = [];
    while (startTime <= endTime) {
      timeStops.push({ time1: new moment(startTime).format("hh:mm a"), time2: new moment(startTime).format("HH:mm") });
      startTime.add(30, "minutes");
    }
    return timeStops;
  };

  useEffect(() => {
    let d = new Date();
    var timeStops = getTimeStops(
      moment(roundMinutes(d)),
      moment(moment(d).endOf('day'))
    );
    setTimeArray(timeStops);
    setTime(timeStops[0]);
    var new_date = moment(date + ' ' + timeStops[0].time1, "YYYY-MM-DD hh:mm");
    setDate(new_date)
    setDroppffdate(moment(new_date).add(24, "hours").format());

  }, []);
  const timeSlotsProps = {
    options: timeArray,
    getOptionLabel: (option) => option.time1,
  };
  const handleChangeTime = (event, value) => {
    if (!value) return;
    setTime(value);
    let _dateee = moment(date).format('YYYY-MM-DD')
    var new_date = moment(_dateee + ' ' + value, "YYYY-MM-DD hh:mm");
    setDate(new_date)
  };
  const handleDateChange = (date) => {
    setDate(moment(date).format('YYYY-MM-DD'));
    var d = new Date(date);
    if (moment(date).isAfter(moment(), "day")) {
      d.setHours(0, 0, 0, 0);
      var timeStops = getTimeStops(
        moment(roundMinutes(new Date(d))),
        moment(moment(d).endOf('day'))
      );
      setTimeArray(timeStops);
      setTime(timeStops[0]);
    }
    else {
      var new_date = new Date();
      var timeStops = getTimeStops(
        moment(roundMinutes(new_date)),
        moment(moment(d).endOf('day'))
      );
      setTimeArray(timeStops);
      setTime(timeStops[0]);
    }


  };



  // API CALLS

  const searchQuery = () => {
    if (day == 0) {
      window.alert("Please select days");
      return;
    }

    const query = {
      car_name: carname,
      brand: brand,
      pickupdate: new Date(moment(moment(new Date(date)).format('YYYY-M-DD') + " " + time.time2, 'YYYY-M-DD HH:mm:ss').format("YYYY-MM-DDTHH:mm:ssZ")),
      dropoffdate: moment(droppffdate).seconds(0).milliseconds(0),
      city: city,
    };
    dispatch(searchCar(query));
    //save day and pickup date in session storage
    sessionStorage.setItem("days", day);
    sessionStorage.setItem("time", time.time1);
    sessionStorage.setItem("pickupdate", moment(date).seconds(0).milliseconds(0));

    if (history.location.pathname != "/searchcar") {
      history.push("/searchcar")
    }
  };
  const getallcars = () => {
    dispatch(getAllCars());
  };

  async function filterQuery() {
    const query = {
      car_name: carname,
      brand: brand,
      pickupdate: moment(date).seconds(0).milliseconds(0),
      dropoffdate: moment(droppffdate).seconds(0).milliseconds(0),
      city: city,
      minEngineCapacity: minEngineCapacity,
      maxEngineCapacity: maxEngineCapacity,
      minRent: minRent,
      maxRent: maxRent,
      minModel: minModel,
      maxModel: maxModel,
      transmission: transmission,
      selectedfeatures: selectedfeatures,
    };
    await dispatch(filterCar(query));
    setopenFilter(false)
  }
  const _sortResults = (val) => {
    dispatch(sortResults(val));
    setsortMenu(false)
  };
  return (
    <>
      <div className={`${classes.root} mainsearch`}>
        <Grid container spacing={3} className="maingrid" alignItems="center">
          <Grid item xs={6} sm={4} md={2} className={classes.paper}>
            <Autocomplete
              className="autocomplte"
              {...brandProps}
              onChange={handleChangeBrand}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: classes.customTextField }}
                  label="Car Brand"
                  placeholder="Select Brand"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Divider className="hr-searchbar" orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={6} sm={4} md={2} className={classes.paper}>
            <Autocomplete
              className="autocomplte"
              {...carnameProps}
              onChange={handleChangeCarName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: classes.customTextField }}
                  label="Car Name"
                  placeholder="Select Car"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />{" "}
            <Divider orientation="vertical" flexItem className="hr-searchbar" />
          </Grid>
          <Grid item xs={6} sm={4} md={2} className={classes.paper}>
            <Autocomplete
              className="autocomplte"
              {...citiesProps}
              onChange={handleChangeCities}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: classes.customTextField }}
                  label="City"
                  placeholder="Select City"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Divider orientation="vertical" flexItem className="hr-searchbar" />
          </Grid>
          <Grid item xs={6} md={2} className={`${classes._paper} days-grid-mob`}>
            <Autocomplete
              className="autocomplte"
              defaultValue={{ value: 1, hours: 24 }}
              onChange={handleChangeDays}
              {...daysProps}
              name="days"
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: classes.customTextField }}
                  label="Number of days"
                  value={day}
                  placeholder="Select Days"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.paper}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className={"dateGrid"}>
                <KeyboardDatePicker
                  clearable
                  label="From"
                  value={date}
                  onChange={(date) => handleDateChange(date)}
                  invalidDateMessage=""
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  keyboardIcon={<ExpandMore style={{ color: "#F05123" }} />}
                  variant="inline"
                  className={"dateField"}
                />
                <Autocomplete
                  {...timeSlotsProps}
                  closeIcon={null}
                  style={{ fontSize: 10 }}
                  onChange={handleChangeTime}
                  value={time}
                  className={"timeField"}
                  popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      classes={{ root: classes.customTextField }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </div>
            </MuiPickersUtilsProvider>
            <Divider orientation="vertical" flexItem className="hr-searchbar" />
          </Grid>
          <Grid item xs={12} sm={6} md={2} className={`${classes._paper} days-grid-web`}>
            <Autocomplete
              defaultValue={{ value: 1, hours: 24 }}
              className="autocomplte"
              onChange={handleChangeDays}
              {...daysProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: classes.customTextField }}
                  label="Number of days"
                  placeholder="Select Days"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} className="searchicon-grid">
            <SearchIcon className="search-icon" onClick={searchQuery} />
            <button className="search-button" onClick={searchQuery}>Search</button>
          </Grid>
        </Grid>
      </div>
      {history.location.pathname == "/searchcar" && <div className="div-buttons">
        <div className={`${classes.root} mainfilterrow`}>
          <Grid container spacing={2}>
            <Grid item >
              <button className="filter-buttons" aria-controls="simple-menu" aria-haspopup="true" onClick={() => setsortMenu(true)}>Sort</button>
            </Grid>
            <Grid item > <button className="filter-buttons" onClick={() => setopenFilter(true)}>Filter</button></Grid>
            <Grid item > <button className="filter-buttons" onClick={() => getallcars()}>View all cars</button></Grid>
          </Grid>
        </div>

        <FilterPopup open={openFilter} setopenFilter={setopenFilter} props={{
          transmission, addInFeatureQuery, setTransmission, minRent, setminRent, maxRent, setmaxRent, minEngineCapacity, setminEngineCapacity, maxEngineCapacity,
          setmaxEngineCapacity, minModel, setminModel, maxModel, setmaxModel, selectedfeaturesname, setselectedFeaturesName, selectedfeatures, setselectedFeatures, filterQuery
        }} />
        <SortPopup sortMenu={sortMenu} setsortMenu={setsortMenu} _sortResults={_sortResults} />
      </div>}
    </>
  );
};

export default SearchBar;