import React, { useEffect, useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import mask_contactus from "../../assets/images/mask_contactus.svg";
import Footer from "../../components/LandingPage/Footer/Footer";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/Auth/AuthSlice";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import loadingbars from "../../assets/loading_bars.svg";
import Typography from "@material-ui/core/Typography";
import "../Contact/Contact.css";

const PrivacyPolicy = () => {
  const auth = useSelector(selectAuth);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function loadinghandler() {
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    setloading(true);
    loadinghandler();
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        width: "100vw",
      }}
    >
      {auth ? <NavbarProfile /> : <Navbar />}
      {loading ? (
        <div className="loading-div">
          <img src={loadingbars} alt="Loading..." />
        </div>
      ) : (
        <>
          <div className="contactheader">
            <img src={mask_contactus} />
            <h2>Terms And Conditions</h2>
          </div>
          <div style={{ margin: 50 }}>
            <Typography variant="h4" gutterBottom style={{ color: "#383838" }}>
              1. TERMS AND CONDITION OF USE
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              1.1 Scope and Definitions
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              These General Conditions of Use apply to all services provided by
              Karblock Pvt Ltd (defined herein below). Karblock Pvt Ltd owns and
              operates the Portal and a Karblock Mobile App (defined herein
              below) in Pakistan
            </Typography>
            <Typography
              variant="overline"
              gutterBottom
              style={{ color: "#383838", fontWeight: "bold" }}
            >
              Defined Terms
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Karblock Pvt Ltd </span>”
              means an Online Car Rental Services, a company with capital of PKR
              1,000,000.00 registered in Pakistan under company number{" "}
              <span style={{ color: "black" }}>0184939</span>, and hereafter
              referred to as Karblock. The registered office of Karblock is at
              Office No: 9, 2nd Floor, Pakland City Centre, I-8 Markaz,
              Islamabad - Pakistan.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Car Rental</span>” means the
              A car rental or car hire Agency is a company that rents
              automobiles for short periods of time for a fee;
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Conditions</span>” mean
              these General Conditions of Use, including the Good Conduct
              Charter and Privacy Policy of Karblock Pvt Ltd as notified on the
              Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Car Rental Charges</span>”
              means the amount agreed between the Car Owner and the Car Renter
              in relation to the Car Rental for certain period which is payable
              by the Car Renter to the Car Owner.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Car Renter</span>” means a
              member who has accepted an offer to Rent or Hire a Car from Car
              Owner for certain period of time.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Car Owner</span>” or
              “Driver” means a member who through the Web Portal and App offers
              to Lease out a car to a Karblock Member renter in exchange for the
              Car Rental Charges.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Member</span>” refers to a
              registered user of the Karblock Web Portal and App (IOS and
              Android)
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Service</span>” refers to
              any service provided by Karblock Pvt Ltd through the Web Portal
              and an App to any Member.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Web Portal</span>” means
              <span style={{ color: "cornflowerblue" }}>
                {" "}
                www.Karblock.com{" "}
              </span>
              ,
              <span style={{ color: "cornflowerblue" }}>
                {" "}
                www.Karblock.com.pk{" "}
              </span>
              ,
              <span style={{ color: "cornflowerblue" }}> www.Karblock.pk </span>
              and any other Web Portal maintained or operated by Karblock Pvt
              Ltd which offers similar services including any microweb Portals
              or sub-Web Portals offered through any such Web Portal (e.g.,
              “Agenda” and “Campus”).
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Lease</span>” means a
              contract by which Car Owner conveys car to a Car Renter for a
              specified time, in return for a payment through the Karblock Web
              Portal and App.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>User Account</span>” means
              an account with the Web Portal and App opened by a Member and used
              in order to access the Service provided by Karblock Pvt Ltd
              through the Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              “<span style={{ fontWeight: "bold" }}>Vehicle</span>” means the
              vehicle offered by a Car Owner for Car Rental.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              1.2 Acceptance of Conditions
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Conditions apply to any and all use of the Web Portal and App
              by a Member. By using the Web Portal and an App, the Members
              signify their acceptance to these Conditions in full and agree to
              be bound by them.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              No access to the Services will be permitted unless the Conditions
              are accepted in full. No Member is entitled to accept part only of
              the Conditions. If a Member does not agree to the Conditions, such
              Member may not use the Services.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              All Members agree to comply with the Conditions and accept that
              their personal data may be processed in accordance with the
              Privacy Policy.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              In the event that any Member fails to comply with any of the
              Conditions, Karblock Pvt Ltd reserves the right, but not the
              obligation at its own discretion, to withdraw the User Account in
              question and suspend or withdraw all Services to that Member
              without notice.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              These Conditions are intended to create binding rights and
              obligations between Members and Karblock Pvt Ltd in accordance
              with the Pakistan ‘’The Contract Act, 1872’’.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              1.3 Variation of the Conditions, Web Portal and Service
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd reserves the right to modify the Conditions at
              any time. In addition, Karblock Pvt Ltd may vary or amend the
              Services provided through the Web Portal, the Web Portal
              functionality and/ or the “look and feel” of the Web Portal at any
              time without notice and without liability to Members.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Any modification to the Web Portal, Services or Conditions will
              take effect as soon as such changes are published on the Web
              Portal, subject to communication of any material change to the
              Conditions to the Members in an e-mail.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Members will be deemed to have accepted any varied Conditions in
              the event that they use any Services offered through the Web
              Portal following publication of the varied Conditions. Changes
              will not apply to any bookings which have been made prior to
              publication of the varied Conditions.
            </Typography>
            {/* SECOND */}
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              2. USE OF THE SERVICE
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.1 User Account and Accuracy of Information
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              In order to use the Services each Member must create a User
              Account and agrees to provide any personal information requested
              by Karblock Pvt Ltd. In particular, Members will be required to
              provide their information including but not limited to first name,
              last name, age, title, valid telephone number and email address.
              Use of the Web Portal is limited to those over the age of 18 years
              at the time of registration.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Members agree and accept that all of the information they provide
              to Karblock Pvt Ltd when setting up their User Account and at any
              other time shall be true, correct, complete and accurate in all
              respects. Members also agree that any information supplied to
              Karblock Pvt Ltd or posted on the Web Portal in connection with
              any Trip, Vehicle or Car Rental will be true, accurate and
              complete.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Members agree and understand that Karblock Pvt Ltd does not
              undertake any verification to confirm the accuracy of any
              information provided by the Members on the Web Portal or to a Car
              Owner or Car Renter as the case maybe. Karblock Pvt Ltd will not
              be liable to any Member in the event that any information provided
              by another Member is false, incomplete, inaccurate, misleading or
              fraudulent.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Unless expressly agreed by Karblock Pvt Ltd, Members are limited
              to one User Account per Member. No User Account may be created on
              behalf of or in order to impersonate another person.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.2 Status of Karblock Pvt Ltd
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Web Portal and the Services are strictly limited to providing
              a Service for Car Owners and Car Renter to Rent a Car. The
              Services may be used only to offer or accept car Rental in
              exchange for Charges for specific time between the Car Owner and
              the Car-Renter.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Car Rental, collection points and destinations must be pre-agreed
              through the Web Portal between the Car Owner and Car-Renter.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Neither Karblock Pvt Ltd nor the Web Portal provides any transport
              services. The Web Portal is a communications platform for Members
              to transact with one another. Karblock Pvt Ltd does not interfere
              with Trips, destinations or timings. The agreement for car sharing
              is between the Car Owner and the Car-Renter. Karblock Pvt Ltd is
              not a party to any agreement or transaction between Members, nor
              is Karblock Pvt Ltd liable in respect of any matter arising which
              relates to a booking between Members. Karblock Pvt Ltd is not and
              will not act as an agent for any Member.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              <span style={{ color: "white" }}>
                Any breach of these Conditions will give rise to immediate
                suspension of such Member’s User Account and they may be
                restricted from accessing any further Services.
              </span>{" "}
              Moreover, company reserves the right to take legal action against
              the member.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.3 Types of Booking and Payment
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd offers to its Members an online booking service
              (hereinafter, the “Booking Service”) with a view to facilitate the
              booking of Car Available for Rental.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd reserves the right not to offer the Booking
              Service for Rental due to: (i) changes to applicable law, changes
              in the practice of regulatory authorities or changes in case law,
              (ii) changes to market practices or technology changes, (iii)
              changes of business considerations underlying the Booking
              Services, and (iv) other important and valid reasons
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd reserves the right to limit the number and/or
              class of Members eligible to the Booking Service for any Car
              Rental.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              2. How to book a Car-Rental: The Car Owner provides details of his
              or her Car on the Web Portal and App, specifying date and time of
              availability, the amount of the Rental Fee of his car Car for
              Period of 10 hours or more with all other relevant conditions.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd will then send a notification confirmation to
              each of the Car Owner and the Car Renter for confirming the
              Booking (hereinafter, the “Booking Confirmation”). Once a Booking
              Confirmation has been sent, the Booking is complete and a separate
              binding agreement for Car-Rental shall be formed between the
              Car-Owner and Car Renter.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Car Owners will have to pay 10% of the Car Rental earnings to
              Karblock Pvt Ltd by closing the account twice a month on 1st and
              16th day. Failure to do so the car owners membership/ account will
              be strike off by the Karblock Pvt Ltd from the web portal and App.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Collection of Payment is the Car Owner’s responsibility to collect
              payment from the Car Renter at the time of the starting a Rental
              service.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Please note that Karblock Pvt Ltd reserves the right to change any
              aspect of the Web Portal or the Service which may include adding
              new services (which may require payment) or withdrawing any
              existing Services. Karblock Pvt Ltd does not guarantee that the
              Web Portal will be functional at all times and Services may be
              suspended during such period when the Web Portal is not in
              operation. Karblock Pvt Ltd will not be liable to any of the
              Members in case where the Web Portal is non-operational.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.4 Car Owner and Car Renter Obligations
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Car Owner’s obligations
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Car Owner agrees:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                color: "#383838",
                fontStyle: "italic",
                fontWeight: "bold",
                marginLeft: 30,
              }}
            >
              ~ That the Car Rental shall not be for any fraudulent, unlawful or
              criminal activity.
              <br />
              ~ That they will procure for the Vehicle, a insurance policy,
              which provides insurance cover to the Vehicle and covers third
              party liability.
              <br />
              ~ That they will present themselves on time and at the place
              agreed with the specified Vehicle.
              <br />~{" "}
              <span style={{ color: "black" }}>
                That they will immediately inform Car Renter of any change in
                booking. If Car Renter have made a booking and the Car Owner
                decides to change any aspect of the booking, the Car Owner
                undertakes to contact Car Renter who have made a booking for car
                rental and to obtain the agreement of Car Renter to the change.
                If a Car Renter refuses the change, they are entitled to a full
                refund and without any compensation being paid to the Car Owner.
              </span>
              <br />
              ~The Car Owner must comply with the Good Conduct Charter at all
              times.
              <br />
              ~The Car Owner must wait for the Car Renter at the pickup point
              for at least 30 minutes after the agreed time (however, the Car
              Renter is expected to be punctual).
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Car Renters obligations
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Car Renter agrees:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                color: "#383838",
                fontStyle: "italic",
                fontWeight: "bold",
                marginLeft: 30,
              }}
            >
              ~ That the Car Rental shall not be for any fraudulent, unlawful or
              criminal activity.
              <br />
              ~ That they will present themselves on time and at the place
              agreed with the Car Owner;
              <br />
              ~ That they will present themselves on time and at the place
              agreed with the specified Vehicle.
              <br />~{" "}
              <span style={{ color: "white" }}>
                That they will immediately inform the Car Owner or Karblock Pvt
                Ltd if they are required to cancel a booking.
              </span>
              <br />
              ~ That they will comply with the Good Conduct Charter at all
              times.
              <br />
              ~ The Car-Renter agrees to wait at the pickup point for at least
              30 minutes after the agreed time for the Car Owner to arrive.
              <br />~ That they will pay the Rental Amount to the Car Owner
              before starting a journey.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", fontWeight: "bold" }}
            >
              If the Car-Renter or Car Owner fail to comply with any of these
              terms or any other Conditions Karblock Pvt Ltd reserves the right
              to keep information relating to the breach, to publish or disclose
              this information on the Member’s online profile and to suspend or
              withdraw the Member’s access to the Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              That they shall ensure that all other persons who accompany the
              Co-Traveller in the Trip comply with these Conditions as
              applicable to a Co-Traveller.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.5 Full Tank Policy
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Car owners and Car renters agree to the term of Full Tank
              Policy. The Car owner should provide a car with full fuel tank at
              the time of initiating a Rental Journey, and should be approved by
              Car renter.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              After the Rental Period is over, the Car renter should provide a
              car with full fuel tank back to the Car owner and must be approved
              by the Car owner.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd will not be liable for any reduction or
              disagreement arises out of fuel. It is the Car Owners and Car
              Renter responsibility to make sure the get full tank and return
              the same.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.6 Insurance
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Car Owner agrees and undertakes to take out and maintain a
              comprehensive insurance to cover third party liability, the
              occupants of the Vehicle and Rental offered or booked through the
              Web Portal and App. The Car Owner agrees that they will, on
              request, provide to the Karblock with evidence, in advance of the,
              of the complete validity of its insurance policy. The Car Owner
              also undertakes to hold a valid driving licence and that the Car
              Owner will own or will be entitled to use the Vehicle and that the
              Vehicle will have a valid PUC (Pollution Under Control)
              certificate and the Car-Renter is entitled to request evidence of
              the Car Owner’s insurance, registration certificate (‘log book’),
              driving licence and PUC certificate at any time up to completion
              of the Rental Period.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              It is Karblock Pvt Ltd.'s understanding that governmental
              authorities take the view that a Car-Renter who is a third-party
              passenger who is covered by comprehensive third-party insurance
              policy in Pakistan. However, Karblock Pvt Ltd gives no warranty or
              assurance in this regard and it is the Car Owner’s responsibility
              to verify that their insurance provides adequate cover.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              It is up to each Car Owner and Car-Renter to confirm with each
              other that the Car Owner is covered by valid insurance. The Car
              Owner must confirm that their insurance policy allows them to
              carry Car Renter and that their insurance policy covers all Car
              Renter and any accident or incident which may occur during a Trip.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Car Owner and the Car-Renter are aware that standard
              non-commercial insurance policies may refuse to cover loss or
              damage arising in the event that the Car Owner had made or was
              seeking to make a profit.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              If the Car Owner does receive any hiring charges or reward, or if
              the insurers repudiate or refuse to accept any claim arising
              during a Rental Period for any other reason, the Car Owner will be
              responsible for the financial consequences, losses and damages
              arising and Karblock Pvt Ltd will not be liable under any
              circumstances to the Car Owner or the Car-Renter.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd reserves the right, but not the obligation at its
              own discretion, to suspend immediately the account of a user
              including the money displayed and to make aware to competent
              authorities any professional activity.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.6 Management of Disputes Between Members
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd may at its sole discretion provide its Members
              with an online service for resolving disputes. This service is
              non-binding. Karblock Pvt Ltd is under no obligation to seek to
              resolve disputes and this service is offered at Karblock Pvt
              Ltd.'s sole discretion and may be withdrawn at any time.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.7 Verification of IDs and Phone number
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              By accepting the terms and conditions contained herein, every
              Member or any person who wishes to register as a Member hereby
              agrees and consents to the fact that Karblock Pvt Ltd may collect
              IDs / documents belonging to them including but not limited to
              passport, PAN card and CNIC for the purpose of verification of the
              information contained in such IDs / documents by third party
              service providers.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              In order to increase trustworthiness, prevent typos and wrong
              numbers, Member may verify their mobile number. The Member may do
              this by providing Karblock Pvt Ltd with their mobile phone number,
              after which the Member will receive a SMS with a code which can be
              validated on the Web Portal. This service is free of charge,
              except for the possible cost levied by a Member’s mobile phone
              operator for receiving the SMS.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.8 Messages between Members
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd may review, scan, and moderate the messages the
              Members exchange with each other's through the Web Portal in
              particular for fraud prevention, customer support purposes,
              enforcement of the contracts entered into with Karblock Pvt Ltd.'s
              members (such as the Conditions) and ensure compliance with
              applicable law. For example, in order to prevent the circumventing
              of its Booking Service, Karblock Pvt Ltd may scan and analyse
              messages sent through the platform to check that they do not
              include any contact details or references to other Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              By using the Web Portal and accepting the T&C’s, the Member agrees
              that Karblock Pvt Ltd, in its sole discretion, may review, analyse
              and moderate the messages exchanged through the Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              By using the messaging feature of the Web Portal, the Member
              undertakes not to write and/or send any message prohibited by
              applicable law. In particular the Member undertakes to refrain
              from writing/sending any message which content:
            </Typography>
            <ul style={{ marginLeft: 30 }}>
              <li>
                * belongs to another person and to which the Member does not
                have any right to;
              </li>
              <li>
                * is grossly harmful, harassing, blasphemous defamatory,
                obscene, pornographic;
              </li>
              <li>* harms minors in any way;</li>
              <li>
                * infringes any patent, trademark, copyright or other
                proprietary rights;
              </li>
              <li>* violates any law for the time being in force;</li>
              <li>
                * deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature;
              </li>
              <li>* impersonates another person;</li>
              <li>
                * contains software viruses that limit the functionality of any
                computer resource; and
              </li>
              <li>
                * threatens the unity, integrity, defense, security or
                sovereignty of Pakistan, friendly relations with foreign states,
                or public order or causes incitement to the commission of any
                cognizable offence or prevents investigation of any offence or
                is insulting any other nation.
              </li>
            </ul>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              In addition, the Member undertakes to send messages only with
              respect to the booking of Car-Rental and in line with the purposes
              of this Web Portal. The Member undertakes to refrain from using
              the messages for private or confidential communications.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd reserves the right to filter or delete the
              messages and suspend or terminate the User Account of the Member
              and the access of the Member to the Web Portal if it appears
              during the moderation of the messages sent by the Member that s/he
              does not comply with the Conditions and/or applicable law.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              2.10 Contacting Members
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
               By accepting the terms and conditions contained herein, every
              Member hereby agrees and gives consent to Karblock Pvt Ltd to
              communicate via phone calls, SMS, email and such other means as
              Karblock Pvt Ltd may deem fit. Such communications to Members may
              be recorded through technical support provided by third parties
              for the purpose of training, quality and for regularly updating
              the Members about the services of Karblock Pvt Ltd.
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              3. DISCLAIMER OF LIABILITY
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
               3.1 Members may access the Services on the Web Portal at their
              own risk and using their best and prudent judgment before entering
              into any arrangements with other Members through the Web Portal.
              Karblock Pvt Ltd will neither be liable nor responsible for any
              actions or inactions of Members nor any breach of conditions,
              representations or warranties by the Members. Karblock Pvt Ltd
              hereby expressly disclaims and any and all responsibility and
              liability in arising out of the use of the Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              3.2 Karblock Pvt Ltd expressly disclaims any warranties or
              representations (express or implied) in respect of Car Rental,
              accuracy, reliability and completeness of information provided by
              Members, or the content (including details of the Journey and
              Rental Charges) on the Web Portal. While Karblock Pvt Ltd will
              take precautions to avoid inaccuracies in content of the Web
              Portal, all content and information, are provided on an as is
              where is basis, without warranty of any kind. Karblock Pvt Ltd
              does not implicitly or explicitly support or endorse any of the
              Members availing Services from the Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              3.3 Karblock Pvt Ltd is not a party to any agreement between a Car
              Owner and Car-Renter and will not be liable to either the Car
              Owner or the Car-Renter unless the loss or damage incurred arises
              due to Karblock Pvt Ltd.'s negligence.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              3.4 Karblock Pvt Ltd shall not be liable for any loss or damage
              arising as a result of:
            </Typography>
            <div style={{ marginLeft: 30 }}>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                A false, misleading, inaccurate or incomplete information being
                provided by a Member;
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                The cancellation of a Rental Booking by a Car Owner or
                Car-Renter;
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                Any failure to make payment of a Rental Charges.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                Any fraud, fraudulent misrepresentation or breach of duty or
                breach of any of these Conditions by a Car Owner or Car-Renter
                before, during or after a Rental.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                Karblock Pvt Ltd will not be liable to any Member for any
                business, financial or economic loss or for any consequential or
                indirect loss such as lost reputation, lost bargain, lost
                profit, loss of anticipated savings or lost opportunity arising
                as a result of the services provided by Karblock Pvt Ltd
                (whether suffered or incurred as a result of the Karblock Pvt
                Ltd.'s negligence or otherwise){" "}
                <span style={{ color: "white" }}>
                  except in the case of fraud, wilful concealment or theft.
                </span>
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                Karblock Pvt Ltd.'s liability to any Member for all losses in
                respect of any Rental is capped at the sum of PKR 500/-
                (Pakistani Rupees Five Hundred only).
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                Karblock Pvt Ltd will not be liable to any Member in relation to
                any rental unless Karblock Pvt Ltd is notified of a claim
                relating to that rental{" "}
                <span style={{ color: "white" }}>within 3 months </span>
                of completion of the service.
              </Typography>{" "}
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#383838" }}
              >
                Given that Car Owners are required to hold valid insurance to
                cover a Trip and given that Karblock Pvt Ltd.'s service is
                limited to putting Car Owners and Car Renter in touch with each
                other and cannot oversee any Trip, Members accept that the
                limitations on the Karblock Pvt Ltd.'s liability set out above
                are reasonable.
              </Typography>{" "}
            </div>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              4. INDEMNITY AND RELEASE
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              4.1 Members will indemnify and hold harmless Karblock Pvt Ltd, its
              subsidiaries, affiliates and their respective officers, directors,
              agents and employees, from any claim or demand, or actions
              including reasonable attorney’s fees, made by any third party or
              penalty imposed due to or arising out of your breach of these
              Conditions or any document incorporated by reference, or your
              violation of any law, rules, regulations or the rights of a third
              party.
            </Typography>{" "}
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              4.2 Members release Karblock Pvt Ltd and/or its affiliates and/or
              any of its officers and representatives from any cost, damage,
              liability or other consequence of any of the actions/inactions of
              the Members and specifically waiver any claims or demands that
              they may have in this behalf under any statute, contract or
              otherwise.
            </Typography>{" "}
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              5. GENERAL TERMS
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              5.1 Relationship
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              No arrangement between the Members and Karblock Pvt Ltd shall
              constitute or be deemed to constitute an agency, partnership,
              joint venture or the like between the Members and Karblock Pvt
              Ltd.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              5.2 Suspension or Withdrawal of Web Portal Access
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              In the event of non-compliance on your part with all or some of
              the Conditions, you acknowledge and accept that Karblock Pvt Ltd
              can at any time, without prior notification, interrupt or suspend,
              temporarily or permanently, all or part of the service or your
              access to the Web Portal (including in particular your User
              Account).
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              5.3 Intellectual Property
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The format and content included on the Web Portal, such as text,
              graphics, logos, button icons, images, audio clips, digital
              downloads, data compilations, and software, is the property of
              Karblock Pvt Ltd, its affiliates or its content suppliers and is
              protected by Pakistan and international copyright, authors’ rights
              and database right laws.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              All rights are reserved in relation to any registered and
              unregistered trademarks (whether owned or licensed to Karblock Pvt
              Ltd) which appear on the Web Portal.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              The Web Portal or any portion of the Web Portal may not be
              reproduced, duplicated, copied, sold, resold, Web Portal, or
              otherwise exploited for any commercial purpose without the express
              written consent of Karblock Pvt Ltd. No person is entitled to
              systematically extract and/or re-utilise parts of the contents of
              the Web Portal without the express written consent of Karblock Pvt
              Ltd. In particular, the use of data mining, robots, or similar
              data gathering and extraction tools to extract (whether once or
              many times) for re-utilisation of any substantial parts of this
              Web Portal is strictly prohibited.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              5.4 Content of the Web Portal Provided by the Members
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              By displaying content on this Web Portal, Members expressly grant
              a license to Karblock Pvt Ltd to display the content and to use it
              for any of our other business purposes.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Members of this Web Portal are expressly asked not to publish any
              defamatory, misleading or offensive content or any content which
              infringes any other persons intellectual property rights (e.g.
              copyright). Any such content which is contrary to Karblock Pvt
              Ltd’s policy and Karblock Pvt Ltd does not accept liability in
              respect of such content, and the Member responsible will be
              personally liable for any damages or other liability arising and
              agrees to indemnify Karblock Pvt Ltd in relation to any liability
              it may suffer as a result of any such content. However as soon as
              Karblock Pvt Ltd becomes aware of infringing content, Karblock Pvt
              Ltd shall do everything it can to remove such content from the Web
              Portal as soon as possible.
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "#383838" }}>
              5.5 Partner Web Portals
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Karblock Pvt Ltd reserves the right to reproduce any information
              that appears on the Web Portal or on the partner Web Portals.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              In particular, ads published on one of the Web Portals maintained
              or co-maintained by Karblock Pvt Ltd may be reproduced on other
              Web Portals maintained or co-maintained by Karblock Pvt Ltd or
              third parties.
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              6. LAW AND JURISDICTION
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              These terms shall be governed by the law of Pakistan and any
              disputes arising in relation to these terms shall be subject to
              the jurisdiction of the Courts of Islamabad.
            </Typography>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
