import React, { useState } from "react";
import { Grid, makeStyles, Dialog, DialogContent, DialogContentText, DialogTitle, Avatar } from "@material-ui/core";
import { Rating } from "@material-ui/lab"
import Swal from "sweetalert2";
import CallIcon from '@material-ui/icons/Call';
import axios from "../../api";
import moment from "moment";
import "./style.css"
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getAllBookings } from "../../features/bookings/bookings.action";

const BookingDetailsPopup = ({ open, setOpenBD, data }) => {
    const useStyles = makeStyles((theme) => ({
        features: { cursor: "pointer", fontWeight: "normal" },
        divider: { marginTop: 5, marginBottom: 5 },
        filterLabel: { marginLeft: 20, color: "black" },
        heading: { fontWeight: 300, fontSize: 20 },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
    }));
    let token = localStorage.getItem("token");
    let decoded = jwt_decode(token);
    let userId = decoded.doc._id;
    const dispatch = useDispatch();
    const classes = useStyles();
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    const submitRating = (value) => {
        const body = { rating: Number(value) };
        axios.put(`/rating/updatebookingRating/${data._id}`, body).then((res) => {
            Swal.fire({
                icon: "success",
                position: "center",
                title: `Your review has been submitted`,
                showConfirmButton: true
            });
            dispatch(getAllBookings(userId));
            setOpenBD(false)
        });
    };
    return (
        <Dialog
            open={open}
            onClose={() => setOpenBD(false)}
            scroll={"paper"}
            maxWidth={'md'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">
                <div className="bd-col1">
                    <Avatar
                        alt={data?.owner_id?.fullname}
                        src={data?.owner_id?.profile_image?.replace(
                            "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",
                            "https://d1w0w4ie5qtn2n.cloudfront.net/"
                        )}
                        className={classes.large}
                    />
                    <div className="bd-detail">
                        <span className="bd-owner-name">{data?.owner_id?.fullname}</span>
                        <div className="bd-mobilenumber"><span><CallIcon className="callicon" size={"small"} /></span><span>{data?.owner_id?.mobilenumber}</span></div>
                        <div className="bd-rating"><span><Rating size="small" value={data?.owner_id?.rating} readOnly /></span><span>{data?.owner_id?.noofRentals} Rentals</span></div>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent dividers={'paper'}>
                {/* <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}> */}
                <Grid container style={{ display: "flex", flexDirection: "column" }}>
                    <table className="bd-table-width">
                        <tbody>
                            <tr className="bd-table-row"><td >Booking Number</td><td> </td><td>{data?.booking_number}</td></tr>
                            <tr className="bd-table-row"> <td>Invoice Date</td><td> </td><td>{moment(data?.createdAt).format("DD/MM/YYYY hh:mm a")}</td></tr>
                            <tr className="bd-table-row"><td>Pickup Date</td><td> </td><td>{moment(data?.pickup_date).format("DD/MM/YYYY hh:mm a")}</td></tr>
                            <tr className="bd-table-row"><td>Dropoff Date</td><td> </td><td>{moment(data?.dropoff_date).format("DD/MM/YYYY hh:mm a")}</td></tr>
                            <tr className="bd-table-row"><td>Booking Days</td><td> </td><td>{data?.days ? data?.days : 0}</td></tr>
                            <tr className="bd-table-row"><td>Booking Reason</td><td> </td><td>{data?.booking_reason}</td></tr>
                            <tr className="bd-table-row"><td>Travelling To</td><td> </td><td>{data?.traveling_city}</td></tr>
                            <tr className="bd-table-row"><td>Pickup Point</td><td> </td><td>{data?.pickup_point?.address}</td></tr>
                            <tr className="bd-table-row"><td>Charges per day</td><td> </td><td>PKR {data?.car_id?.rent}</td></tr>
                            <tr className="bd-table-row"><td>Total Charges</td><td> </td><td>PKR {data?.initial_bill}</td></tr>

                            {(data?.booking_status === 'ended' && data?.rating) > 0 ?
                                <>
                                    <tr className="bd-table-row"><td>Extra Charges</td><td> </td><td>PKR {data?.booking_status == "ended" ? data?.additional_charges : 0}</td></tr>
                                    <tr className="bd-table-row"><td>Grand Total</td><td> </td><td>PKR {data?.booking_status == "ended" ? data?.final_bill : data?.initial_bill}</td></tr>
                                    <tr className="bd-table-lastrow"><td>Your Rating</td><td> </td><td><Rating
                                        name="simple-controlled"
                                        value={data?.rating}
                                        readOnly /></td></tr>

                                </>
                                :
                                <>
                                    <tr className="bd-table-row"><td>Extra Charges</td><td> </td><td>PKR {data?.booking_status == "ended" ? data?.additional_charges : 0}</td></tr>
                                    <tr className="bd-table-lastrow"><td>Grand Total</td><td> </td><td>PKR {data?.booking_status == "ended" ? data?.final_bill : data?.initial_bill}</td></tr>
                                </>
                            }
                        </tbody>
                    </table>
                    {data?.booking_status === 'ended' && !data?.rating && <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Rating
                            name="simple-controlled"
                            onChange={(event) => submitRating(event.target.value)}
                        />
                    </div>}
                    {/* {data?.booking_status === 'ended' && data?.rating > 0 && <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Rating
                            name="simple-controlled"
                            value={data?.rating}
                            readOnly
                        />
                    </div>} */}
                </Grid>
                {/* </DialogContentText> */}
            </DialogContent>
        </Dialog>

    );
};

export default BookingDetailsPopup;
