import React, { useEffect, useState } from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputAdornment,
  FormHelperText,
  Snackbar,
} from "@material-ui/core";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import MuiAlert from "@material-ui/lab/Alert";
import Footer from "../../components/LandingPage/Footer/Footer";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/Auth/AuthSlice";
import Swal from "sweetalert2";
import axios from "../../api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import pakistan from "../../assets/pakistan.png";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Loader from "../../components/Loader/loader";
import DateFnsUtils from "@date-io/date-fns";
import shortid from "shortid";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PersonIcon from "@material-ui/icons/Person";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepsDiv: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function getSteps() {
  return ["User Details", "Booking Details", "Request Cars"];
}

export default function HorizontalLinearStepper() {
  const auth = useSelector(selectAuth);
  const [loading, setloading] = useState(false);
  const [cityList, setcityList] = useState([]);
  const [carsList, setcarsList] = useState([]);
  const [brandList, setbrandList] = useState([]);
  const [brand, set_brand] = useState("");
  const [city, set_city] = useState("");
  const [cnic, set_cnic] = useState("");
  const [phone, set_phone] = useState("");
  const [name, set_name] = useState("");
  const [address, set_address] = useState("");
  const [pickupdate, set_pickupdate] = useState(new Date());
  const [dropoffdate, set_dropoffdate] = useState(new Date());
  const [pickuptime, set_pickuptime] = useState(new Date());
  const [dropofftime, set_dropofftime] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [messageAlert, setmessageAlert] = useState("");
  const [cars, setcars] = useState([
    {
      id: shortid.generate(),
      car_name: { _id: "", car_name: "" },
      brand: { _id: "", brand_name: "" },
      model: new Date(),
      quantity: "1",
    },
  ]);
  const roundMinutes = (date) => {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;
  
    return new Date(Math.ceil(date.getTime() / ms) * ms);
  };
  const getTimeStops = (start, end) => {
    var startTime = moment(start, "hh:mm");
    var endTime = moment(end, "hh:mm");
    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }
    var timeStops = [];
    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("hh:mm a"));
      startTime.add(30, "minutes");
    }
    return timeStops;
  };
  // TIME SLOTS
  const [timeArray, setTimeArray] = useState([]);

  useEffect(() => {
    let d = new Date();
    var timeStops = getTimeStops(
      moment(roundMinutes(d)),
      moment(moment(d).endOf("day"))
    );
    setTimeArray(timeStops);
    set_pickuptime(timeStops[0]);
    set_dropofftime(timeStops[0]);
    set_dropoffdate(moment(new Date()).add(1, "days"));
  }, []);
  const timeSlotsProps = {
    options: timeArray,
    getOptionLabel: (option) => option,
  };
  const handleChangeTime_p = (event, value) => {
    if (!value) return;
    set_pickuptime(value);
    set_dropofftime(value);
  };
  const handleChangeTime_d = (event, value) => {
    if (!value) return;
    set_dropofftime(value);
  };
  const handleDateChange = (date) => {
    set_pickupdate(moment(date).format("YYYY-MM-DD"));
    var d = new Date(date);
    if (moment(date).isAfter(moment(), "day")) {
      d.setHours(0, 0, 0, 0);
      var timeStops = getTimeStops(
        moment(roundMinutes(new Date(d))),
        moment(moment(d).endOf("day"))
      );
      setTimeArray(timeStops);
      set_pickuptime(timeStops[0]);
      set_dropofftime(timeStops[0]);
    } else {
      var new_date = new Date();
      var timeStops = getTimeStops(
        moment(roundMinutes(new_date)),
        moment(moment(d).endOf("day"))
      );
      setTimeArray(timeStops);
      set_pickuptime(timeStops[0]);
      set_dropofftime(timeStops[0]);
    }
    var new_date = moment(date).add(24, "hours");
    set_dropoffdate(moment(new_date).format());
  };

  const handleDateChange_d = (date) => {
    set_dropoffdate(moment(date).format("YYYY-MM-DD"));
    var d = new Date(date);
    if (moment(date).isAfter(moment(), "day")) {
      d.setHours(0, 0, 0, 0);
      var timeStops = getTimeStops(
        moment(roundMinutes(new Date(d))),
        moment(moment(d).endOf("day"))
      );
      setTimeArray(timeStops);
      set_dropofftime(timeStops[0]);
    } else {
      var new_date = new Date();
      var timeStops = getTimeStops(
        moment(roundMinutes(new_date)),
        moment(moment(d).endOf("day"))
      );
      setTimeArray(timeStops);
      set_dropofftime(timeStops[0]);
    }
    var new_date = moment(date).add(24, "hours");
    set_dropoffdate(moment(new_date).format());
  };

  const handleClose = () => {
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  // VALIDATIONS CHECK START
  const [invalidCnic, set_invalidCnic] = useState(false);
  const [invalidphone, set_invalidphone] = useState(false);
  const [invalidcity, set_invalidcity] = useState(false);
  const [invalidaddress, set_invalidaddress] = useState(false);
  const [invalidQty, set_invalidQty] = useState(false);
  const [success, set_success] = useState(false);
  const [invalidName, set_invalidName] = useState(false);

  const cnicValidation = (value) => {
    if (!value) return;
    else if (value.length != 13) {
      set_invalidCnic(true);
    } else if (/^([0-9]{13,})$/.test(cnic) == false) {
      set_invalidCnic(true);
    } else if (/^([0-9]{13,})$/.test(cnic) == true) {
      return true;
    }
  };

  const phoneValidation = (value) => {
    if (!value) return;
    else if (/^([3][0-9]{9,})$/.test(phone) == false || phone.length != 10) {
      set_invalidphone(true);
      return false;
    }
  };

  const checkQuantity = (value, row_id) => {
    if (value < 1) {
      const carIndex = cars.findIndex((car) => car.id === row_id);
      cars[carIndex]["quantity"] = 1;
      setcars([...cars]);
      set_invalidQty(true);
    }
  };
  const cityaddressValidation = (name) => {
    if (name == "city" && !city.length) {
      set_invalidcity(true);
    } else if (name == "address" && !address.length) {
      set_invalidaddress(true);
    }
  };
  // VALIDATION CHECK END

  useEffect(() => {
    async function getBrands() {
      axios.get("/brand").then((res) => {
        setbrandList(res.data.data);
      });
      axios.get("/cities").then((res) => {
        setcityList(res.data.data);
      });
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    setloading(true);
    getBrands();
  }, []);

  useEffect(() => {
    async function getCarsByBrand() {
      axios
        .get(`/car_name?brand=${brand}`)
        .then((res) => {
          setcarsList(res.data.data);
        })
        .catch((err) => {
          setcarsList([]);
        });
    }
    getCarsByBrand();
  }, [brand]);

  const set_city_func = (_id) => {
    set_city(_id);
  };

  // THIRD PANEL ADD MULIPLE ROWS
  const addNewCarRow = () => {
    const newRow = {
      id: shortid.generate(),
      car_name: { _id: "", car_name: "" },
      brand: { _id: "", brand_name: "" },
      model: new Date(),
      quantity: "1",
    };
    setcars([...cars, newRow]);
  };
  const removeCarRow = (car_id) => {
    let _cars = cars.filter(function (item) {
      return item.id != car_id;
    });
    setcars([..._cars]);
  };
  const getCarRow = () => {
    return cars.map((car) => {
      return (
        <Grid
          container
          spacing={4}
          // sm={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            width: 1100,
          }}
          key={car.id}
        >
          <Grid item sm={3} xs={12}>
            <Autocomplete
              {...brandProps}
              id="controlled-demo"
              onChange={(e, value) => handleChangeBrand(e, value, car.id)}
              renderInput={(params) => (
                <TextField
                  name={"brand"}
                  value={car.brand.brand_name}
                  {...params}
                  label="Brand Name"
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Autocomplete
              {...carnameProps}
              id="controlled-demo"
              onChange={(e, value) => handleChangeCarname(e, value, car.id)}
              renderInput={(params) => (
                <TextField
                  name={"brand"}
                  variant="outlined"
                  value={car.car_name.car_name}
                  {...params}
                  label="Car Name"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                margin="normal"
                id="date-picker-inline"
                label="Model"
                inputVariant="outlined"
                minDate={new Date('2012-03-01')}
                maxDate={new Date()}
                openTo="year"
                views={["year"]}
                value={car.model}
                onChange={(value) => handleChangeModel(value, car.id)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item sm={2} xs={12}>
            <TextField
              type="Number"
              variant="outlined"
              inputProps={{ minLength: 1 }}
              name={"quantity"}
              value={car.quantity}
              error={car.quantity < 1 ? true : false}
              label="Quantity"
              onFocus={() => set_invalidQty(false)}
              onChange={(e, value) => handleChangeQty(e, car.id)}
              onBlur={(e) => checkQuantity(e.target.value, car.id)}
              margin="normal"
            />
            {car.quantity < 1 && (
              <FormHelperText style={{ color: "red" }}>
                Quantity must be greater than 0 !
              </FormHelperText>
            )}
          </Grid>
          <Grid sm={1} xs={12} style={{ marginTop: 50, cursor: "pointer" }}>
            {cars.length > 1 && (
              <HighlightOffIcon
                size={50}
                onClick={() => removeCarRow(car.id)}
                color="secondary"
              />
            )}
            <AddCircleOutlineIcon
              onClick={addNewCarRow}
              color="primary"
              size={50}
            />
          </Grid>
        </Grid>
      );
    });
  };
  // THIRD PANEL ADD MULIPLE END

  // CITy DROPDOWN

  const cityProps = {
    options: cityList,
    getOptionLabel: (option) => option.city_name,
  };
  const handleChangecity = (event, value) => {
    if (!value) return;
    set_city_func(value._id);
  };

  // BRAND DROPDOWN
  const brandProps = {
    options: brandList,
    getOptionLabel: (option) => option.brand_name,
  };
  const handleChangeBrand = (event, value, row_id) => {
    if (!value) return;
    set_brand(value._id);
    const carIndex = cars.findIndex((car) => car.id === row_id);
    cars[carIndex]["brand"]["_id"] = value._id;
    cars[carIndex]["brand"]["brand_name"] = value.brand_name;
    setcars([...cars]);
  };
  const handleChangeQty = (event, row_id) => {
    const carIndex = cars.findIndex((car) => car.id === row_id);
    cars[carIndex]["quantity"] = event.target.value;
    setcars([...cars]);
  };
  const handleChangeModel = (value, row_id) => {
    if (!value) return;
    const carIndex = cars.findIndex((car) => car.id === row_id);
    cars[carIndex]["model"] = value;
    setcars([...cars]);
  };

  // CAR NAME DROPDOWN
  const carnameProps = {
    options: carsList,
    getOptionLabel: (option) => option.name,
  };
  const handleChangeCarname = (event, value, row_id) => {
    if (!value) return;
    const carIndex = cars.findIndex((car) => car.id === row_id);
    cars[carIndex]["car_name"]["_id"] = value._id;
    cars[carIndex]["car_name"]["car_name"] = value.name;
    setcars([...cars]);
  };
  const saveBookingRequest = async () => {
    setloading(true);
    let datetime_p = moment(
      pickupdate + " " + pickuptime,
      "YYYY-MM-DD hh:mm a"
    ).format("YYYY-MM-DD hh:mm a");
    let datetime_d = moment(
      dropoffdate + " " + dropofftime,
      "YYYY-MM-DD hh:mm a"
    ).format("YYYY-MM-DD hh:mm a");

    if (!name || !phone || !cnic || !city || !address) {
      window.alert("Missing Information !");
      return;
    }
    if (cars[0].brand.brand_name == "" || cars[0].car_name.name == "") {
      window.alert("Please select atleast one car !");
      return;
    }
    if (cars.length > 0) {
      let _cars_rec = [];
      cars.forEach((res) => {
        _cars_rec.push({
          id: res.id,
          car_name: res.car_name._id,
          brand: res.brand._id,
          model: res.model.getFullYear(),
          quantity: res.quantity < 0 ? 1 : res.quantity,
        });
      });
      const body = {
        name: name,
        phonenumber: phone,
        cnic: cnic,
        requested_cars: _cars_rec,
        city: city,
        address: address,
        dropoff_date: datetime_d,
        pickup_date: datetime_p,
      };
      await axios
        .post(`booking_request/addbookingrequest`, body)
        .then((res) => {
          set_success(true);
          setmessageAlert(res.data.message);
          setOpen(true);
          setloading(false);

          Swal.fire({
            icon: "success",
            position: "center",
            title: res.data.message,
            showConfirmButton: true,
          });
          set_name("");
          set_cnic("");
          set_city("");
          set_phone("");
          set_address("");
          set_pickupdate(new Date());
          set_dropoffdate(new Date());
          setActiveStep(0);
          setcars([
            {
              id: shortid.generate(),
              car_name: { _id: "", car_name: "" },
              brand: { _id: "", brand_name: "" },
              model: new Date(),
              quantity: "1",
            },
          ]);
        })
        .catch((err) => {
          if (err.response) {
            setloading(false);
            set_success(false);
            Swal.fire({
              icon: "error",
              position: "center",
              title: err.response.data.message,
              showConfirmButton: true,
            });
            setOpen(true);
          }
        });
    }
    setloading(false);
  };
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep == 0) {
      if (
        phone.length > 1 &&
        name.length > 1 &&
        cnic.length > 1 &&
        !invalidName &&
        !invalidphone &&
        !invalidCnic
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (phone.length == 0 && cnic.length == 0 && name.length == 0) {
        set_invalidphone(true);
        set_invalidCnic(true);
        set_invalidName(true);
      } else if (phone.length == 0 || invalidphone) {
        set_invalidphone(true);
      } else if (cnic.length == 0 || invalidCnic) {
        set_invalidCnic(true);
      } else if (name.length == 0 || invalidName) {
        set_invalidName(true);
      }
    }

    if (activeStep == 1) {
      if (
        city.length > 1 &&
        address.length > 1 &&
        !invalidcity &&
        !invalidaddress
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (city.length == 0 || address.length) {
        set_invalidcity(true);
        set_invalidaddress(true);
      } else if (invalidcity) {
        set_invalidcity(true);
      } else if (invalidaddress) {
        set_invalidaddress(true);
      }
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const secondPanel = () => {
    return (
      <Grid container spacing={4} style={{ width: 1000 }}>
        <Grid item md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={"dateGrid"}>
              <KeyboardDatePicker
                clearable
                value={pickupdate}
                onChange={(date) => handleDateChange(date)}
                minDate={new Date()}
                label="Pickup Date"
                invalidDateMessage=""
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
              />
              <Autocomplete
                {...timeSlotsProps}
                closeIcon={null}
                label="DropOff Date & Time"
                style={{ fontSize: 10 }}
                onChange={handleChangeTime_p}
                value={pickuptime}
                className={"bookingrequest_timeField"}
                popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} />
                )}
              />
            </div>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={"dateGrid"}>
              <KeyboardDatePicker
                clearable
                value={dropoffdate}
                onChange={(date) => handleDateChange_d(date)}
                minDate={pickupdate}
                // maxDate={new Date(car && car[0]?.availableTo_date)}
                invalidDateMessage=""
                label="Drop-off Date"
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
              />
              <Autocomplete
                {...timeSlotsProps}
                closeIcon={null}
                style={{ fontSize: 10 }}
                onChange={handleChangeTime_d}
                value={dropofftime}
                className={"bookingrequest_timeField"}
                popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} />
                )}
              />
            </div>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item md={6}>
          <FormControl
            className={classes.margin}
            variant="outlined"
            onFocus={() => set_invalidcity(false)}
          >
            <Autocomplete
              {...cityProps}
              id="controlled-demo"
              onChange={handleChangecity}
              renderInput={(params) => (
                <TextField
                  style={{ width: 380, marginRight: 20 }}
                  name={"city"}
                  value={city}
                  placeholder="City"
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Select City"
                  variant="outlined"
                  onFocus={() => set_city(false)}
                  onBlur={() => cityaddressValidation("city")}
                />
              )}
            />
            {invalidcity && (
              <FormHelperText style={{ color: "red" }}>
                Invalid city input !
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl className={classes.margin} variant="outlined">
            <TextField
              required
              style={{ width: 380 }}
              label="Address"
              id="outlined-start-adornment"
              value={address}
              placeholder="Address"
              onChange={(e) => set_address(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeIcon style={{ color: "#9E9E9E" }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onFocus={() => set_invalidaddress(false)}
              onBlur={() => cityaddressValidation("address")}
            />
            {invalidaddress && (
              <FormHelperText style={{ color: "red" }}>
                Invalid address input !
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const firstPanel = () => {
    return (
      <Grid
        container
        spacing={4}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Grid item sm={4}>
          <FormControl className={classes.margin} variant="outlined">
            <TextField
              onFocus={() => set_invalidName(false)}
              required
              style={{ width: 300 }}
              fullWidth
              label="Name"
              id="outlined-start-adornment"
              placeholder="Name"
              error={invalidName ? true : false}
              value={name}
              onChange={(e) => set_name(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon style={{ color: "#9E9E9E" }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            {invalidName && (
              <FormHelperText style={{ color: "red" }}>
                Please enter a valid Name.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl variant="outlined">
            <TextField
              onFocus={() => set_invalidCnic(false)}
              required
              fullWidth
              inputProps={{
                maxLength: 13,
              }}
              error={invalidCnic ? true : false}
              style={{ width: 300 }}
              label="CNIC"
              id="outlined-start-adornment"
              placeholder="1234512345671"
              value={cnic}
              onChange={(e) => set_cnic(e.target.value)} // className={clsx(classes.margin, classes.textField)}
              onBlur={(e) => cnicValidation(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RecentActorsIcon style={{ color: "#9E9E9E" }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </FormControl>
          {invalidCnic && (
            <FormHelperText style={{ color: "red" }}>
              Please enter a valid CNIC number.
            </FormHelperText>
          )}
          {cnic.length > 13 && (
            <FormHelperText style={{ color: "red" }}>
              Please enter a valid CNIC number.
            </FormHelperText>
          )}
        </Grid>
        <Grid item sm={4}>
          <FormControl className={classes.margin} variant="outlined">
            <TextField
              onFocus={() => set_invalidphone(false)}
              fullWidth
              error={invalidphone ? true : false}
              style={{ width: 300 }}
              label="Mobile Number"
              id="outlined-start-adornment"
              placeholder="3121234567"
              value={phone}
              inputProps={{
                maxLength: 10,
              }}
              onChange={(e) => set_phone(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={pakistan} alt="pakistan flag" />
                  </InputAdornment>
                ),
              }}
              onBlur={(e) => phoneValidation(e.target.value)}
              variant="outlined"
              required
            />
          </FormControl>
          {invalidphone && (
            <FormHelperText style={{ color: "red" }}>
              Please enter a valid Mobile Number.
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    );
  };

  const thirdPanel = () => {
    return getCarRow();
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return firstPanel();
      case 1:
        return secondPanel();
      case 2:
        return thirdPanel();
      default:
        return firstPanel();
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Navbar />
        {loading && <Loader />}
        <div className={classes.mainDiv}>
          <h1
            style={{
              margin: 30,
              textAlign: "center",
              fontWeight: 700,
              fontSize: "4vw",
              width: "fit-content",
              color: "#1F1D61",
            }}
          >
            Bulk Booking Form
          </h1>
          {/* <Button onClick={() => setOpen(true)}>openn</Button> */}
          <Stepper activeStep={activeStep} className={classes.stepsDiv}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div style={{ margin: 40 }}>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      style={{ background: "#1F1D61", color: "white" }}
                      color="primary"
                      onClick={saveBookingRequest}
                      className={classes.button}
                    >
                      Submit Request
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{ background: "#1F1D61", color: "white" }}
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
