import React, { useEffect, useRef, useState } from "react";
import profileholder from "../../assets/profileholder.png";
import picvector from "../../assets/picvector.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import loadingbars from "../../assets/loading_bars.svg";
import axios from "../../api";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PublishIcon from "@material-ui/icons/Publish";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Divider } from "@material-ui/core";
import jwt_decode from "jwt-decode";
import { getCities } from "../../features/Dropdown/dropdown.action";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import PopupModal from "../../components/PopupModal/PopupModal";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { setauth } from "../../features/Auth/AuthSlice";
import { setisotp } from "../../features/OTP/OTPSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/loader";
import "./Profile.css";

const Profie = ({ phone }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setuser] = useState([]);
  let token = localStorage.getItem("token");
  let decoded = jwt_decode(token);
  let userId = decoded.doc._id;
  const [loading, setloading] = useState(false);
  async function getProfile() {
    dispatch(getCities());
    axios.get(`/user?_id=${userId}`).then((res) => {
      setuser(res.data.data[0]);
      setloading(false);
      setcnic(res.data.data[0].cnic);
      set_city(res.data.data[0].city);
      setfullname(res.data.data[0].fullname);
      setcnic_back_image(res.data.data[0].cnic_back_image);
      setcnic_front_image(res.data.data[0].cnic_front_image);
      setaddress(res.data.data[0].address);
      setemail(res.data.data[0].email);
      setprofile_image(res.data.data[0].profile_image);
    });
  }
  useEffect(() => {
    setloading(true);

    getProfile();
  }, []);
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "43vw",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  //   const fullname = useRef("");
  const [mobilenumber, setmobilenumber] = useState(phone);
  const { cities } = useSelector((state) => state.dropdown);
  const [fullname, setfullname] = useState(user?.fullname);
  const [address, setaddress] = useState(user?.address);
  const [email, setemail] = useState(user?.email);
  const [cnic, setcnic] = useState(user?.cnic);
  const [city, set_city] = useState(user?.city);
  const [profile_image, setprofile_image] = useState(user?.profile_image);
  const [cnic_front_image, setcnic_front_image] = useState(
    user?.cnic_front_image
  );
  const [cnic_back_image, setcnic_back_image] = useState(user?.cnic_back_image);
  const [showPopup, setshowPopup] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [status, setstatus] = useState("");
  async function createUserBackend(data) {
    setloading(true);
    axios
      .put(`/user/${userId}`, data)
      .then((res) => {
        getProfile();
        seterrormessage(res?.data?.message);
        setstatus("Success");
        setTimeout(() => {
          setloading(false);
          seterrormessage();
        }, 1000);
      })
      .catch((error) => {
        if (error) {
          seterrormessage(error.response.data.message);
          setcnic_back_image("");
          setcnic_front_image("");
          setprofile_image("");
          setstatus("Error");
          setshowPopup(true);
          setTimeout(() => {
            setloading(false);
          }, 1000);
        }
      });
  }

  const citiesProps = {
    options: cities,
    getOptionLabel: (option) => option.city_name,
  };

  const handleChangeCities = (event, value) => {
    if (!value) return;
    set_city(value._id);
  };
  const userHandler = () => {
    const usertoadd = {
      fullname: fullname,
      cnic: cnic,
      mobilenumber: mobilenumber,
      address: address,
      email: email,
      city: city,
      cnic_front_image,
      cnic: cnic,
      cnic_back_image,
      profile_image,
    };
    createUserBackend(usertoadd);
  };

  const changeHandlerProfile = (event) => {
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    axios
      .post("/imageupload/imageUpload", formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        setprofile_image(res.data.locationArray[0].fileLocation);
        const file = document.getElementsByClassName("filehandlers");
        for (var j = 0; j < file.length; j++) file[j].value = "";
      })
      .catch((err) => {
        window.alert("Profile Picture not uploaded");
      });
  };
  return (
    <div className="profile">
      <NavbarProfile user={user} />
      {showPopup ? (
        <PopupModal
          message={errormessage}
          status={status}
          setshowPopup={setshowPopup}
        />
      ) : null}
      {loading ? <Loader /> : null}
      {!loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="profileform">
            {/* <h3>Complete Your Profile</h3>
            <p>
              Please complete your profile for signing up to Karblock to rent
              your first vehicle.
            </p> */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                userHandler();
              }}
            >
              <Grid container>
                <Grid item sm={12} md={6} lg={6} className="formgroupprofile">
                  <div className="profilepic">
                    <img
                      src={
                        profile_image
                          ? profile_image?.replace(
                              "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",

                              "https://d1w0w4ie5qtn2n.cloudfront.net/"
                            )
                          : profileholder
                      }
                      alt="Profile"
                    />
                    <div>
                      <input
                        id="file-uploadprofile"
                        className="filehandlers"
                        type="file"
                        style={{ display: "none" }}
                        onChange={changeHandlerProfile}
                      />

                      <label
                        htmlFor="file-uploadprofile"
                        style={{
                          background: "transparent",
                          cursor: "pointer",
                          marginTop: 5,
                        }}
                      >
                        <PublishIcon className="upload-icon" /> Edit Photo
                      </label>
                    </div>
                  </div>
                  <label htmlFor="name">Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={fullname}
                    disabled
                    required
                  />
                  <label htmlFor="city">City</label>
                  <Grid item xs={6} sm={4} md={2} className={classes.paper}>
                    <Autocomplete
                      onChange={handleChangeCities}
                      {...citiesProps}
                      defaultValue={city == undefined ? user?.city : city}
                      //   value={city?.city_name}
                      popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "35vw" }}
                          className="bookingrequest__textfield"
                          placeholder="-- select city --"
                          variant={"outlined"}
                        />
                      )}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      className="hr-searchbar"
                    />
                  </Grid>
                  <label htmlFor="Address">Address </label>
                  <input
                    type="text"
                    name="Address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                  />
                  <label htmlFor="email">Email </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={6}
                  className="formgroupprofile secondcolumn-formgroupprofile"
                >
                  <label htmlFor="Cnic">CNIC</label>
                  <input
                    type="text"
                    name="Cnic"
                    value={cnic}
                    disabled
                    required
                    maxlength="13"
                  />
                  <label htmlFor="cnicpic">CNIC Pictures</label>
                  <div className="cnicpichholder-edit">
                    <div className="cnic-edit">
                      <img
                        src={cnic_front_image?.replace(
                          "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",

                          "https://d1w0w4ie5qtn2n.cloudfront.net/"
                        )}
                      />
                    </div>
                    <div className="cnic-edit">
                      <img
                        src={cnic_front_image?.replace(
                          "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",

                          "https://d1w0w4ie5qtn2n.cloudfront.net/"
                        )}
                      />
                    </div>
                  </div>

                  <button className="continue" id="continue" type="submit">
                    Update
                  </button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Profie;
