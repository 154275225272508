import React, { useEffect, useState } from "react";
import LandingPage from "./view/LandingPage/LandingPage";
import Bookacar from "./view/Bookacar/Bookacar";
import SignUp from "./view/SignUp/SignUp";
import LogIn from "./view/LogIn/LogIn";
import About from "./view/About/About";
import Payment from "./view/Payment";
import PaymentConfirmation from "./view/Confirmation";
import News from "./view/News/News";
import Brands from "./view/Brands/Brands";
import Faq from "./view/FAQS/faq";
import Blog from "./view/Blog/Blog";
import Conatct from "./view/Contact/Conatct";
import Profie from "./view/Profile/Profie";
import ProfileEdit from "./view/Profile/ProfileEdit";
import RequestBooking from "./view/RequestBooking/RequestBooking";
import PrivacyPolicy from "./view/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./view/TermsConditions/Termsandconditions";
import ConfirmBooking from "./view/ConfirmBookng/ConfirmBooking";
import Bookings from "./view/Bookings/Bookings";
import BookingRequest from "./view/BookingRequest/BookingRequest";
import Notifications from "./view/Notifications/Notifications";
import SearchCar from "./view/SearchCar/SearchCar";
import TermsConditionsApp from "./view/TermsConditions/termcondition-app";
import { setauth } from "./features/Auth/AuthSlice";
import { setisotp } from "./features/OTP/OTPSlice";
import { firebase, auth } from "./firebaseConfig";
import "./App.css";

import {
  HashRouter as Router,
  Switch,
  Route,
  useHistory, useLocation
} from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import SpecializedPrivateRote from "./SpecializedPrivateRote";
import { useDispatch } from "react-redux";
import axios from "./api";
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const history = useHistory();
  const [phone, setphone] = useState("");
  const [loading, setloading] = useState(false);
  const [otploading, setotploading] = useState(false);
  const [showbanner, setshowbanner] = useState(false);
  const [viewOtpForm, setViewOtpForm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          this.onSignInSubmit();
        },
        defaultCountry: "PK",
      }
    );
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!localStorage.getItem("cookies_accepted")) setshowbanner(true);
    }, 1000);
  }, []);
  const accept = () => {
    localStorage.setItem("cookies_accepted", true);
    setshowbanner(false);
  };
  const loginSubmit = (val) => {
    setloading(true)
    setphone(val);
    let phone_number = "+92" + val;
    const appVerifier = window.recaptchaVerifier;
    auth
      .signInWithPhoneNumber(phone_number, appVerifier)
      .then((confirmationResult) => {
        setViewOtpForm(true);
        window.confirmationResult = confirmationResult;
        setloading(false)
      })
      .catch((error) => {
        setViewOtpForm(false);
        setloading(false)
        alert(error.message);
      });
  };

  const otpSubmit = (e) => {
    e.preventDefault();
    setotploading(true)
    let phone_number = "+92" + phone;
    let opt_number = e.target.otp_value.value;
    window.confirmationResult
      .confirm(opt_number)
      .then((confirmationResult) => {
        setotploading(false)
        setViewOtpForm(false);
        dispatch(setisotp(true));
        history.push("/profile");
      })
      .catch((error) => {
        setotploading(false)
        setViewOtpForm(false);
        dispatch(setisotp(false));
        dispatch(setauth(false));
        alert(error.message);
      });
  };
  const otpSubmitLogin = (e) => {
    setotploading(true)
    e.preventDefault();
    let opt_number = e.target.otp_value.value;
    window.confirmationResult
      .confirm(opt_number)
      .then((confirmationResult) => {
        let phonenmber = confirmationResult.user.phoneNumber;
        phonenmber = phonenmber.replace("+92", "");
        axios
          .post(`/user/renterlogin`, {
            mobilenumber: phonenmber,
          })
          .then((res) => {
            setotploading(false)
            if (res.data.message == "Logged in successfully") {
              localStorage.setItem("token", res.data.token);
              sessionStorage.setItem("token", res.data.token);
              setTimeout(() => {
                setViewOtpForm(false);
                dispatch(setisotp(true));
                dispatch(setauth(true));
                let getRedirect = sessionStorage.getItem("redirect_to");
                if (getRedirect == "requestbooking") {
                  let id = sessionStorage.getItem("car_id");
                  let bookingData = sessionStorage.getItem("bookingData");
                  history.push({ pathname: `/requestbooking/${id}`, state: bookingData });
                } else {
                  history.push("/searchcar");
                }
              }, 1000);
            } else if (
              res.data.message == "User Not Registered" ||
              res.data.message == "Your are not registered as renter"
            ) {
              setTimeout(() => {
                setViewOtpForm(false);
                dispatch(setisotp(false));
                dispatch(setauth(false));
                window.alert(res.data.message);
                history.push("/signup");
              }, 1000);
            }
          })
          .catch((err) => {
            setotploading(false)
            setViewOtpForm(false);
            dispatch(setisotp(false));
            dispatch(setauth(false));
            window.alert(err.response.data.message);
          });
      })
      .catch((error) => {
        setotploading(false)
        setViewOtpForm(false);
        dispatch(setisotp(false));
        dispatch(setauth(false));
        alert(error.message);
      });
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (

    <div className="App">
      <div id="recaptcha-container"></div>
      {showbanner && (
        <div className="cookie-banner">
          <p>
            This website uses cookies to improve your experience. To find out
            more, read our <a href="/#/privacy-policy">privacy policy</a>
            <button onClick={() => accept()}>Accept</button>
          </p>
        </div>
      )}
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""

      />

      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          {/* Test Payment Routes */}
          <Route exact path="/payment">
            <Payment />
          </Route>
          <Route exact path="/paymentconfirmation/status=:status">
            <PaymentConfirmation />
          </Route>
          {/* Test Payment Routes */}

          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/bookacar">
            <Bookacar />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/faqs">
            <Faq />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsConditions />
          </Route>
          <Route exact path="/terms-and-conditions-app">
            <TermsConditionsApp />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>{" "}
          <Route exact path="/notifications">
            <Notifications />
          </Route>
          <Route exact path="/news">
            <News />
          </Route>

          <Route exact path="/brands">
            <Brands />
          </Route>
          <Route exact path="/contact">
            <Conatct />
          </Route>
          <Route exact path="/bookingrequest">
            <BookingRequest />
          </Route>
          <Route exact path="/login">
            <LogIn
              phone={phone}
              otploading={otploading}
              loading={loading}
              loginSubmit={loginSubmit}
              otpSubmitLogin={otpSubmitLogin}
              viewOtpForm={viewOtpForm}
            />
          </Route>
          <Route exact path="/signup">
            <SignUp
              loading={loading}
              otploading={otploading}
              phone={phone}
              loginSubmit={loginSubmit}
              otpSubmit={otpSubmit}
              viewOtpForm={viewOtpForm}
            />
          </Route>
          <Route exact path="/profileprofileprofile">
            <Profie phone={"!2322"} />

          </Route>
          <SpecializedPrivateRote exact path="/profile">
            <Profie phone={phone} />
          </SpecializedPrivateRote>

          <Route exact path="/searchcar">
            <SearchCar />
          </Route>
          <PrivateRoute exact path="/requestbooking/:id">
            <RequestBooking />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/confirmation/:id"
            component={ConfirmBooking}
          />
          <PrivateRoute
            exact
            path="/editprofile/:id"
            component={ProfileEdit}
          />
          <PrivateRoute exact path="/bookings" component={Bookings} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
