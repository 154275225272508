import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import DoneIcon from "@material-ui/icons/Done";
import "./Process.css";
const Process = () => {
  return (
    <div className="process">
      <div className="processheadingdiv">
        <h3 className="processheading">How Karblock works</h3>
      </div>
      <div className="cardholder">
        <div className="card">
          <div className="imageholder">
            <div className="circle">
              <SearchIcon className="circle-icon" fontSize={"large"} />
            </div>
          </div>
          <p className="head">Search</p>
          <p>Drive any car you want. Just enter your choice and hit search.</p>
        </div>
        {/* ----------------------------- */}
        <div className="card">
          <div className="imageholder">
            <div className="circle">
              <DirectionsCarIcon className="circle-icon" />
            </div>
          </div>
          <p className="head">Choose</p>
          <p>
            Choose your ride from a selection of the finest chauffeur-driven
            cars.
          </p>
        </div>
        {/* ----------------------------- */}
        <div className="card">
          <div className="imageholder">
            <div className="circle">
              <DoneIcon className="circle-icon" />
            </div>
          </div>
          <p className="head">Book</p>
          <p>
            Select which car you want to take out; we will arrange everything
            else for you!
          </p>
        </div>
        {/* ----------------------------- */}
      </div>
    </div>
  );
};

export default Process;
