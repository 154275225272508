import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/LandingPage/Footer/Footer";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import Process from "../../components/LandingPage/Process/Process";
import RentACar from "../../components/LandingPage/RentACar/RentACar";
import CarsByMake from "../../components/CarsByMake/CarsByMake";
import WhyKarblock from "../../components/WhyKarblock/WhyKarblock";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { selectAuth } from "../../features/Auth/AuthSlice";
import line from "../../assets/orange_line.png";
import "./Bookacar.css";
const LandingPage = () => {
  const auth = useSelector(selectAuth);
  return (
    <div>
      {auth ? <NavbarProfile /> : <Navbar />}
      <RentACar />
      <Process />
      <div className="bookacar__browsebycar">
        <div className="bookacar__lines">
          <img src={line} width={120} />
          <img src={line} style={{ marginLeft: 80 }} width={120} />
        </div>
        <CarsByMake />
      </div>
      <WhyKarblock />
      <Footer />
    </div>
  );
};

export default LandingPage;
