

const daysOptions = [
  { value: 1, hours: 24 },
  { value: 2, hours: 48 },
  { value: 3, hours: 72 },
  { value: 4, hours: 96 },
  { value: 5, hours: 120 },
  { value: 6, hours: 144 },
  { value: 7, hours: 168 },
  { value: 8, hours: 198 },
  { value: 9, hours: 216 },
  { value: 10, hours: 240 },
  { value: 11, hours: 264 },
  { value: 12, hours: 288 },
  { value: 13, hours: 312 },
  { value: 14, hours: 336 },
  { value: 15, hours: 360 },
];
const daysProps = {
  options: daysOptions,
  getOptionLabel: (option) => `${option.value}`,
};
export default daysProps