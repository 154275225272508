import React, { useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import pakistan from "../../assets/pakistan.png";
import logo from "../../assets/images/logos/white_logo.svg";
import { useHistory } from "react-router-dom";
import cars from "../../assets/images/login_signup.svg";
import PopupModal from "../../components/PopupModal/PopupModal";
import "../SignUp/SignUp.css";
import OTPPopup from "./Otppopup";
const LogIn = ({
  loginSubmit,
  otpSubmitLogin,
  viewOtpForm,
  phone,
  otploading,
  loading,
}) => {
  const history = useHistory();
  const [phonenumber, setphonenumber] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [showPopup, setshowPopup] = useState(false);
  const [status, setstatus] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const validatephoneNumber = (value) => {
    if (value.length < 10) {
      setErrorMessage("Enter a valid Phone Number!");
      setstatus("error");
      setshowPopup(true);
      return false;
    } else if (/^([3][0-9]{9,})$/.test(phonenumber)) {
      return true;
    } else {
      setErrorMessage("Enter a valid Phone Number!");
      setstatus("error");
      setshowPopup(true);
      return false;
    }
  };

  return (
    <div>
      <Navbar />
      {errormessage ? (
        <div className="alert alert-primary" role="alert">
          {errormessage}
        </div>
      ) : null}
      {showPopup ? (
        <PopupModal
          message={ErrorMessage}
          status={status}
          setshowPopup={setshowPopup}
        />
      ) : null}
      {/* 
      {loading ? (
        <div className="loading-div">
          <img src={loadingbars} alt="Loading..." />
        </div>
      ) : null} */}
      <div className="signupcontainer">
        <div className="col1signup">
          <div className="signupform">
            <h2>Login</h2>
            <p>Welcome back. Let’s get logged into your Karblock account.</p>
            <div className="inputphone">
              <img src={pakistan} alt="pakistan flag" />
              <div className="hr"></div>
              <input
                className="phonenumberinput"
                placeholder="3001234567"
                maxLength="10"
                onChange={(e) => {
                  setphonenumber(e.target.value);
                  seterrormessage("");
                }}
              />
            </div>

            <button
              className="createaccountbutton"
              style={{ color: "#fff" }}
              onClick={() => {
                if (validatephoneNumber(phonenumber)) {
                  loginSubmit(phonenumber);
                }
              }}
            >
              {loading ? "Loading..." : "Continue"}
            </button>

            <p className="check" id="check">
              Don't Have An Account?
              <span
                onClick={() => history.push("/signup")}
                style={{ color: "#1F1D61" }}
              >
                {" "}
                Sign Up
              </span>
            </p>
          </div>
        </div>
        <div className="col2signup container">
          <img src={cars} alt="" />
          <div className="centered">
            <>
              <img src={logo} width={"130px"} height="130px" />
              <p style={{ color: "red" }}>
                Login into your Karblock account as Renter
                <br />
                and avail our services.
              </p>
            </>
          </div>
        </div>
      </div>
      <OTPPopup
        otpSubmitLogin={otpSubmitLogin}
        open={viewOtpForm}
        phone={phone}
        otploading={otploading}
      />
    </div>
  );
};

export default LogIn;
