import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
const Maker = () => (
  <span
    style={{
      backgroundImage:
        "url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg)",
      position: "absolute",
      width: "38px",
      height: "37px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    }}
  ></span>
);

const Map = () => {
  const [center, setCenter] = useState([33.6676206, 73.0743052]);
  useEffect(() => {
    setCenter([33.6676206, 73.0743052]);
  }, []);
  return (
    <div style={{ height: "40vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={process.env.REACT_APP_MAP_KEY}
        center={center}
        defaultZoom={14}
        zoom={14}
      >
        <Maker lat={33.6676206} lng={73.0743052} />
      </GoogleMapReact>
    </div>
  );
};
export default Map;
