import React, { useEffect, useState } from "react";
import "../LandingPage/Navbar/Navbar";
import logo from "../../assets/images/logos/black_logo.svg";
import profile from "../../assets/user.png";
import { useHistory } from "react-router-dom";
import { setauth } from "../../features/Auth/AuthSlice";
import { useDispatch } from "react-redux";
import { setisotp } from "../../features/OTP/OTPSlice";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { Badge, Menu, MenuItem } from "@material-ui/core";
import jwt_decode from "jwt-decode";
import axios from "../../api";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const NavbarProfile = ({ notifyIcon }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const _open = Boolean(anchorEl);
  const handleClick = (event) => {
    history.push("/notifications");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [toggle, settoggle] = useState(false);
  const [notify, setnotify] = useState(notifyIcon);
  const [photoProfile, setphotoProfile] = useState("");
  const [nameProfile, setnameProfile] = useState("");
  const [notifications, setnotifications] = useState([]);
  const [count, setcount] = useState(0);
  const [bookingcount, setbookingcount] = useState(0);

  let token = localStorage.getItem("token");
  let decoded = jwt_decode(token);
  let userId = decoded.doc._id;
  async function getNotifications() {
    axios.get(`/notification/${userId}`).then((res) => {
      setnotifications(res.data.data);
      setcount(res.data.count[0].count_notread);
    });
  }

  async function getBookingCount() {
    axios.get(`/booking/currentBooking/${userId}`).then((res) => {
      setbookingcount(res.data.data.length);
    });
  }
  useEffect(() => {
    async function getProfile() {
      axios.get(`/user?_id=${userId}`).then((res) => {
        setphotoProfile(res?.data?.data[0]?.profile_image);
        setnameProfile(res?.data?.data[0]?.fullname);
      });
    }
    getProfile();
    getNotifications();
    getBookingCount();
  }, []);
  const gotoEditProfile = () => {
    history.push(`/editprofile/${userId}`);
  };
  return (
    <>
      <div className="header">
        <div
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={logo} className="logo" />
        </div>
        <nav className="nav">
          <ul className="nav__links">
            <li>
              <Link
                to="/"
                className={history.location.pathname == "/" && "active"}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={history.location.pathname == "/about" && "active"}
              >
                {" "}
                About
              </Link>
            </li>
            <li>
              <Link
                to="/bookings"
                className={history.location.pathname == "/bookings" && "active"}
              >
                {" "}
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={bookingcount > 0 ? false : true}
                >
                  Bookings
                </Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={history.location.pathname == "/contact" && "active"}
              >
                {" "}
                Contact
              </Link>
            </li>
            <li>
              <Link
                to="/notifications"
                className={
                  history.location.pathname == "/notifications" && "active"
                }
              >
                {" "}
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={count > 0 ? false : true}
                >
                  <NotificationsActiveIcon
                    style={{ color: "#1F1D61" }}
                    onClick={handleClick}
                  />
                </Badge>
              </Link>
            </li>
          </ul>
          <div
            className="rightnavButtons"
            style={{ width: "40px", height: "40px" }}
          >
            <img
              style={{
                borderRadius: "50%",
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
              src={photoProfile ? photoProfile?.replace(
                "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",
                "https://d1w0w4ie5qtn2n.cloudfront.net/"
              ) : profile}
              onClick={(e) => {
                handleClick1(e);
                if (window.innerWidth <= 952) {
                  settoggle(!toggle);
                }
              }}
            />
            {toggle == false && (
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  handleClose();
                }}
              >
                <StyledMenuItem onClick={() => gotoEditProfile()}>
                  <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={nameProfile && nameProfile} />
                </StyledMenuItem>
                <StyledMenuItem>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Logout"
                    onClick={() => {
                      localStorage.setItem("token", "");
                      sessionStorage.setItem("token", "");
                      dispatch(setauth(false));
                      dispatch(setisotp(false));
                      history.push("/");
                    }}
                  />
                </StyledMenuItem>
              </StyledMenu>
            )}
          </div>
        </nav>
      </div>
      {toggle ? (
        <section>
<nav className="nav__mob" style={{ width: toggle ? "100%" : "0%" }}>
                <ul className="nav__links nav__links__flex">
              <li style={{ display: "block", padding: "10px" }}>
                <a
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Home
                </a>
              </li>
              <li style={{ display: "block", padding: "10px" }}>
                <a
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  About
                </a>
              </li>

              <li
                style={{ display: "block", padding: "10px" }}
                onClick={() => gotoEditProfile()}
              >
                <a>Profile</a>
              </li>
              <li style={{ display: "block", padding: "10px" }}>
                <a
                  onClick={() => {
                    history.push("/bookings");
                  }}
                >
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={bookingcount > 0 ? false : true}
                  >
                    Bookings
                  </Badge>
                </a>
              </li>
              <li style={{ display: "block", padding: "10px" }}>
                <a
                  onClick={() => {
                    history.push("/notifications");
                  }}
                >
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={count > 0 ? false : true}
                  >
                    Notifications
                  </Badge>
                </a>
              </li>

              <li style={{ display: "block", padding: "10px" }}>
                <a
                  onClick={() => {
                    history.push("/contact");
                  }}
                >
                  Contact
                </a>
              </li>
              <li style={{ display: "block", padding: "10px" }}>
                <a
                  className="cta1"
                  // style={{ marginLeft: "1vw" }}
                  onClick={() => {
                    localStorage.setItem("token", "");
                    sessionStorage.setItem("token", "");
                    dispatch(setauth(false));
                    dispatch(setisotp(false));
                    history.push("/");
                  }}
                >
                  <button>Logout</button>
                </a>
              </li>
              <div className="rightnavButtons" style={{ padding: "10px" }}>
                <a
                  className="cta1"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <button>Login</button>
                </a>
                <a
                  className="cta2"
                  onClick={() => {
                    history.push("/signup");
                  }}
                >
                  <button>Sign Up</button>
                </a>
              </div>
            </ul>
          </nav>
        </section>
      ) : null}
    </>
  );
};

export default NavbarProfile;
