import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingcars: false,
  saved: false,
  cars: [],
  car:[],
  carousel_cars: [],
};
const carSlice = createSlice({
  name: "carService",
  initialState,
  reducers: {
    getLoadingLists: (state) => {
      state.loadingcars = true;
      state.saved = false;
    },
    getCarSuccess: (state, { payload }) => {
      state.loadingcars = false;
      state.car = payload;
    },
    getCarouselCarListsSuccess: (state, { payload }) => {
      state.loadingcars = false;
      state.carousel_cars = payload;
    },
    getCarListsSuccess: (state, { payload }) => {
      state.loadingcars = false;
      state.cars = payload;
    },
    getCarListsFailure: (state) => {
      state.loadingcars = false;
      state.cars = [];
      state.carousel_cars = [];
    },
  },
});

const carReducer = carSlice.reducer;
export default carReducer;
export const {getCarSuccess,
  getLoadingLists,
  getCarListsFailure,
  getCarListsSuccess,
  getCarouselCarListsSuccess,
} = carSlice.actions;
