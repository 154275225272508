import React, { useEffect, useState } from "react";
import "./Review.css";
import { Paper, Grid, Box, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AliceCarousel from "react-alice-carousel";
import data from "./Data";
import "react-alice-carousel/lib/alice-carousel.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 20,
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}));

const Review = () => {
  const classes = useStyles();
  const [responsive, setResponsive] = useState({
    0: { items: 1 },
    650: { items: 2 },
    1024: { items: 3 },
  });
  useEffect(() => {
    setResponsive({
      0: { items: 1 },
      650: { items: 2 },
      1024: { items: 3 },
    });
  }, []);
  const getCarouselItem = () => {
    return data?.map((res) => {
      return <Item item={res} />;
    });
  };
  const Item = ({ item }) => {
    return (
      <Grid item style={{ margin: 20 }}>
        <Paper className={classes.paper}>
          <div className="main-row">
            <div className="flex-col1">
              <Avatar
                alt={item.name}
                src={item?.img?.replace(
                  "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",
                  "https://d1w0w4ie5qtn2n.cloudfront.net/"
                )}
                className={classes.large}
              />
              <div className="review-detail">
                <span className="review-name">{item.name}</span>
                <span className="text-black">{item.role}</span>
              </div>
            </div>
            <div className="flex-col2">
              <p>
                <Rating value={item.rating} />
              </p>
              <p className="text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non
                maecenas nibh vitae et eu, amet.
              </p>
              <p className="text-black">{item.date}</p>
            </div>
          </div>
        </Paper>
      </Grid>
    );
  };
  return (
    <div style={{ display: "flex", width: "100vw"}}>
      <div className="review">
        <h3>Testimonials</h3>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={4}
            className="grid-carousel review-carousel"
            justifyContent="center"
            alignItems="center"
          >
            <AliceCarousel
              infinite
              mouseTracking
              items={getCarouselItem()}
              autoPlayInterval={1000}
              disableDotsControls
              animationDuration={1000}
              responsive={responsive}
              renderPrevButton={() => {
                return <ArrowBackIosIcon className="orange-arrow" />;
              }}
              renderNextButton={() => {
                return <ArrowForwardIosIcon className="orange-arrow" />;
              }}
            />
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Review;
