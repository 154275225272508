import React, { useEffect, useState } from "react";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import "./Bookings.css";
import BookingCard from "../../components/BookingCard/BookingCard";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import Loader from "../../components/Loader/loader";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookings } from "../../features/bookings/bookings.action";
const Bookings = () => {
  let token = localStorage.getItem("token");
  let decoded = jwt_decode(token);
  let userId = decoded.doc._id;
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
      borderRadius: "10px",
      margin: "auto",
      width: "300px",
      height:"410px"
    },
  }));
  const classes = useStyles();
  const { bookings, loadingbookings } = useSelector((state) => state.bookings);
  useEffect(() => {
    dispatch(getAllBookings(userId));
  }, []);
  return (
    <div>
      <NavbarProfile />

      {loadingbookings ? (
        <Loader />
      ) : (
        <div className="bookingwrapper">
          <div className="bookingdetails">
            <h2>All Bookings</h2>
            {/* {bookings?.length == 0 ? <h2>No bookings found.</h2> : ""} */}
            <Grid
              container
              spacing={8}
              justifyContent="center"
              alignItems="center"
            >
              {bookings?.map((book) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Paper className={classes.paper}>
                      <BookingCard data={book} key={book._id} />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bookings;
