import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingbookings: false,
  saved: false,
  addbookingloading:false,
  bookings: [],
  booking:[],
};
const bookingSlice = createSlice({
  name: "bookingService",
  initialState,
  reducers: {
    getLoadingLists: (state) => {
      state.loadingbookings = true;
      state.saved = false;
    },
    getBookingSuccess: (state, { payload }) => {
      state.loadingbookings = false;
      state.booking = payload;
    },
    getBookingsSuccess: (state, { payload }) => {
      state.loadingbookings = false;
      state.bookings = payload;
    },
    getBookingsFailure: (state) => {
      state.loadingbookings = false;
      state.bookings = [];
    },
    getAddBookingLoading: (state) => {
      state.addbookingloading = true;
    },
    getAddBookingLoadingFalse: (state) => {
      state.addbookingloading = false;
    },
  },
});

const bookingReducer = bookingSlice.reducer;
export default bookingReducer;
export const {
  getBookingsFailure,
  getBookingsSuccess,
  getBookingSuccess,
  getLoadingLists,
  getAddBookingLoading,getAddBookingLoadingFalse
} = bookingSlice.actions;
