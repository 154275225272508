import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import carReducer from "../features/cars/cars.reducer";
import authReducer from "../features/Auth/AuthSlice";
import otpReducer from "../features/OTP/OTPSlice";
import dropdownReducer from "../features/Dropdown/dropdown.reducer";
import reasonReducer from "../features/reason/reason.reducer";
import bokingReducer from "../features/BookingInfo/BookingInfoSlice";
import bookingInfo from "../features/bookings/bookings.reducer";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducers = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  otp: otpReducer,
  bookings:bookingInfo,
  booking: bokingReducer,
  cars: carReducer,
  reason: reasonReducer,
  dropdown: dropdownReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
