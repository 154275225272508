import React from "react";
import SearchBar from "../../SearchBar/SearchBar";
import "./RentACar.css";

const RentACar = () => {
  return (
    <div className="mainrentacar">
        <div className="searchcovercard">
          <SearchBar />
        </div>
    </div>
  );
};

export default RentACar;
