import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDCna20bfowRWUKMGxmZmO7yYPG8wPwHQY",
  authDomain: "karblock-3f99f.firebaseapp.com",
  projectId: "karblock-3f99f",
  storageBucket: "karblock-3f99f.appspot.com",
  messagingSenderId: "664935989773",
  appId: "1:664935989773:web:4a35421bebd869ee080b44",
  measurementId: "G-XJXX7PC32L"
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export { auth, firebase };
