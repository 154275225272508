import React, { useState } from "react";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { cancelBooking } from "../../features/bookings/bookings.action";
import { reasonProps } from "../../utills/data"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./style.css"
// import "./FilterButtons.css"
const BookingDetailsPopup = ({ open, setOpenCB,id }) => {
    const useStyles = makeStyles((theme) => ({
        features: { cursor: "pointer", fontWeight: "normal" },
        divider: { marginTop: 5, marginBottom: 5 },
        filterLabel: { marginLeft: 20, color: "black" },
        heading: { fontWeight: 300, fontSize: 20 },
    }));
    const classes = useStyles();
    const [reason, setreason] = useState("");
    const dispatch = useDispatch()
    const [coment, setcomment] = useState("");

    const descriptionElementRef = React.useRef(null);
    const handleChangeTravelingCity = (event,value) => {
        if (!value) return;
        setreason(value.title);
    };
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const cancelbooking = (id) => {
        let body = {
            cancelled_by: "renter",
            cancellation_reason: reason,
            cancellation_comment: coment,
        };
        dispatch(cancelBooking(body,id))
    }
    return (
        <Dialog
            open={open}
            className={classes.modal}
            onClose={() => setOpenCB(false)}
            scroll={"paper"}

            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>Cancel Booking</DialogTitle>
            <DialogContent dividers={'paper'}>
                <DialogContentText
                    style={{ display: "flex", alignItems: "center", alignContent: "center", width: 400 }}
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}>
                    <Grid container>
                        <Grid item sm={12} md={12}>
                            <Autocomplete
                                onChange={handleChangeTravelingCity}
                                style={{ width: "400px" }}
                                {...reasonProps}
                                popupIcon={<ExpandMore style={{ color: "#F05123" }} />}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Cancellation Reason"
                                        InputLabelProps={{ shrink: true }}
                                        className="bookingrequest__textfield"
                                        placeholder="-- select reason --"
                                        variant={"outlined"}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label="Comment"
                                multiline
                                style={{ width: "400px", marginTop: 20 }}
                                variant="outlined"
                                rows={4}
                                InputLabelProps={{ shrink: true }}
                                value={coment}
                                onChange={(e) => setcomment(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                <button className="cb-button" onClick={() => cancelbooking(id)}>Okay</button>
            </DialogActions>
        </Dialog>

    );
};

export default BookingDetailsPopup;
