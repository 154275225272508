import React from "react";
import "./BulkBookingCars.css";
import bulkbooking from "../../../assets/bulkbooking.png";
import { useHistory } from "react-router-dom";

const KarblockRenter = () => {
    const history = useHistory();

    return (
        <div style={{ display: "flex", width: "100vw",padding:"0px 0px",justifyContent:"center",alignContent:"center"}}>
            <div className="bulkBookingCars">
                <div className="rentercard">
                    <h3><span className="black-heading">Bulk Booking</span><span className="orange-heading"> for events, weddings & ceremonies</span></h3>
                    <p>Do you want to rent more than one car for your corporate event/wedding/life event/journey? If so, press down the button below.</p>
                    <button className="blue-button" onClick={()=>history.push("/bookingrequest")}>Bulk Booking</button>
                </div>
                <div className="renter">
                    <img src={bulkbooking} alt="bulkbooking" />
                </div>
            </div>
        </div>
    );
};

export default KarblockRenter;
