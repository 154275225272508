import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
const Carousel = ({ car }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const [responsive, setResponsive] = useState({
        0: { items: 1 },
        530: { items: 1 },
        700: { items: 1 },
        1024: { items: 1 },
    });
    useEffect(() => {
        setResponsive({
            0: { items: 1 },
            530: { items: 1 },
            700: { items: 1 },
            1024: { items: 1 },
        });
    }, []);
    const items = () => {
        return (
            car &&
            car[0]?.car_images?.map((res) => {
                return (
                    <div className="item cardetail_carouselimg" data-value="1">
                        <img src={res.replace(
                  "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",
                  "https://d1w0w4ie5qtn2n.cloudfront.net/"
                )} />
                    </div>
                );
            })
        );
    };
    const slidePrev = () => setActiveIndex(activeIndex - 1);
    const slideNext = () => setActiveIndex(activeIndex + 1);
    const syncActiveIndex = ({ item }) => setActiveIndex(item);


    return (
        <div className="requestbox-maingrid">
        <div className="requestbox-imgcarousel">
            <AliceCarousel
                mouseTracking
                disableDotsControls
                animationType="fadeout"
                animationDuration={800}
                disableButtonsControls
                items={items()}
                activeIndex={activeIndex}
                responsive={responsive}
                onSlideChanged={syncActiveIndex}
            />
            <div className="b-refs-buttons__">
                <ArrowBackIosIcon onClick={slidePrev} className="orange-arrow" />
                <ArrowForwardIosIcon onClick={slideNext} className="orange-arrow" />
            </div>
        </div>
        </div>
    );
};

export default Carousel;
