import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { selectAuth } from "../../features/Auth/AuthSlice";
import { Paper, Grid } from "@material-ui/core";
import SearchBar from "../../components/SearchBar/SearchBar";
import { getBrands } from "../../features/Dropdown/dropdown.action";
import Loader from "../../components/Loader/loader";
import { searchCarbybrand } from "../../features/cars/cars.action";
import jwt_decode from "jwt-decode";
import axios from "../../api";
import { useHistory } from "react-router-dom";
import "../../view/SearchCar/CarsGrid.css";
import "./Brands.css";
const Brands = () => {
  const [user, setuser] = useState("");
  const history = useHistory();

  async function getProfile() {
    let token = localStorage.getItem("token");
    let decoded = jwt_decode(token);
    let userId = decoded.doc._id;
    axios.get(`/user?_id=${userId}`).then((res) => {
      setuser(res?.data?.data[0]);
    });
  }

  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const { loadingcars } = useSelector((state) => state.cars);
  const { brands } = useSelector((state) => state.dropdown);
  useEffect(() => {
    dispatch(getBrands());
    if (auth) {
      getProfile();
    }
  }, []);
  const search = (id) => {
    dispatch(searchCarbybrand(id, user?.city?._id));
    history.push("/searchcar");
  };
  return (
    <div>
      {auth ? <NavbarProfile /> : <Navbar />}
      {loadingcars && <Loader />}
      <div className={loadingcars ? "decreasopacity" : ""}>
        <div className="div-searchbar">
          <SearchBar />
        </div>

        <div className="div-cars">
          <Grid
            container
            spacing={2}
            className="grid-carousel carmake-carousel"
            justifyContent="center"
            alignItems="center"
          >
            {brands?.map((item, i) => {
              return (
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <Paper
                    className="paper-item"
                    key={i}
                    onClick={() => search(item._id)}
                  >
                    <div
                      className="paper-brndimg-container"
                      onClick={() => search(item._id)}
                    >
                      <img
                        src={item?.image?.replace(
                          "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/",
                          "https://d1w0w4ie5qtn2n.cloudfront.net/"
                        )}
                        onClick={() => search(item._id)}
                      />
                    </div>
                    <p>{item.brand_name}</p>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Brands;
