import React, { useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import blogcover from "../../assets/blogcover.png";
import "./Blog.css";
import Footer from "../../components/LandingPage/Footer/Footer";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/Auth/AuthSlice";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import loadingbars from "../../assets/loading_bars.svg";
import { useEffect } from "react";
const Blog = () => {
  const auth = useSelector(selectAuth);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function loadinghandler() {
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    setloading(true);
    loadinghandler();
  }, []);
  return (
    <div className="blog">
      {auth ? <NavbarProfile /> : <Navbar />}
      {loading ? (
        <div className="loading-div">
          <img src={loadingbars} alt="Loading..." />
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="blogcover">
            <img src={blogcover} alt="blogcover" />
            <div className="blogtext">
              <h2>Finibus Bonorum et Malorum</h2>
              <div>
                <p>Written by John doe</p>
                <p className="blogdate">Monday May 20</p>
              </div>
            </div>
            <div className="blogparagraphholder">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh
                tincidunt odio aenean risus fusce. Pretium ultrices augue
                ullamcorper sit sagittis. Amet fusce sagittis, condimentum in
                diam dis. Odio eget nibh purus, feugiat. Nunc aliquet ac
                porttitor mattis mauris tortor dignissim. Malesuada posuere
                lectus eu sit eu, pulvinar. Quisque rhoncus massa turpis non
                nibh. Cursus ac viverra turpis risus amet nunc. Neque vel massa
                sed integer vulputate nibh diam molestie tortor. Morbi risus
                mattis elit facilisi. Aliquet pharetra ultrices hendrerit ante
                nulla lorem nisi.{" "}
              </p>
              <p>
                Felis neque, consectetur egestas sed nam. Venenatis, cras
                aliquet scelerisque augue tellus nullam sollicitudin vitae. Cras
                magna habitant aliquet hendrerit rhoncus quis sodales.
                Vestibulum risus a nunc rhoncus. Id sed habitasse dictum diam
                tellus eget eget lacus euismod. Posuere commodo sapien nunc,
                ultrices arcu lacus mauris. Adipiscing ut ut vel elit mattis
                bibendum ultricies sagittis magna. Vulputate risus pellentesque
                cursus at. Egestas velit sed cursus massa nunc sem ac egestas
                tellus. Tincidunt a leo quam pharetra, sed massa nulla.
              </p>{" "}
              <p>
                Nunc tortor laoreet aenean interdum integer ultrices nulla.
                Nisl, urna mi dolor porttitor quam nulla orci auctor cursus. Sit
                eu et quis neque libero, neque, laoreet in turpis. Pellentesque
                sed cursus placerat rhoncus vitae sit. Praesent libero, et
                aenean eget. Augue ultricies pellentesque ipsum, feugiat
                ultrices. Pharetra, eu orci diam scelerisque ac arcu enim sed.
                Dictum dignissim dui velit, vitae leo lorem tristique massa.
                Quisque nulla egestas nisi libero. Ut lectus et egestas nulla
                sit arcu etiam nunc turpis. Laoreet porttitor tortor viverra
                tellus diam auctor a elit.{" "}
              </p>{" "}
              <p>
                Nunc urna venenatis, donec varius gravida varius tempor, id
                diam. Facilisi purus elit massa, venenatis dignissim duis. Vel
                duis ultrices purus, bibendum commodo eleifend metus eleifend.
                Consequat imperdiet vestibulum arcu purus mattis sem sit.
                Malesuada duis ipsum est quisque. Pulvinar habitant suspendisse
                aliquam ipsum hac nibh augue habitant iaculis. Mattis amet et
                quisque nulla morbi eleifend ut. Vel lectus integer quisque
                ultricies non, nec, in orci. Rhoncus urna, eu aenean sem
                pellentesque leo. Duis dignissim fermentum sit mauris lacus.
                Congue eu cursus hendrerit pretium dui. Non, nullam lacus
                porttitor nunc, consequat. Lacus, maecenas cum facilisi felis
                enim, amet faucibus cursus morbi. Id euismod nibh enim quis at
                purus. Egestas etiam laoreet vitae facilisis morbi suscipit.
                Velit id aenean eget interdum accumsan. Suspendisse a, ipsum et
                lorem ullamcorper nulla.
              </p>
              <p>
                Viverra orci eget arcu commodo eget. Ante amet, egestas
                imperdiet nibh purus, arcu urna, proin. Arcu vitae odio aliquam
                quis nec et, tincidunt sollicitudin. Urna dictumst facilisi sed
                tortor sed ornare luctus tellus non. In et augue libero
                vestibulum. Est sit maecenas blandit diam augue. In facilisi sit
                euismod interdum nullam id pellentesque enim. Quis convallis
                tellus risus eget natoque lorem ut. Adipiscing turpis enim
                aliquet sed amet, nec at mauris. At sociis nisi tortor, lectus
                in accumsan.
              </p>
            </div>
            <div className="heading2">
              <h2>Heading 2</h2>
              <div className="blogparagraphholder">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh
                  tincidunt odio aenean risus fusce. Pretium ultrices augue
                  ullamcorper sit sagittis. Amet fusce sagittis, condimentum in
                  diam dis. Odio eget nibh purus, feugiat. Nunc aliquet ac
                  porttitor mattis mauris tortor dignissim. Malesuada posuere
                  lectus eu sit eu, pulvinar. Quisque rhoncus massa turpis non
                  nibh. Cursus ac viverra turpis risus amet nunc. Neque vel
                  massa sed integer vulputate nibh diam molestie tortor. Morbi
                  risus mattis elit facilisi. Aliquet pharetra ultrices
                  hendrerit ante nulla lorem nisi.{" "}
                </p>
                <p>
                  Felis neque, consectetur egestas sed nam. Venenatis, cras
                  aliquet scelerisque augue tellus nullam sollicitudin vitae.
                  Cras magna habitant aliquet hendrerit rhoncus quis sodales.
                  Vestibulum risus a nunc rhoncus. Id sed habitasse dictum diam
                  tellus eget eget lacus euismod. Posuere commodo sapien nunc,
                  ultrices arcu lacus mauris. Adipiscing ut ut vel elit mattis
                  bibendum ultricies sagittis magna. Vulputate risus
                  pellentesque cursus at. Egestas velit sed cursus massa nunc
                  sem ac egestas tellus. Tincidunt a leo quam pharetra, sed
                  massa nulla.
                </p>{" "}
                <p>
                  Nunc tortor laoreet aenean interdum integer ultrices nulla.
                  Nisl, urna mi dolor porttitor quam nulla orci auctor cursus.
                  Sit eu et quis neque libero, neque, laoreet in turpis.
                  Pellentesque sed cursus placerat rhoncus vitae sit. Praesent
                  libero, et aenean eget. Augue ultricies pellentesque ipsum,
                  feugiat ultrices. Pharetra, eu orci diam scelerisque ac arcu
                  enim sed. Dictum dignissim dui velit, vitae leo lorem
                  tristique massa. Quisque nulla egestas nisi libero. Ut lectus
                  et egestas nulla sit arcu etiam nunc turpis. Laoreet porttitor
                  tortor viverra tellus diam auctor a elit.{" "}
                </p>{" "}
              </div>
            </div>
            {/*  */}
            <div className="heading3">
              <h2>Heading 3</h2>
              <div className="blogparagraphholder">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh
                  tincidunt odio aenean risus fusce. Pretium ultrices augue
                  ullamcorper sit sagittis. Amet fusce sagittis, condimentum in
                  diam dis. Odio eget nibh purus, feugiat. Nunc aliquet ac
                  porttitor mattis mauris tortor dignissim. Malesuada posuere
                  lectus eu sit eu, pulvinar. Quisque rhoncus massa turpis non
                  nibh. Cursus ac viverra turpis risus amet nunc. Neque vel
                  massa sed integer vulputate nibh diam molestie tortor. Morbi
                  risus mattis elit facilisi. Aliquet pharetra ultrices
                  hendrerit ante nulla lorem nisi.{" "}
                </p>
                <p>
                  Felis neque, consectetur egestas sed nam. Venenatis, cras
                  aliquet scelerisque augue tellus nullam sollicitudin vitae.
                  Cras magna habitant aliquet hendrerit rhoncus quis sodales.
                  Vestibulum risus a nunc rhoncus. Id sed habitasse dictum diam
                  tellus eget eget lacus euismod. Posuere commodo sapien nunc,
                  ultrices arcu lacus mauris. Adipiscing ut ut vel elit mattis
                  bibendum ultricies sagittis magna. Vulputate risus
                  pellentesque cursus at. Egestas velit sed cursus massa nunc
                  sem ac egestas tellus. Tincidunt a leo quam pharetra, sed
                  massa nulla.
                </p>{" "}
                <p>
                  Nunc tortor laoreet aenean interdum integer ultrices nulla.
                  Nisl, urna mi dolor porttitor quam nulla orci auctor cursus.
                  Sit eu et quis neque libero, neque, laoreet in turpis.
                  Pellentesque sed cursus placerat rhoncus vitae sit. Praesent
                  libero, et aenean eget. Augue ultricies pellentesque ipsum,
                  feugiat ultrices. Pharetra, eu orci diam scelerisque ac arcu
                  enim sed. Dictum dignissim dui velit, vitae leo lorem
                  tristique massa. Quisque nulla egestas nisi libero. Ut lectus
                  et egestas nulla sit arcu etiam nunc turpis. Laoreet porttitor
                  tortor viverra tellus diam auctor a elit.{" "}
                </p>{" "}
              </div>
            </div>
            {/*  */}
            <div className="commentsec">
              <h3>Comment</h3>
              <div className="commentinputs">
                <label htmlFor="name">Full Name</label>
                <input type="text" name="name" />
                <label htmlFor="email">Email</label>
                <input type="email" name="email" />
                <label htmlFor="msg">Message</label>

                <textarea name="msg" cols="30" rows="10"></textarea>
                <button>Comment</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Blog;
