import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../../assets/images/logos/black_logo.svg";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import "./Navbar.css";

const Navbar = () => {
  const history = useHistory();
  const [toggle, settoggle] = useState(false);
  return (
    <>
      <div className="header">
        <Link to="/">
          <img src={logo} className="logo" />
        </Link>
        <nav className="nav">
          <ul className="nav__links">
            <li>
              <Link
                to="/"
                className={history.location.pathname == "/" && "active"}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={history.location.pathname == "/about" && "active"}
              >
                {" "}
                About
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={history.location.pathname == "/contact" && "active"}
              >
                {" "}
                Contact
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                className={history.location.pathname == "/login" && "active"}
              >
                {" "}
                Login
              </Link>
            </li>
            <li>
              <Link
                to="/signup"
                className={history.location.pathname == "/signup" && "active"}
              >
                {" "}
                Signup
              </Link>
            </li>
          </ul>
          <div className="rightnavButtons">
            {toggle ? (
              <CloseIcon
                className="toggle-icon"
                style={{ color: "#1F1D61", cursor: "pointer" }}
                onClick={() => {
                  settoggle(!toggle);
                }}
              />
            ) : (
              <MenuIcon
                className="toggle-icon"
                style={{ color: "#1F1D61", cursor: "pointer" }}
                onClick={() => {
                  settoggle(!toggle);
                }}
              />
            )}
          </div>
        </nav>
      </div>
      <nav
        className={toggle ? "nav__mob" : "display-none"}
        style={{ width: toggle ? "100%" : "0%" }}
      >
        <ul className="nav__links nav__links__flex">
          <li className="nav__links__mob">
            <Link to="/"> Home</Link>
          </li>
          <li className="nav__links__mob">
            <Link to="/about"> About</Link>
          </li>
          <li className="nav__links__mob">
            <Link to="/contact"> Contact</Link>
          </li>
          <li className="nav__links__mob">
            <Link to="/login"> Login</Link>
          </li>
          <li className="nav__links__mob">
            <Link to="/signup"> Sign up</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
