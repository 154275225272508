import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cities: [], brands: [], carnames: [], features: []
};
const carSlice = createSlice({
    name: "carService",
    initialState,
    reducers: {
        getCitiesList: (state, { payload }) => {
            state.cities = payload
        },
        getBrandList: (state, { payload }) => {
            state.brands = payload
        },
        getCarNamesList: (state, { payload }) => {
            state.carnames = payload
        },
        getFeaturesList: (state, { payload }) => {
            state.features = payload
        },
        getFailure: (state) => {
            state.features = []
            state.cities = []
            state.brands = []
            state.carnames = []
        }

    },
});

const carReducer = carSlice.reducer;
export default carReducer;
export const { getCitiesList, getFeaturesList, getCarNamesList, getBrandList ,getFailure} = carSlice.actions;
