import api from "../../api/index";
import {
  getLoadingLists,
  getCarListsFailure,
  getCarouselCarListsSuccess,
  getCarListsSuccess,
  getCarSuccess
} from "./cars.reducer";

// get All car
export const getCarouselCars = () => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.get(`/car/webiste/getcars`);
    dispatch(getCarouselCarListsSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getCarListsFailure(err));
    }
  }
};

// get All car
export const getAllCars = () => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.get(`/car`);
    dispatch(getCarListsSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getCarListsFailure(err));
    }
  }
};

// get All car
export const getCar = (id) => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.get(`/car?_id=${id}`);
    dispatch(getCarSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getCarListsFailure(err));
    }
  }
};

export const searchCar = (query) => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.get(
      // `/car?city=613738f4ec2d3547346fe3b5&availableFrom_date=Fri Sep 09 2022 17:30:00 GMT+0500 (PKT)&availableTo_date=Sat Sep 10 2022 17:30:00 GMT+0500 (PKT)&car_name=&brand=&is_driver=true`
      `/car?availableFrom_date=${query.pickupdate}&availableTo_date=${query.dropoffdate}&brand=${query.brand}&city=${query.city}&car_name=${query.car_name}`
    );
    dispatch(getCarListsSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getCarListsFailure(err));
    }
  }
};

export const searchCarbybrand = (id, city_id) => async (dispatch) => {
  let url;
  if (city_id == undefined) {
    url = `/car/getCarsbyBrand?brand=${id}`
  } else {
    url = `/car/getCarsbyBrand?brand=${id}&city=${city_id}`
  }
  dispatch(getLoadingLists());
  try {
    const res = await api.get(url);
    dispatch(getCarListsSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getCarListsFailure(err));
    }
  }
};

export const sortResults = (val) => async (dispatch, getState) => {
  dispatch(getLoadingLists());
  const currentState = getState().cars.cars;
  let arrayForSort = [...currentState]
  if (val === "max-rent") {
    const _newresultArray = arrayForSort.sort((a, b) => b.rent - a.rent);
    dispatch(getCarListsSuccess([..._newresultArray]));
  } else if (val === "min-rent") {
    const _newresultArray = arrayForSort.sort((a, b) => a.rent - b.rent);
    dispatch(getCarListsSuccess([..._newresultArray]));
  } else if (val === "max-model") {
    const _newresultArray = arrayForSort.sort(
      (a, b) => parseInt(b.model) - parseInt(a.model)
    );
    dispatch(getCarListsSuccess([..._newresultArray]));
  } else if (val === "min-model") {
    const _newresultArray = arrayForSort.sort(
      (a, b) => parseInt(a.model) - parseInt(b.model)
    );
    dispatch(getCarListsSuccess([..._newresultArray]));
  }
};
export const filterCar = (query) => async (dispatch) => {
  dispatch(getLoadingLists());
  let getquery = `/car?availableFrom_date=${query.pickupdate}&availableTo_date=${query.dropoffdate}&brand=${query.brand}&city=${query.city}&car_name=${query.car_name}&minEngine=${query.minEngineCapacity}&maxEngine=${query.maxEngineCapacity}&minRent=${query.minRent}&maxRent=${query.maxRent}&minModel=${query.minModel}&maxModel=${query.maxModel}&transmission=${query.transmission}`;
  query.selectedfeatures.map((item) => {
    getquery = getquery + "&features=" + item;
  });
  try {
    const res = await api.get(getquery);
    dispatch(getCarListsSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getCarListsFailure(err));
    }
  }
};
