import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
};

const AuthSlice = createSlice({
  name: `auth`,
  initialState,
  reducers: {
    setauth: (state, action) => {
      state.isAuth = action.payload;
    },
  },
});
export const { setauth } = AuthSlice.actions;
export const selectAuth = (state) => state.auth.isAuth;
export default AuthSlice.reducer;
