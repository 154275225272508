import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    reasons: [],
    reason:[],
    errors: false,
    saved: false
};
const reasonSlice = createSlice({
    name: 'reasonService',
    initialState,
    reducers: {
        getLoadingLists: state => {
            state.loading = true;
            state.saved = false;
        },
        getReasonsListsSuccess: (state, { payload }) => {
            state.loading = false;
            state.saved = false;
            state.reasons = payload;
        },
        getReasonSuccess: (state, { payload }) => {
            state.reason = payload;
            state.saved = false;
            state.loading = false;
        },
        getReasonssListsFailure: state => {
            state.loading = false;
            state.saved = false;
            state.hasErrors = true;
            state.reasons = [];
        },
        addReasonsuccess: (state) => {
            state.loading = false;
            state.saved = true;
        },
        updateReasonsSuccess: (state) => {
            state.loading = false;
            state.saved = true;
        }
    },
});

const reasonReducer = reasonSlice.reducer;
export default reasonReducer;
export const {
    getLoadingLists,
    getReasonsListsFailure,
    getReasonsListsSuccess,
    addReasonsuccess,
    
    getReasonSuccess, updateReasonsSuccess
} = reasonSlice.actions;
