import React from "react";
import { useSelector } from "react-redux";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { selectAuth } from "../../features/Auth/AuthSlice";
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/loader"
import CarsGrid from "./CarsGrid";
import "./SearchCar.css";
const Search = () => {
    const auth = useSelector(selectAuth);
    const { loadingcars } = useSelector((state) => state.cars);

    return (
        <div >
            {auth ? <NavbarProfile /> : <Navbar />}
            {loadingcars && <Loader />}
            <div className={loadingcars ? "decreasopacity" : ""}>
                <div className="div-searchbar">
                    <SearchBar />
                </div>

                <div className="div-cars">
                    <CarsGrid />
                </div>
            </div>
        </div>
    );
};

export default Search;
