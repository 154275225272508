import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOtpProvided: false,
};

const OTPSlice = createSlice({
  name: `otp`,
  initialState,
  reducers: {
    setisotp: (state, action) => {
      state.isOtpProvided = action.payload;
    },
  },
});
export const { setisotp } = OTPSlice.actions;
export const selectOtp = (state) => state.isOtpProvided.isOtpProvided;
export default OTPSlice.reducer;
