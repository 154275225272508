import React from "react";
import { useParams } from "react-router-dom";
import successImage from "../assets/images/paymentSuccess.png"
import errorImage from "../assets/images/paymentError.png"
import "./payment.css"
const MyComponent = () => {
    const { status } = useParams()
    return <div className="main__paymentConfirmation">
        {status == "success" ? <img src={successImage} /> : <img src={errorImage} />}
        {status == "success" ? "Your payment was successful" : "Your payment was unsuccessful"}

    </div>;
}
export default MyComponent