import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import GoogleMap from "./MapComponents/GoogleMap";
import "../../components/SearchBar/FilterButtons.css";

const MapModal = ({ open, set_pickuppoint, onClose, _pickuppoint,loadingmap }) => {
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            scroll={"paper"}
            maxWidth={'lg'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>Select Pickup</DialogTitle>
            <DialogContent dividers={'paper'}>
                <DialogContentText
                    className="dialog-map"
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}>
                    <GoogleMap
                        loadingmap={loadingmap}
                        set_pickuppoint={set_pickuppoint}
                        handleCloseMapModal={onClose}
                    />
                </DialogContentText>
            </DialogContent>

        </Dialog>

    );
};

export default MapModal;
