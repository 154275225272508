import React, { useState } from "react";
import "./PopupModal.css";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";

const PopupModal = ({ status, message, setshowPopup }) => {
  const closepopup = () => {
    setshowPopup(false);
  };
  const [open, setOpen] = useState(true);
  return (
    <div className="popupholder" id="popupclose">
      {status === "Success" ? (
        <Alert
          onClose={() => {
            setshowPopup(false);
          }}
          severity="success"
          variant="filled"
        >
          {message}
        </Alert>
      ) : (
        <Alert
          onClose={() => {
            setshowPopup(false);
          }}
          severity="error"
          variant="filled"
        >
          {message}
        </Alert>
      )}
    </div>
  );
};

export default PopupModal;
