import React from "react";
import "./KarblockOwner.css";
import karblockowner from "../../../assets/karblockowner.png";
import google_play from "../../../assets/images/google_play.svg";
import android_play from "../../../assets/images/android_play.svg";

const KarblockOwner = () => {
  return (
    <div style={{ display: "flex", width: "100vw" }}>
      <div className="karblockowner">
        <div className="ownercard">
          <h3>Karblock Owner</h3>
          <p>
            We want to make your life easier by taking care of everything
            related to car rental for you, right from the word GO. From
            arranging the car pickup to making sure the docking back of the
            vehicle on time is on us. We have got options available for every
            pocket and taste.
          </p>
          <div
            className="renter-download-images"
          >
            <a
              href="https://apps.apple.com/us/app/karblock/id1602901466"
              target="_blank"
            >
              {" "}
              <img
                src={android_play}
                alt="arrow"
                width="auto"
                className="android_play"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.karblock&hl=en_US&gl=US"
              target="_blank"
            >
              <img
                src={google_play}
                alt="arrow"
                width="auto"
                className="google_play"
              />
            </a>
          </div>
        </div>
        <div className="owner">
          <img src={karblockowner} alt="karblockowner" />
        </div>
      </div>
    </div>
  );
};

export default KarblockOwner;
