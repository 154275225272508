import React, { useEffect } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { getBrands } from "../../features/Dropdown/dropdown.action"
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CarCarousel from "./CarsCarousel"
import "./CarsByMake.css"
const CarByMake = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    useEffect(() => {
        dispatch(getBrands())
    }, [])
    return (
        <div className="carsbymake">
            {history.location.pathname == "/" && <><h3 className="carsbymakeheading">YOUR DREAM CAR ON DEMAND</h3>
                <p className="rent-heading">Rent premium cars without any hassle</p>
                </>
            }
            <CarCarousel />
        </div>
    )
}

export default CarByMake 