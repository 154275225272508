import React from 'react';
import { MenuItem,  Fade, Menu } from '@material-ui/core';
const SortPopup = ({ _sortResults, sortMenu, setsortMenu }) => {
    return (
        <div>
            <Menu
                id="fade-menu"
                anchorEl={sortMenu}
                keepMounted
                open={sortMenu}
                onClose={() => setsortMenu(false)}
                TransitionComponent={Fade}
            >
                <MenuItem value="max-rent" onClick={(e) => _sortResults("max-rent")}>Rent : High to low</MenuItem>
                <MenuItem value="min-rent" onClick={(e) => _sortResults("min-rent")}>Rent : Low to high</MenuItem>
                <MenuItem value="max-model" onClick={(e) => _sortResults("max-model")}>Model : High to low</MenuItem>
                <MenuItem value="min-model" onClick={(e) => _sortResults("min-model")}>Model : Low to high</MenuItem>
            </Menu>
        </div >
    );
}
export default SortPopup