import React, { useState } from "react";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import "./FilterButtons.css"
const OTPPopup = ({ open, otpSubmitLogin, phone, otploading }) => {
    const useStyles = makeStyles((theme) => ({
        features: { cursor: "pointer", fontWeight: "normal" },
        divider: { marginTop: 5, marginBottom: 5 },
        filterLabel: { marginLeft: 20, color: "black" },
        heading: { fontWeight: 300, fontSize: 20 },
    }));
    const classes = useStyles();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            className={classes.modal}
            scroll={"paper"}

            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            {/* <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>Verify your mobile number.</DialogTitle> */}
            <DialogContent dividers={'paper'}>
                <DialogContentText
                    style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center" }}
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}>
                    <Grid container>
                        <Grid item sm={12} md={12} style={{ textAlign: "center" }}>
                            <h6 style={{ color: "black", textAlign: "center" }}>Please enter the One-Time Password to verify your account.</h6>
                            <p style={{ color: "black", textAlign: "center" }}>A One-Time Password has been sent to +92{phone}</p>
                            <form id="otpForm" onSubmit={otpSubmitLogin} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className="input-field-otp">
                                    {/* <label>Enter OTP</label> */}
                                    {/* <input
                                        type="number"
                                        placeholder="Enter otp here"
                                        name="otp_value"
                                        autoComplete="false"
                                    /> */}
                                    <TextField
                                        placeholder="Enter otp here"
                                        type="number"
                                        name="otp_value"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        onInput={(e) => {
                                            e.target.value = (e.target.value).toString().slice(0, 6)
                                        }} />

                                </div>
                                <button className="main-button" type="submit">
                                    {otploading ? "Validating..." : "Validate"}
                                </button>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default OTPPopup;
