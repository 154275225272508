import React, { useState } from "react";
import logo from "../../assets/images/logos/white_logo.svg";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import "./SignUp.css";
import PopupModal from "../../components/PopupModal/PopupModal";
import pakistan from "../../assets/pakistan.png";
import cars from "../../assets/cars.png";
import OTPPopup from "../LogIn/Otppopup";
const SignUp = ({
  loginSubmit,
  otpSubmit,
  viewOtpForm,
  phone,
  loading,
  otploading,
}) => {
  const [phonenumber, setphonenumber] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [showPopup, setshowPopup] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [status, setstatus] = useState("");

  const validatephoneNumber = (value) => {
    if (value.length < 10) {
      seterrormessage("Enter a valid Phone Number!");
      setshowPopup(true);
      setstatus("error");


      return false;
    } else if (/^([3][0-9]{9,})$/.test(phonenumber)) {
      return true;
    } else {
      seterrormessage("Enter a valid Phone Number!");
      setshowPopup(true);
      setstatus("error");

      return false;
    }
  };

  return (
    <div>
      <Navbar />
       {showPopup ? (
        <PopupModal
          message={errormessage}
          status={status}
          setshowPopup={setshowPopup}
        />
      ) : null}
      {/* {!viewOtpForm ? ( */}
      <div className="signupcontainer">
        <div className="col1signup">
          <div className="signupform">
            <h2>SignUp</h2>
            <p>Create your account to rent your next vehicle with Karblock.</p>
            <div className="inputphone">
              <img src={pakistan} alt="pakistan flag" />
              <div className="hr"></div>
              <input
                className="phonenumberinput"
                type="numeric"
                placeholder="3001234567"
                maxLength="10"
                value={phonenumber}
                onChange={(e) => {
                  setphonenumber(e.target.value);
                  seterrormessage("");
                }}
              />
            </div>

              <button
                className="createaccountbutton"
                style={{ color: "#fff" }}
                onClick={() => {
                  if (validatephoneNumber(phonenumber)) {
                    loginSubmit(phonenumber);
                  }
                }}
              >
                {loading ? "Loading..." : "Create Account"}
              </button>
          </div>
        </div>
        <div className="col2signup container">
          <img src={cars} alt="" />
          <div className="centered">
            <>
              <img src={logo} width={"130px"} height="130px" />
              <p>
                Signup for Karblock as Renter and become
                <br />
                part of the family.
              </p>
            </>
          </div>
        </div>
      </div>
      {/* ) : ( */}
      {/* <div className="form-wrapper" onSubmit={otpSubmit}>
          <form id="otpForm">
          <div className="input-field-otp">
              <label>Enter OTP</label>
              <input
                type="number"
                placeholder="One time password"
                name="otp_value"
                autoComplete="false"
              />
            </div>
            <button className="main-button" type="submit">
              Verify OTP
            </button>
          </form>
        </div> */}
      <OTPPopup
        otpSubmitLogin={otpSubmit}
        open={viewOtpForm}
        phone={phone}
        otploading={otploading}
      />
      {/* )} */}
    </div>
  );
};

export default SignUp;
