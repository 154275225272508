import React from "react";
import { Grid } from "@material-ui/core";
import happyblackcar from "../../assets/happyblackcar.png";
import fc1 from "../../assets/images/fc1.svg";
import fc2 from "../../assets/images/fc2.svg";
import fc3 from "../../assets/images/fc3.svg";
import "./WhyKarblock.css"
const WhyKarblock = () => {
  return (
    <>
      <Grid container> <Grid item className="whykarblock__heading"><h2>Why Karblock ?</h2></Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        className={"grid-"}
        justifyContent="space-evenly"
        alignItems="center"
        alignContent="center"
      >
        <Grid item className="book_gridcol2">
          <div className="knowmorecol2">
            <div className="darkblue">
              <div className="fc1image">
                <img src={fc1} alt="fc1" />
              </div>
              <div className="fc1row1">
                <span className="headfc">Your dream car, on-demand</span>
                <p>
                  You decide which car you want to take out; we will arrange
                  everything else for you! You can even customize your ride by
                  adding extras like GPS or pet insurance
                </p>
              </div>
            </div>

            <div className="lightblue">
              <div className="fc2image">
                <img src={fc2} alt="fc2" />
              </div>
              <div className="fc2row2">
                <span className="headfc">Get the best in less</span>
                <p>
                  Whatever your budget is, Karblock has got you covered. Save up
                  to 40% on our chauffeur-driven cars without sacrificing service
                  quality! Our affordable prices are unbeatable!
                </p>
              </div>
            </div>

            <div className="morelightblue">
              <div className="fc3image">
                <img src={fc3} alt="fc3" />
              </div>
              <div className="fc3row3">
                <span className="headfc">Savings coupled with Comfort</span>
                <p>
                  We know how important it is to make sure you're comfortable
                  during your trip, so Karblock will pick up and drop off the car
                  right at your doorstep.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item className="book___gridcol1">
          <div className="__bookcarcontainer">
            <img src={happyblackcar} alt="desert car" />
          </div>
        </Grid>
      </Grid>


    </>

  );
};
export default WhyKarblock;
