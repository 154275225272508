import React from "react";
import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
const NewsCardHolder = () => {
  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    grid: {
      width: "100",
      margin: "0px",
    },
  });

  const classes = useStyles();
  return (
    // <div className="newscardHolder" style={{ margin: "45px 0px" }}>
    //   <div className="newscard">
    //     <img src={newscard1} alt="newscard1 image" />
    //     <div className="newscardtext">
    //       {" "}
    //       <h5>Taxes increased on vehicles</h5>
    //       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //     </div>
    //     <div className="newscarddate">
    //       <p className="dateholder">May 20th 2020</p>
    //       <p
    //         style={{
    //           cursor: "pointer",
    //           textAlign: "right",
    //           fontFamily: "Inter",
    //           fontStyle: "normal",
    //           fontWeight: "bold",
    //           fontSize: "12px",
    //           lineHeight: "15px",

    //           color: "#2D3748",
    //         }}
    //       >
    //         Read more
    //       </p>
    //     </div>
    //   </div>

    //   <div className="newscard">
    //     <img src={newscard2} alt="newscard1 image" />
    //     <div className="newscardtext">
    //       {" "}
    //       <h5>Hiring new drivers</h5>
    //       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //     </div>
    //     <div className="newscarddate">
    //       <p className="dateholder">May 20th 2020</p>
    //       <p
    //         style={{
    //           cursor: "pointer",
    //           textAlign: "right",
    //           fontFamily: "Inter",
    //           fontStyle: "normal",
    //           fontWeight: "bold",
    //           fontSize: "12px",
    //           lineHeight: "15px",

    //           color: "#2D3748",
    //         }}
    //       >
    //         Read more
    //       </p>
    //     </div>
    //   </div>

    //   <div className="newscard">
    //     <img src={newscard3} alt="newscard1 image" />
    //     <div className="newscardtext">
    //       {" "}
    //       <h5>Rent-a-Car Tips</h5>
    //       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //     </div>
    //     <div className="newscarddate">
    //       <p className="dateholder">May 20th 2020</p>
    //       <p
    //         style={{
    //           cursor: "pointer",
    //           textAlign: "right",
    //           fontFamily: "Inter",
    //           fontStyle: "normal",
    //           fontWeight: "bold",
    //           fontSize: "12px",
    //           lineHeight: "15px",

    //           color: "#2D3748",
    //         }}
    //       >
    //         Read more
    //       </p>
    //     </div>
    //   </div>
    // </div>
    <div style={{ width: "70vw" }}>
      <Grid
        container
        spacing={2}
        className={classes.grid}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={6} lg={4}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="cardimages"
                height="140"
                image={card1}
                title="cardimages"
              />
              <CardContent>
                <p className="blogcardheading"> Taxes increased on vehicles</p>

                <p className="blogCardp">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Risus, sit imperdiet risus, venenatis at.
                </p>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        {/*  */}
        <Grid item md={6} lg={4}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="cardimages"
                height="140"
                image={card2}
                title="cardimages"
              />
              <CardContent>
                <p className="blogcardheading">Hiring new drivers</p>
                <p className="blogCardp">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Risus, sit imperdiet risus, venenatis at.
                </p>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        {/*  */}
        <Grid item md={6} lg={4}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="cardimages"
                height="140"
                image={card3}
                title="cardimages"
              />
              <CardContent>
                <p className="blogcardheading">Taxis in Islamabad got better</p>
                <p className="blogCardp">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Risus, sit imperdiet risus, venenatis at.
                </p>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewsCardHolder;
