import React, { useEffect, useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import mask_contactus from "../../assets/images/mask_contactus.svg";
import Footer from "../../components/LandingPage/Footer/Footer";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/Auth/AuthSlice";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import loadingbars from "../../assets/loading_bars.svg";
import Typography from "@material-ui/core/Typography";
import "../Contact/Contact.css";

const PrivacyPolicy = () => {
  const auth = useSelector(selectAuth);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function loadinghandler() {
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    setloading(true);
    loadinghandler();
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        width: "100vw",
      }}
    >
      {auth ? <NavbarProfile /> : <Navbar />}
      {loading ? (
        <div className="loading-div">
          <img src={loadingbars} alt="Loading..." />
        </div>
      ) : (
        <>
          <div className="contactheader">
            <img src={mask_contactus} />
            <h2>PRIVACY AND DATA PROTECTION POLICY</h2>
          </div>
          <div style={{ margin: 50 }}>
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              1. General
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              1.1. Karblock Pvt Ltd (“
              <span style={{ fontWeight: "bold" }}>we</span>” or “
              <span style={{ fontWeight: "bold" }}>us</span>”) take the privacy
              of your information very seriously. Our Privacy Policy is designed
              to tell you about our practices regarding the collection, use and
              disclosure of information that you may provide via the{" "}
              <span style={{ color: "cornflowerblue" }}>
                {" "}
                www.Karblock.com{" "}
              </span>{" "}
              website and other associated partner websites, microsites and
              sub-sites (the “<span style={{ fontWeight: "bold" }}>Site</span>
              ”).
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              1.2. By using this Site or any services we offer, you are
              consenting to the collection, use, and disclosure of that
              information about you in accordance with, and are agreeing to be
              bound by, this Privacy Policy.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              1.3. Karblock Pvt Ltd is registered under the Prevention of
              Electronic Crimes Act, 2016 ('PECA' (the “
              <span style={{ fontWeight: "bold" }}>Act</span>”). The Act sets
              out a number of important principles that organisations must apply
              when processing personal data.
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              2. Ways that we collect information
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              2.1. We may collect and process the following information or data
              (including personal information and information that can be
              uniquely identified with you) about you:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.1. Passport, CNIC, Driving License such other documents and
              information that may be required from time to time including your
              name, address including postcodes), date of birth and if you are a
              Car Owner certain information about your Vehicle to register with
              the Site or to access other services provided by us;
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.2. Your e-mail address and a password.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.3. A mobile phone number.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.4. A record of any correspondence between you and us.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.5. A record of any bookings you have made or advertisements
              you have placed with or through the Site.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.6. Your replies to any surveys or questionnaires that we may
              use for research purposes.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.7. Details of accounting or financial transactions including
              transactions carried out through the Site or otherwise. This may
              include information such as your credit card, debit card or bank
              account details, details of Bookings (as described in our
              Conditions) you have booked or offered through the Site.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.8. Details of your visits to the Site and the resources that
              you access.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.9. Information we may require from you when you report a
              problem with the Site.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom0
              style={{ color: "#383838", marginLeft: 20 }}
            >
              2.1.10. Information about you which we may receive from other
              sources.
            </Typography>
            {/* SECOND */}
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              3. Use
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              3.1. We may use this information to:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.1. Ensure that the content of the Site is presented in the
              most effective manner for you and for your computer and customise
              the Site to your preferences.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.2. Assist in making general improvements to the Site.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.3. Carry out and administer any obligations arising from any
              agreements entered into between you and us.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.4. Allow you to participate in features of the Site and other
              services.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.5. Contact you and notify you about changes to the Site or the
              services we offer (except where you have asked us not to do this).
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.6. Collect payments from you.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.7. Analyse how users are making use of the Site and for
              internal marketing and research purposes.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 20 }}
            >
              3.1.8 Ensure that you comply with the Conditions and applicable
              law.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              3.2. We will not resell your information to any third party nor
              use it for any third party marketing.
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              4. Sharing your information
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              4.1. If we charge you any fees or collect any money from you in
              relation to any services on the Site, including any sponsorship
              money, credit or debit card payments will be collected by our
              payment processor.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              4.2. In order for payments to be processed you may need to provide
              some necessary details to our payment processor. We will tell you
              about this at the point we collect that information.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              4.3. We do not disclose any information you provide via the Site
              to any third parties except
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.1. As part of our booking process and in order to provide our
              services information may be passed to a Car Renter (if you are a
              Car Owner) or to a Car Owner (if you are a Car Renter).
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.2. If we are under a duty to disclose or share your personal
              data in order to comply with any legal obligation (for example, if
              required to do so by a court order or for the purposes of
              prevention of fraud or other crime or upon requisition made by
              government agencies).
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.3. In order to enforce any terms of use that apply to any of
              the Site, or to enforce any other terms and conditions or
              agreements for our Services that may apply.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.4. We may share information with our international partner
              websites where you have made a booking through the Site with a
              user of our partner websites or where you have used the Site to
              interact with a third party.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.5. We may transfer your personal information to a third party
              as part of a sale of some or all of our business and assets to any
              third party or as part of any business restructuring or
              reorganisation, but we will take steps with the aim of ensuring
              that your privacy rights continue to be protected.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.6. To protect the rights, property, or safety of Karblock Pvt
              Ltd, the Site’s users, or any other third parties. This includes
              exchanging information with other companies and organisations for
              the purposes of fraud protection and credit risk reduction.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.7. For the purpose of verification by third party service
              providers of the information contained in the IDs and other
              documents that may be collected from the Members at the time of
              registration or at any other time during their use of the Site as
              may be required. Third party service providers will process data
              under Karblock’s control and will be bound by the same degree of
              security and care as Karblock’s under this Privacy and Data
              Protection Policy. <br />
              Notwithstanding anything contained herein or the terms &
              conditions, Karblock hereby disclaims any liability arising from
              the verification of the IDs and other documents by third party
              service providers including but not limited to its accuracy,
              reliability, and originality.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838", marginLeft: 22 }}
            >
              4.3.8. For the purpose of maintaining internal quality, training
              and updating the customers about the Services of Karblock.{" "}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              4.4. Karblock may moderate the messages you exchange on the Site.
              Karblock will not sell, transfer, distribute, disclose your
              communications to any third party without your consent, unless
              such disclosure is mandated under applicable laws.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              4.5. Other than as set out above, we shall not disclose any of
              your personal information unless you give us permission to do so.{" "}
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              5. Cookies
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              5.1. A cookie is a piece of data stored locally on your computer
              and contains information about your activities on the Internet.
              The information in a cookie does not contain any personally
              identifiable information you submit to our site.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              5.2. On the Site, we use cookies to track users’ progress through
              the Site, allowing us to make improvements based on usage data. We
              also use cookies if you log in to one of our online services to
              enable you to remain logged in to that service. A cookie helps you
              get the best out of the Site and helps us to provide you with a
              more customised service.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              5.3. Once you close your browser, our access to the cookie
              terminates. You have the ability to accept or decline cookies.
              Most web browsers automatically accept cookies, but you can
              usually modify your browser setting to decline cookies if you
              prefer. To change your browser settings you should go to your
              advanced preferences.
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              5.4. We have a clear cookies notice on the home page of the
              website. If you continue to use the Site having seen the notice
              then we assume you are happy for us to use the cookies described
              above.{" "}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              5.5. If you choose not to accept the cookies, this will not affect
              your access to the majority of information available on the Site.
              However, you will not be able to make full use of our online
              services.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              6. Access to and correction of personal information
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              6.1. We will take all reasonable steps in accordance with our
              legal obligations to update or correct personally identifiable
              information in our possession that you submit via this Site.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              6.2. The Act gives you the right to access information held about
              you. Your right of access can be exercised in accordance with the
              Act. Any access request may be subject to a fee of PKR 500 to meet
              our costs in providing you with details of the information we hold
              about you. If you wish to see details of any personal information
              that we hold about you please contact us by way of our contact
              page.{" "}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              6.3. We take all appropriate steps to protect your personally
              identifiable information as you transmit your information from
              your computer to our Site and to protect such information for
              loss, misuse, and unauthorised access, disclosure, alteration, or
              destruction. We use leading technologies and encryption software
              to safeguard your data, and operate strict security standards to
              prevent any unauthorised access to it.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              6.4. Where you use passwords, usernames, or other special access
              features on this site, you also have a responsibility to take
              reasonable steps to safeguard them.
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              7. Other websites
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              7.1. This Site contains links and references to other websites.
              Please be aware that this Privacy Policy does not apply to those
              websites.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              7.2. We cannot be responsible for the privacy policies and
              practices of sites that are not operated by us, even if you access
              them via the Site. We recommend that you check the policy of each
              site you visit and contact its owner or operator if you have any
              concerns or questions.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              7.3. In addition, if you came to this Site via a third party site,
              we cannot be responsible for the privacy policies and practices of
              the owners or operators of that third party site and recommend
              that you check the policy of that third party site and contact its
              owner or operator if you have any concerns or questions.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              8. Transferring your information outside of Pakistan
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              8.1. As part of the services offered to you through the Site, the
              information you provide to us may be transferred to, and stored
              at, countries outside of Pakistan. By way of example, this may
              happen if any of our servers are from time to time located in a
              country outside of Pakistan or one of our service providers is
              located in a country outside of Pakistan. We may also share
              information with other equivalent national bodies, which may be
              located in countries worldwide. The information provided by you
              may be transferred to a third party if it is necessary for the
              performance of any contract between us and the said third party or
              upon receipt of your consent to data transfer. If we transfer your
              information outside of Pakistan in this way, we will take steps
              with the aim of ensuring that your privacy rights continue to be
              protected as outlined in this privacy policy and in accordance
              with applicable laws including but not limited to Information
              Technology Act, 2000 and the rules framed thereunder.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              8.2. If you use the Site while you are outside Pakistan, your
              information may be transferred outside Pakistan in order to
              provide you with those services.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: "#383838" }}
            >
              8.3. By submitting your personal information to us you agree to
              the transfer, storing or processing of your information outside
              Pakistan in the manner described above.
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              9. Notification of changes to our Privacy Policy
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              We will post details of any changes to our Privacy Policy on the
              Site to help ensure you are always aware of the information we
              collect, how we use it, and in what circumstances, if any, we
              share it with other parties.
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#383838", marginTop: 20, marginBottom: 20 }}
            >
              10. Contact us
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              If at any time you would like to contact us with your views about
              our privacy practices, or with any enquiry relating to your
              personal information, you can do so by way of our contact page or
              by mail to KARBLOCK PVT LTD,. Office No: 09, 2nd Floor, Pakland
              City Centre, I-8 Markaz, Islamabad - Pakistan.{" "}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: "#383838" }}
            >
              Should you wish to no longer receive our (marketing newsletters),
              you may click on the unsubscribe link located within each email
              and/or manage your preferences directly.
            </Typography>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
