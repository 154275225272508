import api from "../../api/index";
import {
  getBookingsFailure,
  getBookingsSuccess,
  getBookingSuccess,
  getLoadingLists,
  getAddBookingLoadingFalse,
  getAddBookingLoading
} from "./bookings.reducer";
import Swal from "sweetalert2";
// get All car
export const getAllBookings = (userId) => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.get(`/booking?renter_id=${userId}`);
    dispatch(getBookingsSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getBookingsFailure(err));
    }
  }
};

export const cancelBooking = (body, id) => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.put(`/booking/cancelBooking/${id}`, body);
    Swal.fire({
      icon: "success",
      position: "center",
      title: res.data.message,
      showConfirmButton: true
    });
    dispatch(getAllBookings(res?.data?.data?.renter_id));
  } catch (err) {
    if (err.response) {
      dispatch(getAllBookings());
      Swal.fire({
        icon: "error",
        position: "center",
        title: err.response.data.message,
        showConfirmButton: true
      });
      dispatch(getBookingsFailure(err));
    }
  }
}

export const addBooking = (body,history) => async (dispatch) => {
  dispatch(getAddBookingLoading())
  try {
    const res = await api.post(`/booking/addNewBooking`, body);
    Swal.fire({
      icon: "success",
      position: "center",
      title: `Booking Request has been sent to owner.`,
      showConfirmButton: true
    });
    dispatch(getAddBookingLoadingFalse());
    history.push('/bookings')
  } catch (err) {
    if (err.response) {
      dispatch(getAllBookings());
      Swal.fire({
        icon: "error",
        position: "center",
        title: err.response.data.message,
        showConfirmButton: true
      });
      dispatch(getAddBookingLoadingFalse());
    }
  }
}


// get All car
export const getBooking = (id) => async (dispatch) => {
  dispatch(getLoadingLists());
  try {
    const res = await api.get(`/booking?_id=${id}`);
    dispatch(getBookingSuccess(res.data.data));
  } catch (err) {
    if (err.response) {
      dispatch(getBookingsFailure(err));
    }
  }
};


