import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  booking: {},
};

const BookingInfoSlice = createSlice({
  name: `booking`,
  initialState,
  reducers: {
    setbooking: (state, action) => {
      state.booking = action.payload;
    },
  },
});
export const { setbooking } = BookingInfoSlice.actions;
export const selectBooking = (state) => state.booking.booking;
export default BookingInfoSlice.reducer;
