import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isotp = useSelector((state) => state.otp.isOtpProvided);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isotp ? <Redirect to="/signup" /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;
