import React, { useEffect, useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import mask_contactus from "../../assets/images/mask_contactus.svg";
import Footer from "../../components/LandingPage/Footer/Footer";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/Auth/AuthSlice";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import loadingbars from "../../assets/loading_bars.svg";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import "../Contact/Contact.css";
import "./faq.css";

const PrivacyPolicy = () => {
  const auth = useSelector(selectAuth);
  const [loading, setloading] = useState(false);
  const Accordion = withStyles({
    root: {
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 56,
      "&$expanded": {
        minHeight: 56,
      },
    },
    content: {
      "&$expanded": {
        margin: "12px 0",
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    async function loadinghandler() {
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    setloading(true);
    loadinghandler();
  }, []);
  return (
    <div className="faq-div">
      {auth ? <NavbarProfile /> : <Navbar />}
      {loading ? (
        <div className="loading-div">
          <img src={loadingbars} alt="Loading..." />
        </div>
      ) : (
        <>
          <div className="contactheader">
            <img src={mask_contactus} />
            <h2>Frequently Asked Questions</h2>
          </div>
          <div style={{ margin: 50 }}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#383838", marginTop: 20 }}
            >
              FAQs - RENTER
            </Typography>
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Who can avail of your rental service?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We are operating on a simple model of renting out
                  chauffeur-driven vehicles. Thus, we welcome anyone willing to
                  pay for comfort, luxury, and time.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  I would want to drive myself. Do you allow it?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Currently, we do not allow the renter to drive the car. It is
                  also not allowed that the renter asks the chauffeur to sit at
                  the back seat and drive the car. However, in the future, there
                  may be a possibility of renting cars without chauffeurs but at
                  the moment we discourage the practice.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Do I have the option of getting a vehicle for a limited time,
                  like a couple of hours?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  No, you are supposed to pay for a minimum of 10 hours
                  irrespective of your usage.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  I would want the car to be at my doorstep at the time of
                  pickup.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The chauffeur will park as close as possible to the pickup
                  point specified in your booking.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Do you provide the facility of renting out for a long-term and
                  more than one vehicle?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We can tailor the service to suit your needs. You can visit
                  the Booking Request Page on the website to book more than one
                  car for long-term purposes. The representative will contact
                  you and guide you through the process after you have filled
                  out your requirements in the request form.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  What if I have to ride to another city?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Karblock is for both inter and intra-city{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Do I have to pay upfront?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  No, when you end your journey with us, the app will display
                  the amount for the ride and you can hand it over to the
                  chauffeur.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  I am not carrying cash or do not have Pakistani currency. What
                  other options do I have?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Unfortunately, we are only accepting cash at the moment.{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                aria-controls="panel8d-content"
                id="panel8d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  Do I have to deposit some dimes as a security deposit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  No, you do not have to submit any money as a security deposit{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                aria-controls="panel9d-content"
                id="panel9d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  I hope I will be charged ONLY for the fuel I have consumed.{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You are spot-on. You will get the vehicle with a topped-up
                  tank, and just before the end of the journey, the business
                  partner will drive you to the nearest station for topping up
                  the tank. You will ONLY pay for the differential of fuel
                  between the two top-ups.{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#383838", marginTop: 20 }}
            >
              FAQs - OWNER
            </Typography>
            {/* OWNER FAQS */}
            <Accordion
              square
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                aria-controls="panel10d-content"
                id="panel10d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  I need to know the steps to get registered with Karblock. What
                  do I have to do?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The very few steps that will get you onboard with Karblock
                  are:
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <br />
                <div style={{ marginLeft: 40 }}>
                  {" "}
                  <Typography>
                    * You need to sign up via the mobile application.
                    <br />
                    * We will communicate the time and date to bring your
                    vehicle to the designated office for a checkup. <br />
                    * The office staff will get documents of both vehicle and
                    chauffeur vetted. <br />
                    * Once you become a business partner, you will: <br />
                    <div style={{ marginLeft: 60 }}>
                      * Have the right when to make your vehicle available or
                      unavailable. <br />* Arrange the person to drive your
                      automobile if you are not driving.
                    </div>
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                aria-controls="panel11d-content"
                id="panel11d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  Which car models does Karblock support?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  It is mentioned-worthy that any mode of transport registered
                  before 2012 will not be allowed.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  How will I be intimated about the ride?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The moment a potential rider has chosen your vehicle, we will
                  give you a call to inquire about the deal with you. Upon
                  confirmation, we will lock the booking. It would then be your
                  responsibility to ensure that the vehicle is available at the
                  designed pickup point. All details about the booking and the
                  pickup/dropoff point will be available in your mobile
                  application as well after confirmation.{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel13"}
              onChange={handleChange("panel13")}
            >
              <AccordionSummary
                aria-controls="panel13d-content"
                id="panel13d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  What would be the payout frequency?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  It would be a disruptive model as you will be the custodian of
                  the money of every ride. You or your designated person will
                  receive the cash at the end of each ride from the renter
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "panel16"}
              onChange={handleChange("panel16")}
            >
              <AccordionSummary
                aria-controls="panel16d-content"
                id="panel16d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  How will you gauge the fuel consumption, and how will this be
                  managed?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You would be required to ensure a full tank at the start of
                  each ride. The renter will be required to get the tank full
                  just before ending the ride and pay for the fuel.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
