import React from "react";
import { Grid, Avatar } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
}));

const CarDetails = ({ car }) => {
    const classes = useStyles();
    return (
        <Grid container className="bookingrequest__cardetails">
            <Grid item lg={12}>
                <h3>
                    {car &&
                        car[0]?.brand?.brand_name +
                        " " +
                        car[0]?.car_name?.name +
                        " " +
                        car[0]?.model}
                </h3>
            </Grid>
            <Grid item lg={12}>
                <div className="display-row1">
                    {" "}
                    <Rating value={car[0]?.rating} precision={0.5} readOnly />{" "}
                    {car[0]?.rating}
                </div>
            </Grid>
            <Grid item lg={12}>
                <p className="bookingrequest__heading">Hosted By</p>
                <div className="display-row2">
                    <div>
                        <Avatar
                            alt={car[0]?.owner_id?.fullname}
                            src={car[0]?.owner_id?.profile_image}
                            className={classes.large}
                        />
                    </div>
                    <div className="display-col1">
                        <span className="hostname">{car[0]?.owner_id?.fullname}</span>
                        <span className="display-subcol1">
                            <Rating value={car[0]?.owner_id?.rating} precision={0.5} readOnly />{" "}
                            {car[0]?.owner_id?.rating}
                        </span>
                    </div>
                </div>
            </Grid>

            <Grid item lg={12}>
                <p className="bookingrequest__heading">Features</p>

                <div className="featureholder">
                    {car[0]?.features?.map((res) => {
                        return <p>{res.feature_name}</p>;
                    })}
                </div>
            </Grid>
        </Grid>
    );
};
export default CarDetails;
