import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../components/LandingPage/Footer/Footer";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import NavbarProfile from "../../components/NavbarProfile/NavbarProfile";
import { useParams } from "react-router-dom"
import { selectAuth } from "../../features/Auth/AuthSlice";
import "./RequestBooking.css";
import { getCar } from "../../features/cars/cars.action"
import RequestBox from "./RequestBox";
import CarDetails from "./CarDetails";
import { Grid } from "@material-ui/core";
import Loader from "../../components/Loader/loader"
import ImagesCarousel from "./ImagesCarousel"
import { Redirect } from "react-router-dom";
const RequestBooking = () => {
    const isAuth = useSelector((state) => state.auth.isAuth);

    const auth = useSelector(selectAuth);
    const dispatch = useDispatch()
    const { loadingcars ,car} = useSelector((state) => state.cars);
    const { addbookingloading } = useSelector((state) => state.bookings);
    const { id } = useParams()
    useEffect(() => {
        dispatch(getCar(id))
    }, [])
    if (!isAuth) {
        return <Redirect to="/login" />
    }
    return (
        <div>
            {auth ? <NavbarProfile /> : <Navbar />}
            {loadingcars && <Loader />}
            {addbookingloading && <Loader />}

            <Grid style={{ margin: "auto" }}><ImagesCarousel car={car} /></Grid>
            <Grid className={(loadingcars || addbookingloading) ? `requestbooking__grid_none` : `requestbooking__grid`}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                        <CarDetails car={car} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <RequestBox car={car} />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </div>
    );
};

export default RequestBooking;
